import * as React from 'react';

import Card from 'react-bootstrap/Card';

import Alert from 'react-bootstrap/Alert';

type ComponentProps = {
    title: string;
    heading: string;
    text: string;
};

interface ComponentState {
}
    
class ErrorLoading extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title as="h3">{this.props.title}</Card.Title>
                </Card.Body>
                <Alert variant="danger">
                    <Alert.Heading>{this.props.heading}</Alert.Heading>
                    <p>{this.props.text}</p>
                </Alert>
            </Card>
        );
    }
};

export default ErrorLoading;
