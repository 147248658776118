import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import NumberFormat from 'react-number-format';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/SetupData';
import { DashboardAdminWordings as Wordings } from '../../Wordings';
import TransferSettingEdit from './TransferSettingEdit';

const connector = connect((state: ApplicationState) => state.setup, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
    view: DashboardView;
}
    
enum DashboardView { View, TransferEdit }
class TransferSettingCard extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        view: DashboardView.View
    };

    fetchData = (): void => {
        this.props.fetchSetup();
    };

    startTransferEdit = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event.preventDefault();
        this.setState(produce(d => {
            d.view = DashboardView.TransferEdit;
        }));
    };

    endTransferEdit = (): void => {
        this.setState(produce(d => {
            d.view = DashboardView.View;
        }));
    };

    public componentDidMount() {
        this.fetchData();
    }

    public render() {
        const { transferAmountMax, transferNeedEtherMin } = this.props.setup;

        const { view } = this.state;
        return (
            <>
                <Card>
                    <Card.Body>
                        <Card.Title as="h3">{Wordings.transferTitle}</Card.Title>
                    </Card.Body>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <Form.Label>{Wordings.transferAmountMax}</Form.Label>{': '}
                            <NumberFormat value={transferAmountMax} displayType={'text'} thousandSeparator={true} />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Form.Label>{Wordings.transferNeedEtherMin}</Form.Label>{': '}
                            <NumberFormat value={transferNeedEtherMin} displayType={'text'} thousandSeparator={true} />
                        </ListGroup.Item>
                    </ListGroup>
                    <Card.Footer>
                        <Card.Link href="#" onClick={this.startTransferEdit}>{Wordings.edit}</Card.Link>
                    </Card.Footer>
                </Card>
                {view === DashboardView.TransferEdit &&
                    <TransferSettingEdit onCompleted={this.endTransferEdit}/>
                }
            </>
        );
    }
};

export default connector(TransferSettingCard);