import * as React from 'react';
import produce from 'immer';

import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { AuthWalletLoginWordings as Wordings } from '../../Wordings';
import WalletLoginKey from './WalletLoginKey';
import WalletLoginPassword from './WalletLoginPassword';
import AppRoutes from '../../AppRoutes';

type ComponentProps = {};

interface ComponentState {
    readonly view: View;
    readonly username: string;
}

enum View {Login, Password, Set}
class WalletLogin extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        view: View.Login,
        username: ''
    };

    onPasswordSet = () => {
        this.setState(produce(d => { 
            d.view = View.Set;
        }));
    }

    onKeyCheck = (username: string) => {
        this.setState(produce(d => { 
            d.username = username;
            d.view = View.Password;
        }));
    }

    public render() {
        const { view, username } = this.state;
        return (
            <>
            {view === View.Login &&
                <WalletLoginKey onSuccess={this.onKeyCheck}/>
            }
            {view === View.Password &&
                <WalletLoginPassword username={username} onSuccess={this.onPasswordSet}/>
            }
            {view === View.Set &&
                <Alert variant='success'>
                    <Alert.Heading>{Wordings.successTitle}</Alert.Heading>
                    <p>{Wordings.successText}</p>
                    <LinkContainer to={AppRoutes.UserLogin}>
                        <Button variant="primary" block>{Wordings.login}</Button>
                    </LinkContainer>
                </Alert>
            }
            </>
        );
    }
};

export default WalletLogin;
