import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { DashboardNoWalletWordings as Wordings } from '../../Wordings';
import AppRoutes from '../../AppRoutes';

type ComponentProps = {
}

interface ComponentState {
}

/*
https://fgcorp.atlassian.net/browse/FGC-14
Registration Success Page
This page is shown after the user completes the data fields and it is successfully saved
Specific information is presented to the user that is in the attachment
Create Wallet Now option is presented to the user that will initiate the wallet creation process.
Create Wallet later option is offered for those users that are not able to save their information 
or if they want their national coordinator to handle their wallets for them.
*/
export default class HasNoWallet extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        return (
            <Card>
                <Card.Header as="h3">{Wordings.title}</Card.Header>
                <Card.Body>
                    <Card.Text>{Wordings.formatString(Wordings.nextStep, <br/>)}</Card.Text>
                    <Card.Text>{Wordings.formatString(Wordings.requiredAction, <br/>)}</Card.Text>
                    <Card.Text>{Wordings.formatString(Wordings.warning, <br/>)}</Card.Text>
                    <Card.Text>{Wordings.formatString(Wordings.disclaimer, <br/>)}</Card.Text>
                    
                    <LinkContainer to={AppRoutes.WalletCreate}>
                        <Button variant="primary">{Wordings.createNow}</Button>
                    </LinkContainer>
                    <LinkContainer to={AppRoutes.WalletLater}>
                        <Button variant="secondary">{Wordings.createLater}</Button>
                    </LinkContainer>
                </Card.Body>
            </Card>
        );
    }
};
