import React from 'react'
import { RouteProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { ApplicationState } from './store';
import * as ApplicationDataStore from './store/ApplicationData';
import AppRoutes from './AppRoutes';

const connector = connect((state: ApplicationState) => state.appdata, ApplicationDataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux 
    & RouteProps;

class AuthorizeRoute extends Route<ComponentProps> {
    render() {
        var link = document.createElement("a");
        link.href = this.props.path as string;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${AppRoutes.UserLogin}?returnUrl=${encodeURI(returnUrl)}`
        const { component: Component, ...rest } = this.props;

        const token = this.props.userInfo?.token;
        return <Route {...rest}
            render={() => {
                if (token && token.length > 0) {
                    return super.render();
                } else {
                    return <Redirect to={redirectUrl} />
                }
            }} 
        />
    }
}

export default connect(
    (state: ApplicationState) => state.appdata,
    ApplicationDataStore.actionCreators
)(AuthorizeRoute);
