import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/ReviewCodeData';
import { ReviewCodeWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, IReviewCodeForm as IForm, IReviewCodeErrors as IFormErrors, IResponseError, IReviewCodeInfo } from '../../api';
import * as U from '../../utils/BbUtil';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const errorMap = {
    transferDefault: {
        required: Wordings.transferDefaultRequired
    }
}

const connector = connect((state: ApplicationState) => state, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux 
& {
    code: IReviewCodeInfo,
    onCompleted: () => void
}

interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly toast: ToastProps
}

class ReviewCode extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            codeid: 0,
            description: this.props.code.description ?? '',
            transferDefault: this.props.code.transferDefault ?? '0',
            active: undefined
        },
        errors: {},
        toast: DefaultToastProps
    };

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.description = (name === 'description') ? value : d.form.description;
            d.form.transferDefault = (name === 'transferDefault') ? value : d.form.transferDefault;

            d.errors.description = (name === 'description') ? undefined : d.errors.description;
            d.errors.transferDefault = (name === 'transferDefault') ? undefined : d.errors.transferDefault;
        }));
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { d.errors = errors }));
    }

    validateForm = (): boolean => {
        const { transferDefault } = this.state.form;
        let result: IResponseError = {
            transferDefault: transferDefault ? undefined : 'required',
        }
        this.setFormErrors(result);
        return U.isValidForm(result);
    }

    handleCancel = () => {
        this.props.onCompleted();
    }

    handleSubmit = () => {
        if (!this.validateForm()) {
            return;
        }
        const form = { ...this.state.form, codeid: this.props.code.codeid };
        api.ReviewCodeEdit(form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded) {
                    this.props.updateReviewCode(result.data as IReviewCodeInfo);
                    this.props.onCompleted();
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.reviewCodeEdit;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    public render() {
        const { code } = this.props;
        const { form, errors, toast } = this.state;

        return (
            <Modal show={true} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{Wordings.title}{' '}{code.reviewCode}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="description">
                        <Form.Label>{Wordings.description}</Form.Label>
                        <Form.Control type="text" 
                            name="description"
                            value={form.description}
                            isInvalid={U.isInvalid(errors.description)}
                            onChange={this.handleChange}/>
                        <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="transferDefault">
                        <Form.Label>{Wordings.transferDefault}</Form.Label>
                        <Form.Control type="number" 
                            name="transferDefault"
                            value={form.transferDefault}
                            isInvalid={U.isInvalid(errors.transferDefault)}
                            onChange={this.handleChange}/>
                        <Form.Control.Feedback type="invalid">{errors.transferDefault}</Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCancel}>{Wordings.cancel}</Button>
                    <Button variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                </Modal.Footer>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </Modal>
        );
    }
};

export default connector(ReviewCode);
