import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import { AppWordings as Wordings } from '../Wordings';
import AppRoutes from '../AppRoutes';
import { ApplicationState } from '../store';
import * as DataStore from '../store/ApplicationData';

const connector = connect((state: ApplicationState) => state.appdata, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux

interface ComponentState {
}

class AnonLayout extends React.Component<ComponentProps, ComponentState> {
  state: Readonly<ComponentState> = {
  }

  componentDidMount() {
    document.body.style.paddingTop = "4em";
    document.body.style.paddingBottom = "4em";
  }
  componentDidUpdate() {
    document.body.style.paddingTop = "4em";
    document.body.style.paddingBottom = "4em";
  }

  public render() {
    const { appInfo: {build} } = this.props;
    return (
      <>
        <Navbar bg="light" fixed="top" expand="sm" className="nav nav-pills">
          <Navbar.Brand>
            <Image src="/images/anon_logo.jpg" height="30" className="d-inline-block align-top" alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavLink to={AppRoutes.AnonHome} exact className="nav-link">{Wordings.home}</NavLink>
              <NavLink to={AppRoutes.AnonWalletCreate} className="nav-link">{Wordings.anonWallet}</NavLink>
              <NavLink to={AppRoutes.AnonWalletBalance} className="nav-link">{Wordings.anonBalance}</NavLink>
              <NavLink to={AppRoutes.AnonTransfer} className="nav-link">{Wordings.anonTransfer}</NavLink>
              <NavLink to={AppRoutes.AnonTransferStatus} className="nav-link">{Wordings.anonStatus}</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Row>
          <Col xs={12} md={8}>
            {this.props.children}
          </Col>
        </Row>
        <Navbar bg="light" fixed="bottom" expand="sm">
          <Navbar.Text className="mr-auto">{Wordings.anonCopyright}</Navbar.Text>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            <Nav>
              <Navbar.Text>{Wordings.build}{' '}{build}</Navbar.Text>
              <Nav.Link target='_blank' href="https://www.mhlk-irm.com/terms_of_use">{Wordings.terms}</Nav.Link>
              <Nav.Link target='_blank' href="https://www.mhlk-irm.com/privacy-policy">{Wordings.privacy}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
};

export default connector(AnonLayout);
