import { Action, Reducer } from 'redux';
import produce from 'immer';

import { AppThunk } from '.';
import { api, ITransactionInfo, ITransactionListForm, ITransactionListSimpleFilter, ITransactionListDetailFilter } from '../api';

export interface TransactionListState {
    isLoading: boolean;
    isError: boolean;
    data: ITransactionInfo[];

    filter: ITransactionListSimpleFilter | ITransactionListDetailFilter;
    simple: boolean;
    sort: string;
    page: number;
    sizePerPage: number;
    totalSize: number;
    requestId: number;
}

interface FetchTransactionsBeginAction {
    type: 'FETCH_TRANSACTIONS_BEGIN';

    filter: ITransactionListSimpleFilter | ITransactionListDetailFilter;
    simple: boolean;
    sort: string;
    page: number;
    sizePerPage: number;
    requestId: number;
}

interface FetchTransactionsSuccessAction {
    type: 'FETCH_TRANSACTIONS_SUCCESS';
    data: ITransactionInfo[];

    filter: ITransactionListSimpleFilter | ITransactionListDetailFilter;
    simple: boolean;
    sort: string;
    page: number;
    sizePerPage: number;
    totalSize: number;
    requestId: number;
}

interface FetchTransactionsFailureAction {
    type: 'FETCH_TRANSACTIONS_FAILURE';
    requestId: number;
}

interface UpdateTransactionAction {
    type: 'UPDATE_TRANSACTION';
    data: ITransactionInfo;
}

type KnownAction = FetchTransactionsBeginAction | FetchTransactionsSuccessAction | FetchTransactionsFailureAction
    | UpdateTransactionAction;

export const actionCreators = {
    fetchTransactionList: (form: ITransactionListForm): AppThunk => (dispatch, getState) => {
        const appState = getState();
        const { filter, page, sizePerPage, sort } = form;
        if (appState && appState.transactionList && (
                filter !== appState.transactionList.filter
                || page !== appState.transactionList.page
                || sizePerPage !== appState.transactionList.sizePerPage
                || sort !== appState.transactionList.sort)) {

            const requestId = appState.transactionList.requestId + 1;
            dispatch({ type: 'FETCH_TRANSACTIONS_BEGIN', filter, page, sizePerPage, sort, requestId });
            const f = {...form, requestId};
            api.TransactionList(f)
                .then(response => response.data)
                .then((result) => {
                    if (result.succeeded) {
                        const { data, totalSize, filter, page, sizePerPage, sort, requestId } = result;
                        dispatch({ type: 'FETCH_TRANSACTIONS_SUCCESS', filter, page, sizePerPage, sort, data, totalSize, requestId });
                    } else {
                        dispatch({ type: 'FETCH_TRANSACTIONS_FAILURE', requestId });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'FETCH_TRANSACTIONS_FAILURE', requestId });
                });
        }
    },
    updateTransaction: (data: ITransactionInfo) => ({ type: 'UPDATE_TRANSACTION', data } as UpdateTransactionAction),
};

const unloadedState: TransactionListState = {
    isLoading: false,
    isError: false,
    data: [], 

    simple: true,
    filter: {},
    sort: "",
    requestId: 1,
    page: 0,
    sizePerPage: 10,
    totalSize: -1
};

export const reducer: Reducer<TransactionListState> = (state: TransactionListState | undefined, incomingAction: Action): TransactionListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'FETCH_TRANSACTIONS_BEGIN':
            {
                return produce(state, d => {
                    const {filter, page, sizePerPage, sort, requestId} = action;
                    d.isLoading = true;
                    d.isError = false;
                    d.data = [];
                    d.requestId = requestId;

                    d.filter = filter;
                    d.page = page;
                    d.sizePerPage = sizePerPage;
                    d.sort = sort;
                });
            }
        case 'FETCH_TRANSACTIONS_SUCCESS':
            if (action.requestId === state.requestId) {
                return produce(state, d => {
                    const {filter, page, sizePerPage, sort, data, totalSize} = action;
                    d.isLoading = false;
                    d.isError = false;
                    d.data = data;

                    d.filter = filter;
                    d.page = page;
                    d.sizePerPage = sizePerPage;
                    d.totalSize = totalSize;
                    d.sort = sort;
                });
            }
            break;
        case 'FETCH_TRANSACTIONS_FAILURE':
            if (action.requestId === state.requestId) {
                return produce(state, d => {
                    d.isLoading = false;
                    d.isError = true;
                    d.data = [];
                });
            }
            break;
        case 'UPDATE_TRANSACTION':
            return produce(state, d => {
                const index = d.data.findIndex(t => t.txnid === action.data.txnid);
                d.data.splice(index, 1, action.data);
            });
        }

    return state;
};
