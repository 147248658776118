import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";
import { OptionType } from './Wordings';

export const CountryOptions:OptionType[] = [
    {value:'AFG', group: 1},
    {value:'ALB', group: 1},
    {value:'DZA', group: 1},
    {value:'ASM', group: 1},
    {value:'AND', group: 1},
    {value:'AGO', group: 1},
    {value:'AIA', group: 1},
    {value:'ATA', group: 1},
    {value:'ATG', group: 1},
    {value:'ARG', group: 1},
    {value:'ARM', group: 1},
    {value:'ABW', group: 1},
    {value:'AUS', group: 1},
    {value:'AUT', group: 1},
    {value:'AZE', group: 1},
    {value:'BHS', group: 1},
    {value:'BHR', group: 1},
    {value:'BGD', group: 1},
    {value:'BRB', group: 1},
    {value:'BLR', group: 1},
    {value:'BEL', group: 1},
    {value:'BLZ', group: 1},
    {value:'BEN', group: 1},
    {value:'BMU', group: 1},
    {value:'BTN', group: 1},
    {value:'BOL', group: 1},
    {value:'BES', group: 1},
    {value:'BIH', group: 1},
    {value:'BWA', group: 1},
    {value:'BVT', group: 1},
    {value:'BRA', group: 1},
    {value:'IOT', group: 1},
    {value:'BRN', group: 1},
    {value:'BGR', group: 1},
    {value:'BFA', group: 1},
    {value:'BDI', group: 1},
    {value:'CPV', group: 1},
    {value:'KHM', group: 1},
    {value:'CMR', group: 1},
    {value:'CAN', group: 1},
    {value:'CYM', group: 1},
    {value:'CAF', group: 1},
    {value:'TCD', group: 1},
    {value:'CHL', group: 1},
    {value:'CHN', group: 1},
    {value:'CXR', group: 1},
    {value:'CCK', group: 1},
    {value:'COL', group: 1},
    {value:'COM', group: 1},
    {value:'COD', group: 1},
    {value:'COG', group: 1},
    {value:'COK', group: 1},
    {value:'CRI', group: 1},
    {value:'HRV', group: 1},
    {value:'CUB', group: 1},
    {value:'CUW', group: 1},
    {value:'CYP', group: 1},
    {value:'CZE', group: 1},
    {value:'CIV', group: 1},
    {value:'DNK', group: 1},
    {value:'DJI', group: 1},
    {value:'DMA', group: 1},
    {value:'DOM', group: 1},
    {value:'ECU', group: 1},
    {value:'EGY', group: 1},
    {value:'SLV', group: 1},
    {value:'GNQ', group: 1},
    {value:'ERI', group: 1},
    {value:'EST', group: 1},
    {value:'SWZ', group: 1},
    {value:'ETH', group: 1},
    {value:'FLK', group: 1},
    {value:'FRO', group: 1},
    {value:'FJI', group: 1},
    {value:'FIN', group: 1},
    {value:'FRA', group: 1},
    {value:'GUF', group: 1},
    {value:'PYF', group: 1},
    {value:'ATF', group: 1},
    {value:'GAB', group: 1},
    {value:'GMB', group: 1},
    {value:'GEO', group: 1},
    {value:'DEU', group: 1},
    {value:'GHA', group: 1},
    {value:'GIB', group: 1},
    {value:'GRC', group: 1},
    {value:'GRL', group: 1},
    {value:'GRD', group: 1},
    {value:'GLP', group: 1},
    {value:'GUM', group: 1},
    {value:'GTM', group: 1},
    {value:'GGY', group: 1},
    {value:'GIN', group: 1},
    {value:'GNB', group: 1},
    {value:'GUY', group: 1},
    {value:'HTI', group: 1},
    {value:'HMD', group: 1},
    {value:'VAT', group: 1},
    {value:'HND', group: 1},
    {value:'HKG', group: 1},
    {value:'HUN', group: 1},
    {value:'ISL', group: 1},
    {value:'IND', group: 1},
    {value:'IDN', group: 1},
    {value:'IRN', group: 1},
    {value:'IRQ', group: 1},
    {value:'IRL', group: 1},
    {value:'IMN', group: 1},
    {value:'ISR', group: 1},
    {value:'ITA', group: 1},
    {value:'JAM', group: 1},
    {value:'JPN', group: 1},
    {value:'JEY', group: 1},
    {value:'JOR', group: 1},
    {value:'KAZ', group: 1},
    {value:'KEN', group: 1},
    {value:'KIR', group: 1},
    {value:'PRK', group: 1},
    {value:'KOR', group: 1},
    {value:'KWT', group: 1},
    {value:'KGZ', group: 1},
    {value:'LAO', group: 1},
    {value:'LVA', group: 1},
    {value:'LBN', group: 1},
    {value:'LSO', group: 1},
    {value:'LBR', group: 1},
    {value:'LBY', group: 1},
    {value:'LIE', group: 1},
    {value:'LTU', group: 1},
    {value:'LUX', group: 1},
    {value:'MAC', group: 1},
    {value:'MDG', group: 1},
    {value:'MWI', group: 1},
    {value:'MYS', group: 1},
    {value:'MDV', group: 1},
    {value:'MLI', group: 1},
    {value:'MLT', group: 1},
    {value:'MHL', group: 1},
    {value:'MTQ', group: 1},
    {value:'MRT', group: 1},
    {value:'MUS', group: 1},
    {value:'MYT', group: 1},
    {value:'MEX', group: 1},
    {value:'FSM', group: 1},
    {value:'MDA', group: 1},
    {value:'MCO', group: 1},
    {value:'MNG', group: 1},
    {value:'MNE', group: 1},
    {value:'MSR', group: 1},
    {value:'MAR', group: 1},
    {value:'MOZ', group: 1},
    {value:'MMR', group: 1},
    {value:'NAM', group: 1},
    {value:'NRU', group: 1},
    {value:'NPL', group: 1},
    {value:'NLD', group: 1},
    {value:'NCL', group: 1},
    {value:'NZL', group: 1},
    {value:'NIC', group: 1},
    {value:'NER', group: 1},
    {value:'NGA', group: 1},
    {value:'NIU', group: 1},
    {value:'NFK', group: 1},
    {value:'MNP', group: 1},
    {value:'NOR', group: 1},
    {value:'OMN', group: 1},
    {value:'PAK', group: 1},
    {value:'PLW', group: 1},
    {value:'PSE', group: 1},
    {value:'PAN', group: 1},
    {value:'PNG', group: 1},
    {value:'PRY', group: 1},
    {value:'PER', group: 1},
    {value:'PHL', group: 1},
    {value:'PCN', group: 1},
    {value:'POL', group: 1},
    {value:'PRT', group: 1},
    {value:'PRI', group: 1},
    {value:'QAT', group: 1},
    {value:'MKD', group: 1},
    {value:'ROU', group: 1},
    {value:'RUS', group: 1},
    {value:'RWA', group: 1},
    {value:'REU', group: 1},
    {value:'BLM', group: 1},
    {value:'SHN', group: 1},
    {value:'KNA', group: 1},
    {value:'LCA', group: 1},
    {value:'MAF', group: 1},
    {value:'SPM', group: 1},
    {value:'VCT', group: 1},
    {value:'WSM', group: 1},
    {value:'SMR', group: 1},
    {value:'STP', group: 1},
    {value:'SAU', group: 1},
    {value:'SEN', group: 1},
    {value:'SRB', group: 1},
    {value:'SYC', group: 1},
    {value:'SLE', group: 1},
    {value:'SGP', group: 1},
    {value:'SXM', group: 1},
    {value:'SVK', group: 1},
    {value:'SVN', group: 1},
    {value:'SLB', group: 1},
    {value:'SOM', group: 1},
    {value:'ZAF', group: 1},
    {value:'SGS', group: 1},
    {value:'SSD', group: 1},
    {value:'ESP', group: 1},
    {value:'LKA', group: 1},
    {value:'SDN', group: 1},
    {value:'SUR', group: 1},
    {value:'SJM', group: 1},
    {value:'SWE', group: 1},
    {value:'CHE', group: 1},
    {value:'SYR', group: 1},
    {value:'TWN', group: 1},
    {value:'TJK', group: 1},
    {value:'TZA', group: 1},
    {value:'THA', group: 1},
    {value:'TLS', group: 1},
    {value:'TGO', group: 1},
    {value:'TKL', group: 1},
    {value:'TON', group: 1},
    {value:'TTO', group: 1},
    {value:'TUN', group: 1},
    {value:'TUR', group: 1},
    {value:'TKM', group: 1},
    {value:'TCA', group: 1},
    {value:'TUV', group: 1},
    {value:'UGA', group: 1},
    {value:'UKR', group: 1},
    {value:'ARE', group: 1},
    {value:'GBR', group: 1},
    {value:'UMI', group: 1},
    {value:'USA', group: 1},
    {value:'URY', group: 1},
    {value:'UZB', group: 1},
    {value:'VUT', group: 1},
    {value:'VEN', group: 1},
    {value:'VNM', group: 1},
    {value:'VGB', group: 1},
    {value:'VIR', group: 1},
    {value:'WLF', group: 1},
    {value:'ESH', group: 1},
    {value:'YEM', group: 1},
    {value:'ZMB', group: 1},
    {value:'ZWE', group: 1},
    {value:'ALA', group: 1},
]
export interface ICountryWordings extends LocalizedStringsMethods {
    AFG: string;
    ALB: string;
    DZA: string;
    ASM: string;
    AND: string;
    AGO: string;
    AIA: string;
    ATA: string;
    ATG: string;
    ARG: string;
    ARM: string;
    ABW: string;
    AUS: string;
    AUT: string;
    AZE: string;
    BHS: string;
    BHR: string;
    BGD: string;
    BRB: string;
    BLR: string;
    BEL: string;
    BLZ: string;
    BEN: string;
    BMU: string;
    BTN: string;
    BOL: string;
    BES: string;
    BIH: string;
    BWA: string;
    BVT: string;
    BRA: string;
    IOT: string;
    BRN: string;
    BGR: string;
    BFA: string;
    BDI: string;
    CPV: string;
    KHM: string;
    CMR: string;
    CAN: string;
    CYM: string;
    CAF: string;
    TCD: string;
    CHL: string;
    CHN: string;
    CXR: string;
    CCK: string;
    COL: string;
    COM: string;
    COD: string;
    COG: string;
    COK: string;
    CRI: string;
    HRV: string;
    CUB: string;
    CUW: string;
    CYP: string;
    CZE: string;
    CIV: string;
    DNK: string;
    DJI: string;
    DMA: string;
    DOM: string;
    ECU: string;
    EGY: string;
    SLV: string;
    GNQ: string;
    ERI: string;
    EST: string;
    SWZ: string;
    ETH: string;
    FLK: string;
    FRO: string;
    FJI: string;
    FIN: string;
    FRA: string;
    GUF: string;
    PYF: string;
    ATF: string;
    GAB: string;
    GMB: string;
    GEO: string;
    DEU: string;
    GHA: string;
    GIB: string;
    GRC: string;
    GRL: string;
    GRD: string;
    GLP: string;
    GUM: string;
    GTM: string;
    GGY: string;
    GIN: string;
    GNB: string;
    GUY: string;
    HTI: string;
    HMD: string;
    VAT: string;
    HND: string;
    HKG: string;
    HUN: string;
    ISL: string;
    IND: string;
    IDN: string;
    IRN: string;
    IRQ: string;
    IRL: string;
    IMN: string;
    ISR: string;
    ITA: string;
    JAM: string;
    JPN: string;
    JEY: string;
    JOR: string;
    KAZ: string;
    KEN: string;
    KIR: string;
    PRK: string;
    KOR: string;
    KWT: string;
    KGZ: string;
    LAO: string;
    LVA: string;
    LBN: string;
    LSO: string;
    LBR: string;
    LBY: string;
    LIE: string;
    LTU: string;
    LUX: string;
    MAC: string;
    MDG: string;
    MWI: string;
    MYS: string;
    MDV: string;
    MLI: string;
    MLT: string;
    MHL: string;
    MTQ: string;
    MRT: string;
    MUS: string;
    MYT: string;
    MEX: string;
    FSM: string;
    MDA: string;
    MCO: string;
    MNG: string;
    MNE: string;
    MSR: string;
    MAR: string;
    MOZ: string;
    MMR: string;
    NAM: string;
    NRU: string;
    NPL: string;
    NLD: string;
    NCL: string;
    NZL: string;
    NIC: string;
    NER: string;
    NGA: string;
    NIU: string;
    NFK: string;
    MNP: string;
    NOR: string;
    OMN: string;
    PAK: string;
    PLW: string;
    PSE: string;
    PAN: string;
    PNG: string;
    PRY: string;
    PER: string;
    PHL: string;
    PCN: string;
    POL: string;
    PRT: string;
    PRI: string;
    QAT: string;
    MKD: string;
    ROU: string;
    RUS: string;
    RWA: string;
    REU: string;
    BLM: string;
    SHN: string;
    KNA: string;
    LCA: string;
    MAF: string;
    SPM: string;
    VCT: string;
    WSM: string;
    SMR: string;
    STP: string;
    SAU: string;
    SEN: string;
    SRB: string;
    SYC: string;
    SLE: string;
    SGP: string;
    SXM: string;
    SVK: string;
    SVN: string;
    SLB: string;
    SOM: string;
    ZAF: string;
    SGS: string;
    SSD: string;
    ESP: string;
    LKA: string;
    SDN: string;
    SUR: string;
    SJM: string;
    SWE: string;
    CHE: string;
    SYR: string;
    TWN: string;
    TJK: string;
    TZA: string;
    THA: string;
    TLS: string;
    TGO: string;
    TKL: string;
    TON: string;
    TTO: string;
    TUN: string;
    TUR: string;
    TKM: string;
    TCA: string;
    TUV: string;
    UGA: string;
    UKR: string;
    ARE: string;
    GBR: string;
    UMI: string;
    USA: string;
    URY: string;
    UZB: string;
    VUT: string;
    VEN: string;
    VNM: string;
    VGB: string;
    VIR: string;
    WLF: string;
    ESH: string;
    YEM: string;
    ZMB: string;
    ZWE: string;
    ALA: string;
}
export const CountryWordings:ICountryWordings = new LocalizedStrings({
    en: {
        AFG: "Afghanistan",
        ALB: "Albania",
        DZA: "Algeria",
        ASM: "American Samoa",
        AND: "Andorra",
        AGO: "Angola",
        AIA: "Anguilla",
        ATA: "Antarctica",
        ATG: "Antigua and Barbuda",
        ARG: "Argentina",
        ARM: "Armenia",
        ABW: "Aruba",
        AUS: "Australia",
        AUT: "Austria",
        AZE: "Azerbaijan",
        BHS: "Bahamas",
        BHR: "Bahrain",
        BGD: "Bangladesh",
        BRB: "Barbados",
        BLR: "Belarus",
        BEL: "Belgium",
        BLZ: "Belize",
        BEN: "Benin",
        BMU: "Bermuda",
        BTN: "Bhutan",
        BOL: "Bolivia",
        BES: "Bonaire, Sint Eustatius and Saba",
        BIH: "Bosnia and Herzegovina",
        BWA: "Botswana",
        BVT: "Bouvet Island",
        BRA: "Brazil",
        IOT: "British Indian Ocean Territory",
        BRN: "Brunei Darussalam",
        BGR: "Bulgaria",
        BFA: "Burkina Faso",
        BDI: "Burundi",
        CPV: "Cabo Verde",
        KHM: "Cambodia",
        CMR: "Cameroon",
        CAN: "Canada",
        CYM: "Cayman Islands",
        CAF: "Central African Republic",
        TCD: "Chad",
        CHL: "Chile",
        CHN: "China",
        CXR: "Christmas Island",
        CCK: "Cocos Islands",
        COL: "Colombia",
        COM: "Comoros",
        COD: "Congo",
        COG: "Congo",
        COK: "Cook Islands",
        CRI: "Costa Rica",
        HRV: "Croatia",
        CUB: "Cuba",
        CUW: "Curaçao",
        CYP: "Cyprus",
        CZE: "Czechia",
        CIV: "Côte d'Ivoire",
        DNK: "Denmark",
        DJI: "Djibouti",
        DMA: "Dominica",
        DOM: "Dominican Republic",
        ECU: "Ecuador",
        EGY: "Egypt",
        SLV: "El Salvador",
        GNQ: "Equatorial Guinea",
        ERI: "Eritrea",
        EST: "Estonia",
        SWZ: "Eswatini",
        ETH: "Ethiopia",
        FLK: "Falkland Islands [Malvinas]",
        FRO: "Faroe Islands",
        FJI: "Fiji",
        FIN: "Finland",
        FRA: "France",
        GUF: "French Guiana",
        PYF: "French Polynesia",
        ATF: "French Southern Territories",
        GAB: "Gabon",
        GMB: "Gambia",
        GEO: "Georgia",
        DEU: "Germany",
        GHA: "Ghana",
        GIB: "Gibraltar",
        GRC: "Greece",
        GRL: "Greenland",
        GRD: "Grenada",
        GLP: "Guadeloupe",
        GUM: "Guam",
        GTM: "Guatemala",
        GGY: "Guernsey",
        GIN: "Guinea",
        GNB: "Guinea-Bissau",
        GUY: "Guyana",
        HTI: "Haiti",
        HMD: "Heard Island and McDonald Islands",
        VAT: "Holy See",
        HND: "Honduras",
        HKG: "Hong Kong",
        HUN: "Hungary",
        ISL: "Iceland",
        IND: "India",
        IDN: "Indonesia",
        IRN: "Iran",
        IRQ: "Iraq",
        IRL: "Ireland",
        IMN: "Isle of Man",
        ISR: "Israel",
        ITA: "Italy",
        JAM: "Jamaica",
        JPN: "Japan",
        JEY: "Jersey",
        JOR: "Jordan",
        KAZ: "Kazakhstan",
        KEN: "Kenya",
        KIR: "Kiribati",
        PRK: "North Korea",
        KOR: "South Korea",
        KWT: "Kuwait",
        KGZ: "Kyrgyzstan",
        LAO: "Lao People's Democratic Republic",
        LVA: "Latvia",
        LBN: "Lebanon",
        LSO: "Lesotho",
        LBR: "Liberia",
        LBY: "Libya",
        LIE: "Liechtenstein",
        LTU: "Lithuania",
        LUX: "Luxembourg",
        MAC: "Macao",
        MDG: "Madagascar",
        MWI: "Malawi",
        MYS: "Malaysia",
        MDV: "Maldives",
        MLI: "Mali",
        MLT: "Malta",
        MHL: "Marshall Islands",
        MTQ: "Martinique",
        MRT: "Mauritania",
        MUS: "Mauritius",
        MYT: "Mayotte",
        MEX: "Mexico",
        FSM: "Micronesia",
        MDA: "Moldova",
        MCO: "Monaco",
        MNG: "Mongolia",
        MNE: "Montenegro",
        MSR: "Montserrat",
        MAR: "Morocco",
        MOZ: "Mozambique",
        MMR: "Myanmar",
        NAM: "Namibia",
        NRU: "Nauru",
        NPL: "Nepal",
        NLD: "Netherlands",
        NCL: "New Caledonia",
        NZL: "New Zealand",
        NIC: "Nicaragua",
        NER: "Niger",
        NGA: "Nigeria",
        NIU: "Niue",
        NFK: "Norfolk Island",
        MNP: "Northern Mariana Islands",
        NOR: "Norway",
        OMN: "Oman",
        PAK: "Pakistan",
        PLW: "Palau",
        PSE: "Palestine, State of",
        PAN: "Panama",
        PNG: "Papua New Guinea",
        PRY: "Paraguay",
        PER: "Peru",
        PHL: "Philippines",
        PCN: "Pitcairn",
        POL: "Poland",
        PRT: "Portugal",
        PRI: "Puerto Rico",
        QAT: "Qatar",
        MKD: "Republic of North Macedonia",
        ROU: "Romania",
        RUS: "Russian Federation",
        RWA: "Rwanda",
        REU: "Réunion",
        BLM: "Saint Barthélemy",
        SHN: "Saint Helena, Ascension and Tristan da Cunha",
        KNA: "Saint Kitts and Nevis",
        LCA: "Saint Lucia",
        MAF: "Saint Martin",
        SPM: "Saint Pierre and Miquelon",
        VCT: "Saint Vincent and the Grenadines",
        WSM: "Samoa",
        SMR: "San Marino",
        STP: "Sao Tome and Principe",
        SAU: "Saudi Arabia",
        SEN: "Senegal",
        SRB: "Serbia",
        SYC: "Seychelles",
        SLE: "Sierra Leone",
        SGP: "Singapore",
        SXM: "Sint Maarten",
        SVK: "Slovakia",
        SVN: "Slovenia",
        SLB: "Solomon Islands",
        SOM: "Somalia",
        ZAF: "South Africa",
        SGS: "South Georgia and the South Sandwich Islands",
        SSD: "South Sudan",
        ESP: "Spain",
        LKA: "Sri Lanka",
        SDN: "Sudan",
        SUR: "Suriname",
        SJM: "Svalbard and Jan Mayen",
        SWE: "Sweden",
        CHE: "Switzerland",
        SYR: "Syrian Arab Republic",
        TWN: "Taiwan",
        TJK: "Tajikistan",
        TZA: "Tanzania, United Republic of",
        THA: "Thailand",
        TLS: "Timor-Leste",
        TGO: "Togo",
        TKL: "Tokelau",
        TON: "Tonga",
        TTO: "Trinidad and Tobago",
        TUN: "Tunisia",
        TUR: "Turkey",
        TKM: "Turkmenistan",
        TCA: "Turks and Caicos Islands",
        TUV: "Tuvalu",
        UGA: "Uganda",
        UKR: "Ukraine",
        ARE: "United Arab Emirates",
        GBR: "United Kingdom",
        UMI: "United States Minor Outlying Islands",
        USA: "United States of America",
        URY: "Uruguay",
        UZB: "Uzbekistan",
        VUT: "Vanuatu",
        VEN: "Venezuela",
        VNM: "Viet Nam",
        VGB: "Virgin Islands (British)",
        VIR: "Virgin Islands (U.S.)",
        WLF: "Wallis and Futuna",
        ESH: "Western Sahara",
        YEM: "Yemen",
        ZMB: "Zambia",
        ZWE: "Zimbabwe",
        ALA: "Åland Islands",
    }
})
