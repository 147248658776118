import * as React from 'react';

import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import { CommonWordings as Wordings } from '../../Wordings';

type ComponentProps = {
    show: boolean;
    title: string;
    heading: string;
    text: string | ( string | JSX.Element)[];
    alert: | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light';

    onYes: () => void;
    onNo: () => void;
};

interface ComponentState {
}
    
class ConfirmAction extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        return (
            <Modal show={this.props.show}>
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Alert variant={this.props.alert}>
                        <Alert.Heading>{this.props.heading}</Alert.Heading>
                        <p>{this.props.text}</p>
                    </Alert>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onNo}>{Wordings.no}</Button>
                    <Button variant="primary" onClick={this.props.onYes}>{Wordings.yes}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
};

export default ConfirmAction;
