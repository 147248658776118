import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/WalletData';
import { DashboardWalletWordings as Wordings } from '../../Wordings';
import { IWalletInfo } from '../../api';
import HasNoWallet from '../Wallet/HasNoWallet';
import Wallet from '../Wallet/Wallet';
import WalletsLoading from '../Wallet/WalletsLoading';
import ErrorLoading from '../Util/ErrorLoading';

const connector = connect((state: ApplicationState) => state.walletList, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
    selectedWallet?: IWalletInfo;
}
    
/*
https://fgcorp.atlassian.net/browse/FGC-11
User Dashboard
This is the main page for the user.  The user can:
See their wallet address on the left side
See the QR code for their account.
See their coin balance for the selected wallet (only approval dashboard shows Ether balance)
Can select different wallets to display the address and balance for.
Can create new wallet by using the help screen which is accessed by clicking the help button
Can logout from the system

https://fgcorp.atlassian.net/browse/FGC-15
Select Wallet to Display Pop-up
This screen allows user who have more than one wallet to select which wallet’s balance will be displayed on their dashboard.
The user is presented wallets that are associated with their profile.
They highlight the wallet they want to show.
Click “display” to select that wallet 
*/
enum DashboardView { Loading, Error, NoWallet, HasWallets }
class DashboardWallet extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    rowClicked = (e: React.SyntheticEvent, row: IWalletInfo): void  => {
        this.setState(produce(d => {
            d.selectedWallet = row;
        }));
    }

    public componentDidMount() {
        this.props.fetchWalletList();
    }

    public render() {
        const { isLoading, isError, data } = this.props;
        let view: DashboardView;
        if (isLoading) {
            view = DashboardView.Loading;
        } else if (isError) {
            view = DashboardView.Error;
        } else if (data.length === 0) {
            view = DashboardView.NoWallet;
        } else {
            view = DashboardView.HasWallets;
        }
        return (
            <>
                {view === DashboardView.Loading &&
                    <WalletsLoading title={Wordings.title} text={Wordings.loadingText}/>
                }
                {view === DashboardView.Error &&
                    <ErrorLoading title={Wordings.title} heading={Wordings.errorLoadingTitle} text={Wordings.errorLoadingText}/>
                }
                {view === DashboardView.NoWallet &&
                    <HasNoWallet/>
                }
                {view === DashboardView.HasWallets &&
                    <Row>
                        {data.map(wallet => 
                            <Col xs={12} sm={9} md={6} lg={4} key={wallet.walletid}>
                                <Wallet wallet={wallet}/>
                            </Col>
                        )}
                    </Row>
                }
            </>
        );
    }
};

export default connector(DashboardWallet);