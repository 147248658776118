import * as React from 'react';

import Card from 'react-bootstrap/Card';
import NumberFormat from 'react-number-format';
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next';

import { TransactionWordings as Wordings, TransactionStateWordings } from '../../Wordings';
import { ITransactionInfo } from '../../api';
import Moment from 'react-moment';

type ComponentProps = {
    transaction: ITransactionInfo,
}

interface ComponentState {
}
interface Detail {
    field: string;
    label: string;
    value: any;
    format: 'text' | 'date' | 'amount';
}
class TransactionDetail extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        const t = this.props.transaction;
        const columns: ColumnDescription[] = [{
            dataField: 'label',
            text: Wordings.transactionId,
            headerStyle: () => {
                return { width: '14em' }; 
            }
        }, {
            dataField: 'value',
            text: t.transactionId,
            formatter: (col: any, row: Detail) => {
                if (row.format === 'amount') {
                    return (<NumberFormat value={row.value} displayType={'text'} thousandSeparator={true}/>);
                } else if (row.format === 'date') {
                    return (<Moment local>{row.value}</Moment>);
                }  else {
                    return row.value;
                }
            }
       }];

       const status = TransactionStateWordings.getString(t.status) || t.status;
       const data:Detail[] = [
        {field: 'senderWalletKey', label: Wordings.senderWalletKey, value: t.senderWalletKey, format: 'text'},
        {field: 'sender', label: Wordings.sender, value: t.sender, format: 'text'},
        {field: 'receiverWalletKey', label: Wordings.receiverWalletKey, value: t.receiverWalletKey, format: 'text'},
        {field: 'receiver', label: Wordings.receiver, value: t.receiver, format: 'text'},
        {field: 'amount', label: Wordings.amount, value: t.amount, format: 'text'},
        {field: 'startedAt', label: Wordings.startedAt, value: t.startedAt, format: 'text'},
        {field: 'completedAt', label: Wordings.completedAt, value: t.completedAt, format: 'text'},
        {field: 'statusAt', label: Wordings.statusAt, value: t.statusAt, format: 'text'},
        {field: 'status', label: Wordings.status, value: status, format: 'text'},
       ];

       return (
            <Card>
                <Card.Body>
                    <Card.Title as="h3">{Wordings.detailTtitle}</Card.Title>
                </Card.Body>
                <BootstrapTable keyField='field' data={ data } columns={ columns } bordered={ false }/>
            </Card>
        );
    }
};

export default TransactionDetail;
