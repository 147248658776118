import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { WalletHelpWordings as Wordings } from '../../Wordings';
import AppRoutes from '../../AppRoutes';

type ComponentProps = {
}

interface ComponentState {
}
    
/*
https://fgcorp.atlassian.net/browse/FGC-9
Help Screen
The main intention for the help screen is to provide the user with a way to create a new wallet should they need to replace it or if they want to have multiple wallets. 
The help screen pop-up is triggered by a button located on all dashboards. 
At the bottom of the page, they will be able to select create a new wallet.  Upon click that, the wallet creation functionality along with all the confirmation messages will be presented to the user.
Also, the dashboard coin balance display will be updated to reflect the most recent wallet created.
*/
class WalletHelp extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        return (
            <Card>
                <Card.Header as="h3">{Wordings.title}</Card.Header>
                <Card.Body>
                    <Card.Text>{Wordings.formatString(Wordings.create, <br/>)}</Card.Text>
                    <Card.Text>{Wordings.formatString(Wordings.norecover, <br/>)}</Card.Text>
                    <Card.Text>{Wordings.formatString(Wordings.contact, <br/>)}</Card.Text>
                    <Card.Text>{Wordings.formatString(Wordings.assistance, <br/>)}</Card.Text>
                    <LinkContainer to={AppRoutes.WalletCreate}>
                        <Button variant="primary">{Wordings.action}</Button>
                    </LinkContainer>
                </Card.Body>
            </Card>
        );
    }
};

export default WalletHelp;
