import * as React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ApprovalList from '../User/ApprovalList';
import ReviewCodeList from '../ReviewCode/ReviewCodeList';

type ComponentProps = {};

interface ComponentState {
}
    
/*
https://fgcorp.atlassian.net/browse/FGC-7
Approval Dashboard
This dashboard is different from the typical user.  The dashboard presents specific information to the review.
A code is generated for the reviewer, who will give this code to registrants they invite.  This code will be entered on the user information screen and will be used for routing registrants to a particular reviewer for approval or rejection.  This code will be displayed om the dashboard for their reference.
The reviewer will be presented with a list of “pending” registrants where they will approve or reject
Approve: 
Allows the user to use the system and allows them to receive coin transfer to the user’s wallet. 
System checks for a wallet associated with the user.  
If not wallet, the system will present wallet information and confirmation that the information was saved.
The system will associate the new wallet with the user that was approved
If wallet exists, the system will transfer the coins to the wallet. 
Reject: the user will be locked from the system but will remain in the DB.  A flag will be set so that we can create reports the include these users in.  The system will screen for the flagged ID’s and names to prevent them from registering again.
The Approval Dashboard will contain the same elements as the user dashboard.
Wallet balance for MHLK coins
Wallet balance for Ether coins is presented
*/
export default class DashboardApproval extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        return (
            <Row>
                <Col xs={12} md={8}>
                    <ApprovalList />
                </Col>
                <Col xs={12} md={4}>
                    <ReviewCodeList />
                </Col>
            </Row>
        );
    }
}