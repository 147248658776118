import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Select, {ValueType, OptionTypeBase, ActionMeta} from 'react-select';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/WalletData';
import { MintWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, Currency } from '../../api';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const connector = connect((state: ApplicationState) => state.walletList, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
    readonly publicKey: string;
    readonly amount: string;
    readonly currency: Currency;
    readonly show: boolean;
    readonly toast: ToastProps
}

class Mint extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        currency: 'mhlk',
        amount: "0",
        publicKey: '',
        show: false,
        toast: DefaultToastProps
    };

    handleSelectChange = (selectedOption: ValueType<OptionTypeBase>, actionMeta: ActionMeta<OptionTypeBase>) => {
        const value = (selectedOption as OptionTypeBase).value;
        const name = actionMeta.name;
        this.setState(produce(d => {
            d.publicKey = (name === 'publicKey') ? value : d.publicKey;
        }));
    }

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.amount = (name === 'amount') ? value : d.amount;
        }));
    }

    private setShow = (show: boolean) => {
        this.setState(produce(d => {
            d.show = show;
        }));
    }

    private handleClose = () => this.setShow(false);
    private handleShow = () => this.setShow(true);
    
    loadWallets = () => {
        this.props.fetchWalletList();
    }

    handleSubmit = () => {
        const {publicKey, amount, currency} = { ...this.state };
        api.Mint({publicKey, amount, currency})
            .then((response: { data: any; }) => response.data)
            .catch((error: any) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.mint;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            }).finally(() => {
                this.setShow(false);
            });
    }

    componentDidMount() {
        this.loadWallets();
    }

    public render() {
        const { publicKey, amount, show, toast } = this.state;

        const { data, isLoading, isError } = this.props;
        const wp = isError ? Wordings.loadingError : isLoading ? Wordings.loading : Wordings.nowallet;
        const wallets = data.map((w) => ({value: w.publicKey, label: `${w.publicKey}`}));
        const disabled = wallets.length === 0;
        return (
            <>
                <Button variant="primary" size="lg" style={{marginTop: '2em'}} onClick={this.handleShow}>
                    {Wordings.title}
                </Button>{' '}
                <Modal show={show} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>{Wordings.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="publicKey">
                            <Form.Label>{Wordings.publicKey}</Form.Label>
                            {wallets.length === 0 &&
                                <Form.Control readOnly type="text" value={wp}/>
                            }
                            {wallets.length > 0 &&
                                <Select name="publicKey" 
                                options={wallets}
                                value={wallets.find(o => o.value === publicKey)}
                                onChange={this.handleSelectChange}
                                ></Select>
                            }
                        </Form.Group>
                        <Form.Group controlId="amount">
                            <Form.Label>{Wordings.amount}</Form.Label>
                            <Form.Control required type="number" 
                                name="amount"
                                value={amount}
                                onChange={this.handleChange}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>{Wordings.cancel}</Button>
                        <Button variant="primary" disabled={disabled} onClick={this.handleSubmit}>{Wordings.submit}</Button>
                    </Modal.Footer>
                </Modal>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </>
        );
    }
};

export default connector(Mint);
