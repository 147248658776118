import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable, { ColumnDescription} from 'react-bootstrap-table-next';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/TransactionData';
import { AnonTransferStatusWordings as Wordings, CommonWordings, TransactionStateWordings, CurrencyWordings } from '../../Wordings';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';
import { api, IAnonTransferStatusRequest as IForm, IAnonTransferStatusRequestErrors as IFormErrors, IAnonTransferInfo, IResponseError } from '../../api';
import NumberFormat from 'react-number-format';
import * as U from '../../utils/BbUtil';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';

const errorMap = {
    query: {
        required: Wordings.queryRequired
    },
    captcha: {
        'missing-input-secret':	CommonWordings.missingInputSecret,
        'invalid-input-secret':	CommonWordings.invalidInputSecret,
        'missing-input-response': CommonWordings.missingInputResponse,
        'invalid-input-response': CommonWordings.missingInputResponse,
        'bad-request': CommonWordings.badRequest,
        'timeout-or-duplicate':	CommonWordings.timeoutOrDuplicate,
        'unknown-error': CommonWordings.badRequest
    }
}

const connector = connect((state: ApplicationState) => state, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly list: IAnonTransferInfo[] | undefined;
    readonly toast: ToastProps;
    readonly captchaKey: number;
}
    
class TransferStatus extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            query: '',
            captcha: ''
        },
        errors: {},
        list: undefined,
        toast: DefaultToastProps,
        captchaKey: 1
    }

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.query = (name === 'query') ? value : d.form.query;

            d.errors.query = (name === 'query') ? undefined : d.errors.query;
        }));
    }

    validateForm = (): boolean => {
        const { query } = this.state.form;
        let result: IResponseError = {
            query: query ? undefined : 'required',
        };
        this.setFormErrors(result);
        return U.isValidForm(result);
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { 
            d.errors = errors;
            if (U.hasFormErrors(errors)) {
                d.captchaKey = d.captchaKey + 1;
            }
        }));
    }

    onCaptchaChange = (newValue: string|null) => {
        this.setState(produce(d => { d.form.captcha = newValue ?? '' }));
    }

    handleSubmit = () => {
        if (!this.validateForm()) {
            return;
        }
        this.setState(produce(d => {
            d.list = undefined;
        }));
        api.AnonTransferStatus(this.state.form)
            .then(response => response.data)
            .then(result => {
                if (result.succeeded) {
                    this.setState(produce(d => {
                        d.list = result.data;
                    }));
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = Wordings.title;
                    d.toast.text = '';
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    public render() {
        const columns: ColumnDescription[] = [{
            dataField: 'transactionId',
            text: Wordings.transactionId
        }, {
            dataField: 'senderPublicKey',
            text: Wordings.senderPublicKey
        }, {
            dataField: 'receiverPublicKey',
            text: Wordings.receiverPublicKey
        }, {
            dataField: 'currency',
            text: Wordings.currency,
            formatter: (col: any, row: IAnonTransferInfo) => 
                CurrencyWordings.getString(row.currency) || row.currency
        }, {
            dataField: 'memo',
            text: Wordings.memo
        }, {
            dataField: 'amount',
            text: Wordings.amount,
            formatter: (col: any, row: IAnonTransferInfo) => 
                <NumberFormat value={row.amount} displayType={'text'} thousandSeparator={true} />
        }, {
            dataField: 'status',
            text: Wordings.status,
            formatter: (col: any, row: IAnonTransferInfo) => 
                TransactionStateWordings.getString(row.status) || row.status
        }];
        const { form, list, errors, toast } = this.state;
        return (
            <>
                <Card>
                    <Card.Body className="row">
                        <Card.Title as="h3">{Wordings.title}</Card.Title>
                        <Form.Group as={Col} xs={12} controlId="publicKey">
                            <Form.Label>{Wordings.query}</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control type="text" 
                                name="query"
                                value={form.query}
                                isInvalid={U.isInvalid(errors.query)}
                                onChange={this.handleChange}/>
                                <InputGroup.Append>
                                    <Button variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                                </InputGroup.Append>
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">{errors.query}</Form.Control.Feedback>
                        </Form.Group>
                    </Card.Body>
                    {list !== undefined &&
                        <BootstrapTable keyField='transactionId' data={ list } columns={ columns } />
                    }
                </Card>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </>
        );
    }
};

export default connector(TransferStatus);
