import { Action, Reducer } from 'redux';
import produce from 'immer';

import { AppThunk } from '.';
import { api, IUserInfo, IUserListForm, IUserListSimpleFilter, IUserListDetailFilter } from '../api';

export interface UserListState {
    isLoading: boolean;
    isError: boolean;
    data: IUserInfo[];

    filter: IUserListSimpleFilter | IUserListDetailFilter;
    simple: boolean;
    sort: string;
    page: number;
    sizePerPage: number;
    totalSize: number;
    requestId: number;
}

interface FetchUsersBeginAction {
    type: 'FETCH_USERS_BEGIN';

    filter: IUserListSimpleFilter | IUserListDetailFilter;
    simple: boolean;
    sort: string;
    page: number;
    sizePerPage: number;
    requestId: number;
}

interface FetchUsersSuccessAction {
    type: 'FETCH_USERS_SUCCESS';
    data: IUserInfo[];

    filter: IUserListSimpleFilter | IUserListDetailFilter;
    simple: boolean;
    sort: string;
    page: number;
    sizePerPage: number;
    totalSize: number;
    requestId: number;
}

interface FetchUsersFailureAction {
    type: 'FETCH_USERS_FAILURE';
    requestId: number;
}

interface UpdateUserAction {
    type: 'UPDATE_USER';
    data: IUserInfo;
}

type KnownAction = FetchUsersBeginAction | FetchUsersSuccessAction | FetchUsersFailureAction
    | UpdateUserAction;

export const actionCreators = {
    fetchUserList: (form: IUserListForm): AppThunk => (dispatch, getState) => {
        const appState = getState();
        const { simple, filter, page, sizePerPage, sort } = form;
        if (appState && appState.userList) {
            const requestId = appState.userList.requestId + 1;
            dispatch({ type: 'FETCH_USERS_BEGIN', simple, filter, page, sizePerPage, sort, requestId });
            const f = {...form, requestId};
            api.UserList(f)
                .then(response => response.data)
                .then((result) => {
                    if (result.succeeded) {
                        const { data, totalSize, filter, page, sizePerPage, sort, requestId } = result;
                        dispatch({ type: 'FETCH_USERS_SUCCESS', simple, filter, page, sizePerPage, sort, data, totalSize, requestId });
                    } else {
                        dispatch({ type: 'FETCH_USERS_FAILURE', requestId });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'FETCH_USERS_FAILURE', requestId });
                });
        }
    },
    reloadUser: (id: number): AppThunk => (dispatch) => {
        api.GetUser(id)
            .then(response => response.data)
            .then((result) => {
                if (result.succeeded) {
                    const { data } = result;
                    dispatch({ type: 'UPDATE_USER', data });
                }
            })
            .catch((error) => {
            });
    },
    updateUser: (data: IUserInfo) => ({ type: 'UPDATE_USER', data } as UpdateUserAction),
};

const unloadedState: UserListState = {
    isLoading: false,
    isError: false,
    data: [], 

    simple: true,
    filter: {},
    sort: "",
    requestId: 1,
    page: 0,
    sizePerPage: 10,
    totalSize: -1
};

export const reducer: Reducer<UserListState> = (state: UserListState | undefined, incomingAction: Action): UserListState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'FETCH_USERS_BEGIN':
            {
                return produce(state, d => {
                    const {simple, filter, page, sizePerPage, sort, requestId} = action;
                    d.isLoading = true;
                    d.isError = false;
                    d.data = [];
                    d.requestId = requestId;

                    d.simple = simple;
                    d.filter = filter;
                    d.page = page;
                    d.sizePerPage = sizePerPage;
                    d.sort = sort;
                });
            }
        case 'FETCH_USERS_SUCCESS':
            if (action.requestId === state.requestId) {
                return produce(state, d => {
                    const {simple, filter, page, sizePerPage, sort, data, totalSize} = action;
                    d.isLoading = false;
                    d.isError = false;
                    d.data = data;

                    d.simple = simple;
                    d.filter = filter;
                    d.page = page;
                    d.sizePerPage = sizePerPage;
                    d.totalSize = totalSize;
                    d.sort = sort;
                });
            }
            break;
        case 'FETCH_USERS_FAILURE':
            if (action.requestId === state.requestId) {
                return produce(state, d => {
                    d.isLoading = false;
                    d.isError = true;
                    d.data = [];
                });
            }
            break;
        case 'UPDATE_USER':
            return produce(state, d => {
                const index = d.data.findIndex(u => u.userid === action.data.userid);
                if (index >= 0) {
                    d.data.splice(index, 1, action.data);
                }
            });
        }

    return state;
};
