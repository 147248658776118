import { Action, Reducer } from 'redux';
import { AppThunk } from '.';
import produce from 'immer';

import { api, IReviewCodeInfo } from '../api';

export interface ReviewCodeDataState {
    isLoading: boolean;
    isError: boolean;
    data: IReviewCodeInfo[];
}

interface FetchReviewCodesBeginAction {
    type: 'FETCH_REVIEWCODES_BEGIN';
}
interface FetchReviewCodesSuccessAction {
    type: 'FETCH_REVIEWCODES_SUCCESS';
    data: IReviewCodeInfo[];
}
interface FetchReviewCodesFailureAction {
    type: 'FETCH_REVIEWCODES_FAILURE';
}
interface AddReviewCodeAction {
    type: 'ADD_REVIEWCODE';
    data: IReviewCodeInfo;
}
interface UpdateReviewCodeAction {
    type: 'UPDATE_REVIEWCODE';
    data: IReviewCodeInfo;
}

type KnownAction = FetchReviewCodesBeginAction | FetchReviewCodesSuccessAction | FetchReviewCodesFailureAction
    | AddReviewCodeAction | UpdateReviewCodeAction;

export const actionCreators = {
    fetchReviewCodeList: (): AppThunk => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.reviewCodeList) {
            dispatch({ type: 'FETCH_REVIEWCODES_BEGIN' });
            api.ReviewCodeList()
                .then((response) => response.data)
                .then((result) => {
                    if (result.succeeded) {
                        const { data } = result;
                        dispatch({ type: 'FETCH_REVIEWCODES_SUCCESS', data });
                    } else {
                        dispatch({ type: 'FETCH_REVIEWCODES_FAILURE' });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'FETCH_REVIEWCODES_FAILURE' });
                });
        }
    },
    addReviewCode: (data: IReviewCodeInfo) => ({ type: 'ADD_REVIEWCODE', data } as AddReviewCodeAction),
    updateReviewCode: (data: IReviewCodeInfo) => ({ type: 'UPDATE_REVIEWCODE', data } as UpdateReviewCodeAction),
};

const unloadedState: ReviewCodeDataState = { 
    data: [], 
    isLoading: false,
    isError: false
};

export const reducer: Reducer<ReviewCodeDataState> = (state: ReviewCodeDataState | undefined, incomingAction: Action): ReviewCodeDataState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'FETCH_REVIEWCODES_BEGIN': {
            return produce(state, d => {
                d.isLoading = true;
                d.isError = false;
                d.data = [];
            });
        }
        case 'FETCH_REVIEWCODES_SUCCESS': {
            return produce(state, d => {
                const { data } = action;
                d.isLoading = false;
                d.isError = false;
                d.data = data;
            });
        }
        case 'FETCH_REVIEWCODES_FAILURE': {
            return produce(state, d => {
                d.isLoading = false;
                d.isError = true;
                d.data = [];
            });
        }
        case 'ADD_REVIEWCODE': {
            return produce(state, d => {
                d.data.splice(0, 0, action.data);
            });
        }
        case 'UPDATE_REVIEWCODE': {
            return produce(state, d => {
                const { data } = action;
                const r = d.data.find(w => w.codeid === data.codeid);
                if (r) {
                    r.description = data.description;
                    r.transferDefault = data.transferDefault;
                    r.active = data.active;
                }
            });
        }
    }

    return state;
};
