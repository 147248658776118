import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/UserData';
import { RejectUserWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, IUserInfo } from '../../api';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const connector = connect((state: ApplicationState) => state.appdata, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux 
& {
    receiver: IUserInfo,
    onCompleted: () => void
}

interface ComponentState {
    readonly toast: ToastProps
}

class RejectUser extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        toast: DefaultToastProps
    };

    handleCancel = () => {
        this.props.onCompleted();
    }

    handleSubmit = () => {
        const { receiver } = this.props;
        const form = { 
            userid: receiver.userid,
            flag: 'approved',
            value: false,
         };
        api.UserSetFlag(form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded)  {
                    this.props.updateUser(result.data as IUserInfo);
                } else {

                }
                this.props.onCompleted();
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.userSetFlag;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    public render() {
        const { toast } = this.state;
        const { receiver } = this.props;
        return (
            <Modal show={true} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{Wordings.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="user">
                        <Form.Label>{Wordings.user}</Form.Label>
                        <Form.Text>{receiver.firstName}{' '}{receiver.lastName}</Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCancel}>{Wordings.cancel}</Button>
                    <Button variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                </Modal.Footer>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </Modal>
        );
    }
};

export default connector(RejectUser);
