import React, { Component } from 'react';
import { Switch, Route } from 'react-router';

import AppRoutes from './AppRoutes';
import AuthorizeRoute from './AuthorizeRoute';

import Small from './components/LayoutSmall';
import Full from './components/LayoutFull';
import Anon from './components/AnonLayout';

import Home from './components/Home';
import UserLogin from './components/Auth/UserLogin';
import UserLogout from './components/Auth/UserLogout';
import UserRegister from './components/Auth/UserRegister';

import DashboardWallet from './components/Dashboard/DashboardWallet';
import DashboardApproval from './components/Dashboard/DashboardApproval';
import DashboardAdmin from './components/Dashboard/DashboardAdmin';
import DashboardTransaction from './components/Dashboard/DashboardTransaction';

import WalletCreate from './components/Wallet/WalletCreate';
import WalletLater from './components/Wallet/WalletLater';
import WalletHelp from './components/Wallet/WalletHelp';
import UserProfile from './components/User/UserProfile';
import WalletLogin from './components/Auth/WalletLogin';

import AnonHome from './components/AnonTransfer/AnonHome';
import AnonTransfer from './components/AnonTransfer/Transfer';
import AnonTransferStatus from './components/AnonTransfer/TransferStatus';
import AnonWalletCreate from './components/AnonTransfer/WalletCreate';
import AnonWalletBalance from './components/AnonTransfer/WalletBalance';


export default class App extends Component {
  static displayName = App.name;
  render () {
    const showBargainBay = window.AppSettings.ShowBargainBay;
    const showAnonTransfer = window.AppSettings.ShowAnonTransfer;
    return (
        <Switch>
            {showBargainBay && <Route exact path={AppRoutes.Home} render={(p) => <Small><Home {...p}/></Small>} />}
            {showBargainBay && <Route exact path={AppRoutes.UserLogin} render={(p) => <Small><UserLogin {...p}/></Small>} />}
            {showBargainBay && <Route exact path={AppRoutes.UserLogout} render={(p) => <Small><UserLogout {...p}/></Small>} />}
            {showBargainBay && <Route exact path={AppRoutes.WalletLogin} render={(p) => <Small><WalletLogin/></Small>} />}
            {showBargainBay && <Route exact path={AppRoutes.UserRegister} render={(p) => <Full><UserRegister {...p}/></Full>} />}

            {showBargainBay && <AuthorizeRoute exact path={AppRoutes.DashboardWallet} render={(p) => <Full><DashboardWallet/></Full>} />}
            {showBargainBay && <AuthorizeRoute exact path={AppRoutes.DashboardApproval} render={(p) => <Full><DashboardApproval/></Full>} />}
            {showBargainBay && <AuthorizeRoute exact path={AppRoutes.DashboardAdmin} render={(p) => <Full><DashboardAdmin/></Full>} />}
            {showBargainBay && <AuthorizeRoute exact path={AppRoutes.DashboardTransaction} render={(p) => <Full><DashboardTransaction/></Full>} />}

            {showBargainBay && <AuthorizeRoute exact path={AppRoutes.WalletCreate} render={(p) => <Full><WalletCreate {...p}/></Full>} />}
            {showBargainBay && <AuthorizeRoute exact path={AppRoutes.WalletLater} render={(p) => <Full><WalletLater {...p}/></Full>} />}
            {showBargainBay && <AuthorizeRoute exact path={AppRoutes.WalletHelp} render={(p) => <Full><WalletHelp/></Full>} />}

            {showBargainBay && <AuthorizeRoute exact path={AppRoutes.UserProfile} render={(p) => <Full><UserProfile/></Full>} />}

            {!showBargainBay && <Route exact path={AppRoutes.Home} render={(p) => <AnonHome {...p}/>} />}
            {showAnonTransfer && <Route exact path={AppRoutes.AnonHome} render={(p) => <AnonHome {...p}/>} />}
            {showAnonTransfer && <Route exact path={AppRoutes.AnonTransfer} render={(p) => <Anon><AnonTransfer {...p}/></Anon>} />}
            {showAnonTransfer && <Route exact path={AppRoutes.AnonTransferStatus} render={(p) => <Anon><AnonTransferStatus {...p}/></Anon>} />}
            {showAnonTransfer && <Route exact path={AppRoutes.AnonWalletCreate} render={(p) => <Anon><AnonWalletCreate {...p}/></Anon>} />}
            {showAnonTransfer && <Route exact path={AppRoutes.AnonWalletBalance} render={(p) => <Anon><AnonWalletBalance {...p}/></Anon>} />}
        </Switch>
    );
  }
}
