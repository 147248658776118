import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import { ApplicationState } from '../../store';
import * as ApplicationDataStore from '../../store/ApplicationData';
import { ChangePasswordWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, IPasswordChangeForm as IForm, IPasswordChangeFormErrors as IFormErrors, IResponseError } from '../../api';
import * as U from '../../utils/BbUtil';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const errorMap = {
    oldPassword: {
        required: Wordings.oldPasswordRequired,
        badold: Wordings.oldPasswordInvalid,
    },
    newPassword: {
        required: Wordings.newPasswordRequired,
        weak: Wordings.passwordWeak,
    },
    repeatPassword: {
        required: Wordings.repeatPasswordRequired,
        no_match: Wordings.passwordMismatch,
    }
}

const connector = connect((state: ApplicationState) => state.appdata, ApplicationDataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux 
& {
    onCompleted: () => void
}

interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly toast: ToastProps;
}

class ChangePassword extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            oldPassword: '',
            newPassword: '',
            repeatPassword: '',
        },
        errors: {},
        toast: DefaultToastProps
    };

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.oldPassword = (name === 'oldPassword') ? value : d.form.oldPassword;
            d.form.newPassword = (name === 'newPassword') ? value : d.form.newPassword;
            d.form.repeatPassword = (name === 'repeatPassword') ? value : d.form.repeatPassword;

            d.errors.oldPassword = undefined;
            d.errors.newPassword = undefined;
            d.errors.repeatPassword = undefined;
        }));
    }

    validateForm = (): boolean => {
        const { newPassword, oldPassword, repeatPassword } = this.state.form;
        let result: IResponseError = {
            oldPassword: oldPassword ? undefined : 'required',
            newPassword: newPassword ? undefined : 'required',
            repeatPassword: repeatPassword ? undefined : 'required',
        };
        this.setFormErrors(result);
        return U.isValidForm(result);
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { d.errors = errors }));
    }

    handleCancel = () => {
        this.props.onCompleted();
    }

    handleSubmit = () => {
        if (!this.validateForm()) {
            return;
        }
        let form: IForm = {
            ...this.state.form
        };
        api.PasswordChange(form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded) {
                    this.props.onCompleted();
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.passwordChange;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    public render() {
        const { form, errors, toast } = this.state;
        return (
            <>
                <Modal show={true} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>{Wordings.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="oldPassword">
                            <Form.Label>{Wordings.oldPassword}</Form.Label>
                            <Form.Control required type="password" 
                                name="oldPassword"
                                value={form.oldPassword}
                                isInvalid={U.isInvalid(errors.oldPassword)}
                                onChange={this.handleChange}/>
                            <Form.Control.Feedback type="invalid">{errors.oldPassword}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="newPassword">
                            <Form.Label>{Wordings.newPassword}</Form.Label>
                            <Form.Control required type="password" 
                                name="newPassword"
                                value={form.newPassword}
                                isInvalid={U.isInvalid(errors.newPassword)}
                                onChange={this.handleChange}/>
                            <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="repeatPassword">
                            <Form.Label>{Wordings.repeatPassword}</Form.Label>
                            <Form.Control required type="password" 
                                name="repeatPassword"
                                value={form.repeatPassword}
                                isInvalid={U.isInvalid(errors.repeatPassword)}
                                onChange={this.handleChange}/>
                            <Form.Control.Feedback type="invalid">{errors.repeatPassword}</Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCancel}>{Wordings.cancel}</Button>
                        <Button variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                    </Modal.Footer>
                </Modal>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </>
        );
    }
};

export default connector(ChangePassword);
