import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import * as ApplicationDataStore from './ApplicationData';
import * as UserDataStore from './UserData';
import * as WalletDataStore from './WalletData';
import * as ReviewCodeDataStore from './ReviewCodeData';
import * as SetupDataStore from './SetupData';
import * as TransactionDataStore from './TransactionData';

// The top-level state object
export interface ApplicationState {
    appdata: ApplicationDataStore.ApplicationDataState;
    setup: SetupDataStore.SetupState;
    userList: UserDataStore.UserListState;
    walletList: WalletDataStore.WalletDataState;
    reviewCodeList: ReviewCodeDataStore.ReviewCodeDataState;
    transactionList: TransactionDataStore.TransactionListState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    appdata: ApplicationDataStore.reducer,
    setup: SetupDataStore.reducer,
    userList: UserDataStore.reducer,
    walletList: WalletDataStore.reducer,
    reviewCodeList: ReviewCodeDataStore.reducer,
    transactionList: TransactionDataStore.reducer,
};

// https://redux.js.org/recipes/usage-with-typescript#usage-with-redux-thunk
// To reduce repetition, you might want to define a reusable AppThunk type once, 
// in your store file, and then use that type whenever you write a thunk:
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, ApplicationState, unknown, Action<string>>