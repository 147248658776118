import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/SetupData';
import UserList from '../User/UserList';
import TransferSettingCard from '../Transaction/TransferSettingCard';
import Mint from '../Transaction/Mint';

const connector = connect((state: ApplicationState) => state.setup, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
}
    
/*
https://fgcorp.atlassian.net/browse/FGC-11
User Admin Screen
This is the main user admin screen that shows all system users.  It will allow those with admin permissions to manage users in the system.
Add
Delete
Edit
Enable
Disable
Password Reset
Password Edit

https://fgcorp.atlassian.net/browse/FGC-19
Auto Transfer Amount
On the User Admin Screen, the admin can select the amount that will automatically transferred to registrant after approval.
*/
class DashboardAdmin extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        const { mockLedger } = this.props.setup;
        return (
            <>
                <Row>
                    <Col xs={12} md={9}>
                        <UserList/>
                    </Col>
                    <Col xs={12} md={3}>
                        <TransferSettingCard/>
                        {mockLedger && <Mint/>}
                    </Col>
                </Row>
            </>
        );
    }
};

export default connector(DashboardAdmin);
