import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, {  } from 'react-bootstrap-table-next';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/ReviewCodeData';
import { ReviewCodeListWordings as Wordings, CommonWordings } from '../../Wordings';
import { IReviewCodeInfo, IReviewCodeForm } from '../../api';
import ReviewCode from './ReviewCode';
import { api } from '../../api';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const connector = connect((state: ApplicationState) => state.reviewCodeList, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
    readonly view: View;
    readonly code: IReviewCodeInfo | undefined;
    readonly toast: ToastProps
}
    
enum View {List, Edit}
class ReviewCodeList extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        view: View.List,
        code: undefined,
        toast: DefaultToastProps
    };

    onRefresh = () => {
        this.props.fetchReviewCodeList();
    }

    onNew = () => {
        api.ReviewCodeCreate()
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded) {
                    this.props.addReviewCode(result.data as IReviewCodeInfo);
                } else {
                    this.setState(produce(d => { 
                        d.toast.heading = CommonWordings.reviewCodeCreate;
                        d.toast.text = '';
                        d.toast.appearance = 'error'; 
                    }));
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.reviewCodeCreate;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    onEdit = (event:React.MouseEvent<HTMLButtonElement & React.MouseEvent>) => {
        const { codeid } = event.currentTarget.dataset;
        if (!codeid) {
            return;
        }
        const id = parseInt(codeid);
        this.setState(produce(d => {
            d.view = View.Edit;
            d.code = this.props.data.find(r => r.codeid === id);
        }));
    }

    onEditCompleted = () => {
        this.setState(produce(d => {
            d.view = View.List;
        }));
    }

    onStatusAction = (event:React.MouseEvent<HTMLButtonElement & React.MouseEvent>) => {
        const { codeid, action } = event.currentTarget.dataset;
        if (!codeid) {
            return;
        }
        const id = parseInt(codeid);
        const form:IReviewCodeForm = { codeid: id, active: action === 'true' };
        api.ReviewCodeEdit(form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded) {
                    this.props.updateReviewCode(result.data as IReviewCodeInfo);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.reviewCodeEdit;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    statusColumn = (col: any, row: IReviewCodeInfo) => {
        const id = row.codeid;

        const status = row.active ? Wordings.active : Wordings.disabled;
        const statusAction = row.active ? Wordings.disable : Wordings.enable;
        return <>
            {status}
            {' '}<Button variant="link" size="sm" data-action={!row.active} data-codeid={id} onClick={this.onStatusAction}>{statusAction}</Button>
            {' '}<Button variant="link" size="sm" data-codeid={id} onClick={this.onEdit}>{Wordings.edit}</Button>
        </>
    }

    public componentDidMount() {
        this.props.fetchReviewCodeList();
    }

    public render() {
        const columns = [{
            dataField: 'reviewCode',
            text: Wordings.reviewCode,
            formatter: (col: any, row: IReviewCodeInfo) => <>
                {row.reviewCode}<br/>{row.description}
            </>
        }, {
            dataField: 'active',
            text: Wordings.status,
            formatter: this.statusColumn
        }];
  
        const { data } = this.props;

        const pf = paginationFactory({
            sizePerPage: 5,
            paginationSize: 3,  // the pagination bar size, default is 5
            showTotal: true, // display pagination information
            sizePerPageList: [ {
              text: '5', value: 5
            }, {
              text: '10', value: 10
            }, {
              text: CommonWordings.all, value: data.length
            } ], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            firstPageText: CommonWordings.firstPageText, // the text of first page button
            prePageText: CommonWordings.prePageText, // the text of previous page button
            nextPageText: CommonWordings.nextPageText, // the text of next page button
            lastPageText: CommonWordings.lastPageText, // the text of last page button
            nextPageTitle: CommonWordings.nextPageTitle, // the title of next page button
            prePageTitle: CommonWordings.prePageTitle, // the title of previous page button
            firstPageTitle: CommonWordings.firstPageTitle, // the title of first page button
            lastPageTitle: CommonWordings.lastPageTitle, // the title of last page button
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
        });

        const { view, code, toast } = this.state;
        return (
            <>
                <Card>
                    <Card.Body className="row">
                        <Card.Title as="h3">{Wordings.title}</Card.Title>
                        <Form inline className="ml-auto">
                            <ButtonGroup size="sm" className="ml-auto">
                                <Button size="sm" variant="outline-dark" onClick={this.onNew}>{Wordings.add}</Button>
                                <Button size="sm" variant="outline-dark" onClick={this.onRefresh}>{Wordings.refresh}</Button>
                            </ButtonGroup>
                        </Form>
                    </Card.Body>
                    <BootstrapTable keyField='codeid' remote data={ data } columns={ columns } pagination={ pf } noDataIndication={() => Wordings.noResultsFound } />
                </Card>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
                {view === View.Edit &&
                    <ReviewCode code={code as IReviewCodeInfo} onCompleted={this.onEditCompleted} />
                }
            </>
        );
    }
};

export default connector(ReviewCodeList);
