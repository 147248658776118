import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/SetupData';
import { DashboardAdminWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, ISetupEditForm as IForm, ISetupErrors as IFormErrors, IResponseError, ISetupInfo } from '../../api';
import * as U from '../../utils/BbUtil';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const errorMap = {
    transferAmountMax: {
        required: Wordings.transferAmountMaxRequired,
        invalid: Wordings.transferAmountMaxInvalid
    },
    transferNeedEtherMin: {
        required: Wordings.transferNeedEtherMinRequired,
        invalid: Wordings.transferNeedEtherMinInvalid
    }
}

const connector = connect((state: ApplicationState) => state.setup, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux
& {
    onCompleted: () => void
}

interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly toast: ToastProps
}

class TransferSettingEdit extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {...this.props.setup},
        errors: {},
        toast: DefaultToastProps
    };

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.transferAmountMax = (name === 'transferAmountMax') ? value : d.form.transferAmountMax;
            d.form.transferNeedEtherMin = (name === 'transferNeedEtherMin') ? value : d.form.transferNeedEtherMin;

            d.errors.transferAmountMax = (name === 'transferAmountMax') ? undefined : d.errors.transferAmountMax;
            d.errors.transferNeedEtherMin = (name === 'transferNeedEtherMin') ? undefined : d.errors.transferNeedEtherMin;
        }));
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { d.errors = errors }));
    }

    handleCancel = () => {
        this.props.onCompleted();
    }

    handleSubmit = () => {
        let form: IForm = {
            ...this.state.form
        };
        api.SetupEdit(form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded) {
                    this.props.updateSetup(result.data as ISetupInfo);
                    this.props.onCompleted();
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.setupEdit;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    public render() {
        const { form, errors, toast } = this.state;
        return (
            <>
                <Modal show={true} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>{Wordings.transferEditTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="transferAmountMax">
                            <Form.Label>{Wordings.transferAmountMax}</Form.Label>
                            <Form.Control required type="number" 
                                name="transferAmountMax"
                                value={form.transferAmountMax}
                                isInvalid={U.isInvalid(errors.transferAmountMax)}
                                onChange={this.handleChange}/>
                            <Form.Control.Feedback type="invalid">{errors.transferAmountMax}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="transferNeedEtherMin">
                            <Form.Label>{Wordings.transferNeedEtherMin}</Form.Label>
                            <Form.Control required type="number" 
                                name="transferNeedEtherMin"
                                value={form.transferNeedEtherMin}
                                isInvalid={U.isInvalid(errors.transferNeedEtherMin)}
                                onChange={this.handleChange}/>
                            <Form.Control.Feedback type="invalid">{errors.transferNeedEtherMin}</Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCancel}>{Wordings.cancel}</Button>
                        <Button variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                    </Modal.Footer>
                    <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
                </Modal>
            </>
        );
    }
};

export default connector(TransferSettingEdit);
