import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { PersonLinesFill, ShieldLock } from 'react-bootstrap-icons';

import { AppWordings as Wordings } from '../Wordings';
import AppRoutes from '../AppRoutes';
import { ApplicationState } from '../store';
import * as DataStore from '../store/ApplicationData';
import ChangePassword from './Auth/ChangePassword';

const connector = connect((state: ApplicationState) => state.appdata, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux

interface ComponentState {
  view: View;
}

enum View {App, Password}
class FullClass extends React.Component<ComponentProps, ComponentState> {
  state: Readonly<ComponentState> = {
    view: View.App
  }

  showApp = () => {
    this.setState(produce(d => {
        d.view = View.App;
    }));
  }
  showPassword = () => {
    this.setState(produce(d => {
        d.view = View.Password;
    }));
  }
  componentDidMount() {
    const isAuth = !!this.props.userInfo.token;
    document.body.style.paddingTop = isAuth ? "4em" : "0em";
    document.body.style.paddingBottom = "4em";
  }
  componentDidUpdate() {
    const isAuth = !!this.props.userInfo.token;
    document.body.style.paddingTop = isAuth ? "4em" : "0em";
    document.body.style.paddingBottom = "4em";
  }

  public render() {
    const { userInfo, userInfo: {firstName, lastName}, appInfo: {build} } = this.props;
    const isAuth = !!userInfo.token;
    const showAppoval = isAuth && userInfo.isReviewer;
    const showAdmin = isAuth && userInfo.isAdmin;
    const showTransaction = isAuth;
    const showWallet = isAuth;
    const name = `${firstName} ${lastName}`;
    const { view } = this.state;
    return (
      <>
        {isAuth &&
          <Navbar bg="light" fixed="top" expand="sm" className="nav nav-pills">
            <Navbar.Brand>
              <Image src="/images/logo_t.png" height="30" className="d-inline-block align-top" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                {showWallet &&
                  <NavLink to={AppRoutes.DashboardWallet} className="nav-link">{Wordings.wallet}</NavLink>}
                {showAppoval &&
                  <NavLink to={AppRoutes.DashboardApproval} className="nav-link">{Wordings.approval}</NavLink>}
                {showAdmin && 
                  <NavLink to={AppRoutes.DashboardAdmin} className="nav-link">{Wordings.admin}</NavLink>}
                {showTransaction && 
                  <NavLink to={AppRoutes.DashboardTransaction} className="nav-link">{Wordings.transaction}</NavLink>}
              </Nav>
              <Nav>
                <Navbar.Text style={{paddingRight: '6px'}}>{name}</Navbar.Text>
                <OverlayTrigger placement="bottom" overlay={
                  <Tooltip id="tooltip-profile">
                    {Wordings.profile}
                  </Tooltip>
                  }>
                  <NavLink to={AppRoutes.UserProfile}>
                    <Button variant="light">{<PersonLinesFill/>}</Button>
                  </NavLink>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={
                  <Tooltip id="tooltip-password">
                    {Wordings.password}
                  </Tooltip>
                  }>
                  <Button variant="light" onClick={this.showPassword}>{<ShieldLock/>}</Button>
                </OverlayTrigger>
                <NavLink to={AppRoutes.WalletHelp}>
                  <Button variant="outline-success">{Wordings.help}</Button>
                </NavLink>
                <NavLink to={AppRoutes.UserLogout} className="nav-link">{Wordings.logout}</NavLink>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        }
        <Row>
          <Col xs={12}>
            {this.props.children}
          </Col>
          {view === View.Password && <ChangePassword onCompleted={this.showApp}/>}
        </Row>
        <Navbar bg="light" fixed="bottom" expand="sm">
          <Navbar.Text className="mr-auto">{Wordings.copyright}</Navbar.Text>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            <Nav>
              <Navbar.Text>{Wordings.build}{' '}{build}</Navbar.Text>
              <Nav.Link target='_blank' href="https://bargainbaystore.com/terms-of-service">{Wordings.terms}</Nav.Link>
              <Nav.Link target='_blank' href="https://bargainbaystore.com/privacy-policy">{Wordings.privacy}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
};

export default connector(FullClass);
