import { Action, Reducer } from 'redux';
import { AppThunk } from '.';
import produce from 'immer';

import { api, IWalletInfo, IWalletBalanceRequest, Currency, IWalletStatusForm, IWalletBalanceResponse } from '../api';

export interface WalletDataState {
    isLoading: boolean;
    isError: boolean;
    data: IWalletInfo[];
}

interface FetchWalletsBeginAction {
    type: 'FETCH_WALLETS_BEGIN';
}
interface FetchWalletsSuccessAction {
    type: 'FETCH_WALLETS_SUCCESS';
    data: IWalletInfo[];
}
interface FetchWalletsFailureAction {
    type: 'FETCH_WALLETS_FAILURE';
}

interface FetchBalanceBeginAction {
    type: 'FETCH_BALANCE_BEGIN';
    publicKey: string;
    currency: Currency;
}
interface FetchBalanceSuccessAction {
    type: 'FETCH_BALANCE_SUCCESS';
    data: IWalletBalanceResponse;
}
interface FetchBalanceFailureAction {
    type: 'FETCH_BALANCE_FAILURE';
    publicKey: string;
    currency: Currency;
}
interface FetchWalletBeginAction {
    type: 'FETCH_WALLET_BEGIN';
    walletid: number;
}
interface FetchWalletSuccessAction {
    type: 'FETCH_WALLET_SUCCESS';
    data: IWalletInfo;
}
interface FetchWalletFailureAction {
    type: 'FETCH_WALLET_FAILURE';
    walletid: number;
}
interface DeleteWalletBeingAction {
    type: 'DELETE_WALLET_BEGIN';
    walletid: number;
}
interface DeleteWalletSuccessAction {
    type: 'DELETE_WALLET_SUCCESS';
    data: IWalletInfo;
}
interface DeleteWalletFailureAction {
    type: 'DELETE_WALLET_FAILURE';
    walletid: number;
}

type KnownAction = FetchWalletsBeginAction | FetchWalletsSuccessAction | FetchWalletsFailureAction
    | FetchBalanceBeginAction | FetchBalanceSuccessAction | FetchBalanceFailureAction
    | FetchWalletBeginAction | FetchWalletSuccessAction | FetchWalletFailureAction
    | DeleteWalletBeingAction | DeleteWalletSuccessAction | DeleteWalletFailureAction;

export const actionCreators = {
    fetchWalletList: (): AppThunk => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.walletList) {
            dispatch({ type: 'FETCH_WALLETS_BEGIN' });
            api.WalletList({})
                .then((response) => response.data)
                .then((result) => {
                    if (result.succeeded) {
                        const { data } = result;
                        dispatch({ type: 'FETCH_WALLETS_SUCCESS', data });
                    } else {
                        dispatch({ type: 'FETCH_WALLETS_FAILURE' });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'FETCH_WALLETS_FAILURE' });
                });
        }
    },

    fetchWalletBalance: (form: IWalletBalanceRequest): AppThunk => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.walletList) {
            const { publicKey, currency } = form;
            dispatch({ type: 'FETCH_BALANCE_BEGIN', publicKey, currency });
            api.WalletBalance(form)
                .then((response) => response.data)
                .then((result) => {
                    if (result.succeeded) {
                        const { data } = result;
                        dispatch({ type: 'FETCH_BALANCE_SUCCESS', data });
                    } else {
                        dispatch({ type: 'FETCH_BALANCE_FAILURE', publicKey, currency });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'FETCH_BALANCE_FAILURE', publicKey, currency });
                });
        }
    },
    deleteWallet: (walletid: number): AppThunk => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.walletList) {
            const form: IWalletStatusForm = {walletid, value: false};
            dispatch({ type: 'DELETE_WALLET_BEGIN', walletid });
            api.WalletStatus(form)
                .then((response) => response.data)
                .then((result) => {
                    if (result.succeeded) {
                        const { data } = result;
                        dispatch({ type: 'DELETE_WALLET_SUCCESS', data });
                    } else {
                        dispatch({ type: 'DELETE_WALLET_FAILURE', walletid });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'DELETE_WALLET_FAILURE', walletid });
                });
        }
    },
    fetchWallet: (walletid: number): AppThunk => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.walletList) {
            dispatch({ type: 'FETCH_WALLET_BEGIN', walletid });
            api.GetWallet(walletid)
                .then((response) => response.data)
                .then((result) => {
                    if (result.succeeded) {
                        const { data } = result;
                        dispatch({ type: 'FETCH_WALLET_SUCCESS', data });
                    } else {
                        dispatch({ type: 'FETCH_WALLET_FAILURE', walletid });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'FETCH_WALLET_FAILURE', walletid });
                });
        }
    }
};

const unloadedState: WalletDataState = { 
    data: [], 
    isLoading: false,
    isError: false
};

const copyWallet = (d:IWalletInfo, w:IWalletInfo) => {
    d.nickname = w.nickname;
    d.active = w.active;
    d.createdAt = w.createdAt;
    
    d.balanceAt = w.balanceAt;
    d.mhlkBalance = w.mhlkBalance;
    d.ethBalance = w.ethBalance;
    d.isMhlkZero = w.isMhlkZero;
    d.isEthZero = w.isEthZero;
}
const setWalletBalances = (d:IWalletInfo, br:IWalletBalanceResponse) => {
    d.balanceAt = br.balanceAt;

    const mhlkBalance = br.balances.find(b => b.currency === 'mhlk');
    const ethBalance = br.balances.find(b => b.currency === 'eth');

    d.mhlkBalance = mhlkBalance?.amount || '0.00';
    d.ethBalance = ethBalance?.amount || '0.00';
    d.isMhlkZero = mhlkBalance?.isZero || true;
    d.isEthZero = ethBalance?.isZero || true;
}
export const reducer: Reducer<WalletDataState> = (state: WalletDataState | undefined, incomingAction: Action): WalletDataState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'FETCH_WALLETS_BEGIN': {
            return produce(state, d => {
                d.isLoading = true;
                d.isError = false;
                d.data = [];
            });
        }
        case 'FETCH_WALLETS_SUCCESS': {
            return produce(state, d => {
                const { data } = action;
                d.isLoading = false;
                d.isError = false;
                d.data = data;
            });
        }
        case 'FETCH_WALLETS_FAILURE': {
            return produce(state, d => {
                d.isLoading = false;
                d.isError = true;
                d.data = [];
            });
        }
        case 'FETCH_BALANCE_BEGIN': {
            return produce(state, d => {
                const w = d.data.find(w => w.publicKey === action.publicKey);
                if (w) {
                    w.isUpdating = true;
                }
            });
        }
        case 'FETCH_BALANCE_SUCCESS': {
            return produce(state, d => {
                const { data } = action;
                const w = d.data.find(w => w.publicKey === data.publicKey);
                if (w) {
                    setWalletBalances(w, data);
                    w.isUpdating = false;
                }
            });
        }
        case 'FETCH_BALANCE_FAILURE': {
            return produce(state, d => {
                const w = d.data.find(w => w.publicKey === action.publicKey);
                if (w) {
                    w.isUpdating = false;
                }
            });
        }
        case 'DELETE_WALLET_BEGIN': {
            return produce(state, d => {
                const w = d.data.find(w => w.walletid === action.walletid);
                if (w) {
                    w.isUpdating = true;
                }
            });
        }
        case 'DELETE_WALLET_SUCCESS': {
            return produce(state, d => {
                const index = d.data.findIndex(w => w.walletid === action.data.walletid);
                if (index >= 0) {
                    d.data.splice(index, 1);
                }
            });
        }
        case 'DELETE_WALLET_FAILURE': {
            return produce(state, d => {
                const w = d.data.find(w => w.walletid === action.walletid);
                if (w) {
                    w.isUpdating = false;
                }
            });
        }
        case 'FETCH_WALLET_BEGIN': {
            break;
        }
        case 'FETCH_WALLET_SUCCESS': {
            return produce(state, d => {
                const { data } = action;
                const w = d.data.find(w => w.walletid === data.walletid);
                if (w) {
                    copyWallet(w, data);
                }
            });
        }
        case 'FETCH_WALLET_FAILURE': {
            break;
        }
    }

    return state;
};
