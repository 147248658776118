import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import { ApplicationState } from '../../store';
import { LinkContainer } from 'react-router-bootstrap';

import { AppWordings as Wordings } from '../../Wordings';
import AppRoutes from '../../AppRoutes';

const connector = connect((state: ApplicationState) => state.appdata);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
}
    
class Home extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        const { appInfo: {build} } = this.props;
        return (
            <>
            <Row>
                <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4 }}>
                  <Image src="/images/anon_logo.jpg" fluid />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4 }}>
                    <LinkContainer to={AppRoutes.AnonWalletCreate}>
                        <Button variant="primary" block>{Wordings.anonWallet}</Button>
                    </LinkContainer>
                    <LinkContainer to={AppRoutes.AnonWalletBalance}>
                        <Button variant="primary" block>{Wordings.anonBalance}</Button>
                    </LinkContainer>
                    <LinkContainer to={AppRoutes.AnonTransfer}>
                        <Button variant="primary" block>{Wordings.anonTransfer}</Button>
                    </LinkContainer>
                    <LinkContainer to={AppRoutes.AnonTransferStatus}>
                        <Button variant="primary" block>{Wordings.anonStatus}</Button>
                    </LinkContainer>
                </Col>
              </Row>
              <Navbar bg="light" fixed="bottom" expand="sm">
                <Navbar.Text className="mr-auto">{Wordings.anonCopyright}</Navbar.Text>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                  </Nav>
                  <Nav>
                    <Navbar.Text>{Wordings.build}{' '}{build}</Navbar.Text>
                    <Nav.Link target='_blank' href="https://www.mhlk-irm.com/terms_of_use">{Wordings.terms}</Nav.Link>
                    <Nav.Link target='_blank' href="https://www.mhlk-irm.com/privacy-policy">{Wordings.privacy}</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </>
        );
    }
};

export default connector(Home);
