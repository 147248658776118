import React from 'react';
import { withToastManager } from 'react-toast-notifications';

type ComponentProps = {
    heading: string;
    text: string | ( string | JSX.Element)[];
    appearance: string;
}

interface ComponentState {}

class Toaster extends React.Component<ComponentProps, ComponentState> {
  componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState) {
    if (this.props.heading === prevProps.heading &&
      this.props.text === prevProps.text &&
      this.props.appearance === prevProps.appearance) {
        return;
    }
    if (!(this.props.heading || this.props.text)) {
        return;
    }
    const { toastManager } = this.props as any;

    const content = (<div>
        <strong>{this.props.heading}</strong>
        <div>
            {this.props.text}
        </div>
      </div>);

    toastManager.add(content, { appearance: this.props.appearance });
  }

  render() {
    return <></>;
  }
}

export default withToastManager(Toaster);

export interface ToastProps {
  heading: string | undefined;
  text: string | undefined;
  appearance: string | undefined;
}

export const DefaultToastProps:ToastProps = {
  heading: undefined,
  text: undefined,
  appearance: undefined
}

export type ToastFn = (heading: string, text: string, appearance: string) => void;