import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { ApplicationState } from '../store';

import { AppWordings as Wordings } from '../Wordings';

const connector = connect((state: ApplicationState) => state.appdata);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

const Small = (props: any) => {
  const { appInfo: {build} } = props;
  return (
      <>
      <Row>
          <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4 }}>
            <Image src="/images/logo_sm.png" fluid />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4 }}>
            {props.children}
          </Col>
        </Row>
        <Navbar bg="light" fixed="bottom" expand="sm">
          <Navbar.Text className="mr-auto">{Wordings.copyright}</Navbar.Text>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            <Nav>
              <Navbar.Text>{Wordings.build}{' '}{build}</Navbar.Text>
              <Nav.Link target='_blank' href="https://bargainbaystore.com/terms-of-service">{Wordings.terms}</Nav.Link>
              <Nav.Link target='_blank' href="https://bargainbaystore.com/privacy-policy">{Wordings.privacy}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        </>
    );
};

export default connector(Small);
