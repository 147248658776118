import { Action, Reducer } from 'redux';
import produce from 'immer';

import { AppThunk } from '.';
import { api } from '../api';
import { ISetupInfo } from '../interfaces/SetupInterface';

export interface SetupState {
    isLoading: boolean;
    isError: boolean;
    setup: ISetupInfo;
}

interface FetchSetupBeginAction {
    type: 'FETCH_SETUP_BEGIN';
}

interface FetchSetupSuccessAction {
    type: 'FETCH_SETUP_SUCCESS';
    data: ISetupInfo;
}

interface FetchSetupFailureAction {
    type: 'FETCH_SETUP_FAILURE';
}

interface UpdateSetupAction {
    type: 'UPDATE_SETUP';
    data: ISetupInfo;
}

type KnownAction = FetchSetupBeginAction | FetchSetupSuccessAction | FetchSetupFailureAction
    | UpdateSetupAction;

export const actionCreators = {
    fetchSetup: (): AppThunk => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.setup) {
            dispatch({ type: 'FETCH_SETUP_BEGIN' });
            api.Setup()
                .then(response => response.data)
                .then((result) => {
                    if (result.succeeded) {
                        const { data } = result;
                        dispatch({ type: 'FETCH_SETUP_SUCCESS', data });
                    } else {
                        dispatch({ type: 'FETCH_SETUP_FAILURE' });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'FETCH_SETUP_FAILURE' });
                });
        }
    },
    updateSetup: (data: ISetupInfo) => ({ type: 'UPDATE_SETUP', data } as UpdateSetupAction),
};

const unloadedState: SetupState = {
    isLoading: false,
    isError: false,
    setup: {
        transferAmountMax: "0",
        transferNeedEtherMin: "0",
        mockLedger: false
    }
};

export const reducer: Reducer<SetupState> = (state: SetupState | undefined, incomingAction: Action): SetupState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'FETCH_SETUP_BEGIN':
            return produce(state, d => {
                d.isLoading = true;
                d.isError = false;
            });
        case 'FETCH_SETUP_SUCCESS':
            return produce(state, d => {
                d.isLoading = false;
                d.isError = false;
                d.setup = action.data;
            });
        case 'FETCH_SETUP_FAILURE':
            return produce(state, d => {
                d.isLoading = false;
                d.isError = true;
            });
        case 'UPDATE_SETUP':
            return produce(state, d => {
                d.isLoading = false;
                d.isError = false;
                d.setup = action.data;
            });
        }

    return state;
};
