import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Select, {ValueType, OptionTypeBase, ActionMeta} from 'react-select';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/UserData';
import { TransferWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, ITransferRequest as IForm, ITransferRequestErrors as IFormErrors, IResponseError, IWalletInfo, IUserInfo } from '../../api';
import * as U from '../../utils/BbUtil';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const errorMap = {
    amount: {
        required: Wordings.amountRequired,
        over_limit: Wordings.amountOverlimit,
        invalid: Wordings.amountInvalid
    },
    senderPrivateKey: {
        required: Wordings.senderPrivateKeyRequired,
        invalid: Wordings.senderPrivateKeyInvalid,
        not_your_wallet: Wordings.senderNotOwnPrivateKey,
        no_min_eth: Wordings.senderNoMinEth,
        cannot_estimate_gas: Wordings.senderNoMinEth
    },
    receiverPublicKey: {
        required: Wordings.receiverPublicKeyRequired
    }
}

const connector = connect((state: ApplicationState) => state, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux 
& {
    receiver: IUserInfo;
    approval: boolean;
    onCompleted: () => void;
};
interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly receiverWallets: IWalletInfo[];
    readonly isRWLoading: boolean;
    readonly isRWLoadingError: boolean;
    readonly toast: ToastProps;
}

class Transfer extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            currency: 'mhlk',
            amount: "0",
            senderPrivateKey: '',
            receiverPublicKey: '',
            approval: this.props.approval
        },
        errors: {},
        receiverWallets: [],
        isRWLoading: false,
        isRWLoadingError: false,
        toast: DefaultToastProps
    };

    handleSelectChange = (selectedOption: ValueType<OptionTypeBase>, actionMeta: ActionMeta<OptionTypeBase>) => {
        const value = (selectedOption as OptionTypeBase).value;
        const name = actionMeta.name;
        this.setState(produce(d => {
            d.form.receiverPublicKey = (name === 'receiverPublicKey') ? value : d.form.receiverPublicKey;

            d.errors.receiverPublicKey = (name === 'receiverPublicKey') ? undefined : d.errors.receiverPublicKey;
        }));
    }

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.amount = (name === 'amount') ? value : d.form.amount;
            d.form.senderPrivateKey = (name === 'senderPrivateKey') ? value : d.form.senderPrivateKey;

            d.errors.amount = (name === 'amount') ? undefined : d.errors.amount;
            d.errors.senderPrivateKey = (name === 'senderPrivateKey') ? undefined : d.errors.senderPrivateKey;
        }));
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { d.errors = errors }));
    }

    validateForm = (): boolean => {
        const { amount, senderPrivateKey, receiverPublicKey } = this.state.form;
        let result: IResponseError = {
            amount: amount ? undefined : 'required',
            senderPrivateKey: senderPrivateKey ? undefined : 'required',
            receiverPublicKey: receiverPublicKey ? undefined : 'required'
        }
        this.setFormErrors(result);
        return U.isValidForm(result);
    }

    handleCancel = () => {
        this.props.onCompleted();
    }

    loadDefaultAmount = () => {
        this.setState(produce(d => {
            d.form.amount = '0';
        }));
        api.Setup()
            .then(response => response.data)
            .then((result) => {
                if (result.succeeded) {
                    const { data } = result;
                    this.setState(produce(d => {
                        d.form.amount = data?.transferAmountMax || '0';
                    }));
                }
            })
            .catch((error) => {
            });
    }

    loadWallets = () => {
        const { receiver } = this.props;

        this.setState(produce(d => {
            d.receiverWallets = [];
            d.isRWLoading = true;
            d.isRWLoadingError = false;
        }));
        api.WalletList({ userid: receiver.userid })
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded)  {
                    this.setState(produce(d => {
                        d.receiverWallets = result.data;
                        d.isRWLoading = false;
                        d.isRWLoadingError = false;
                        d.form.receiverPublicKey = result.data.length > 0 ? result.data[0].publicKey : '';
                    }));
                } else {
                    this.setState(produce(d => {
                        d.isRWLoading = false;
                        d.isRWLoadingError = true;
                    }));
                }
            })
            .catch(() => {
                this.setState(produce(d => {
                    d.isRWLoading = false;
                    d.isRWLoadingError = true;
                }));
            });
    }

    handleSubmit = () => {
        const form = { ...this.state.form };
        api.Transfer(form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded) {
                    this.props.onCompleted();
                    const {receiver } = this.props;
                    this.props.reloadUser(receiver.userid);
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.transfer;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    componentDidMount() {
        this.loadWallets();
        this.loadDefaultAmount();
    }

    componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState) {
        if (this.props.receiver.userid !== prevProps.receiver.userid) {
            this.loadWallets();
            this.loadDefaultAmount();
        }
    }

    public render() {
        const { form, errors, receiverWallets, isRWLoading, isRWLoadingError, toast } = this.state;
        const { receiver, approval } = this.props;
        const receiverName = `${receiver.firstName} ${receiver.lastName}`;

        const rwp = isRWLoadingError ? Wordings.loadingError : isRWLoading ? Wordings.loading : Wordings.nowallet;
        const receiverWalletList = receiverWallets.map((w) => ({value: w.publicKey, label: `${w.publicKey}`}));
        const disabled = receiverWallets.length === 0;
        return (
            <>
                <Modal show={true} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>{Wordings.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="receiver">
                            <Form.Label>{Wordings.receiver}</Form.Label>
                            <Form.Control readOnly type="text" value={receiverName} />
                        </Form.Group>
                        <Form.Group controlId="receiverPublicKey">
                            <Form.Label>{Wordings.receiverPublicKey}</Form.Label>
                            {receiverWallets.length === 0 &&
                                <Form.Control readOnly type="text" value={rwp}/>
                            }
                            {receiverWallets.length > 0 &&
                                <Select name="receiverPublicKey" 
                                options={receiverWalletList}
                                value={receiverWalletList.find(o => o.value === form.receiverPublicKey)}
                                onChange={this.handleSelectChange}
                                ></Select>
                            }
                        </Form.Group>
                        <Form.Group controlId="senderPrivateKey">
                            <Form.Label>{Wordings.senderPrivateKey}</Form.Label>
                            <Form.Control required type="password" 
                                name="senderPrivateKey"
                                value={form.senderPrivateKey}
                                isInvalid={U.isInvalid(errors.senderPrivateKey)}
                                onChange={this.handleChange}/>
                            <Form.Control.Feedback type="invalid">{errors.senderPrivateKey}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="amount">
                            <Form.Label>{Wordings.amount}</Form.Label>
                            <Form.Control required type="number" 
                                readOnly={true}
                                name="amount"
                                value={form.amount}
                                isInvalid={U.isInvalid(errors.amount)}
                                onChange={this.handleChange}/>
                            <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCancel}>{Wordings.cancel}</Button>
                        <Button variant="primary" disabled={disabled} onClick={this.handleSubmit}>{Wordings.submit}</Button>
                    </Modal.Footer>
                    <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
                </Modal>
            </>
        );
    }
};

export default connector(Transfer);
