import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import produce from 'immer';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'

import { ApplicationState } from '../../store';
import * as ApplicationDataStore from '../../store/ApplicationData';
import * as WalletListDataStore from '../../store/WalletData';
import { WalletCreateWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, IWalletCreateForm as IForm } from '../../api';
import * as U from '../../utils/BbUtil';
import AppRoutes from '../../AppRoutes';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';
import WalletCreatePart from './WalletCreatePart';

const connector = connect((state: ApplicationState) => state, {...ApplicationDataStore.actionCreators, ...WalletListDataStore.actionCreators});
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux 
& RouteComponentProps<{}>

interface ComponentState {
    readonly status: Status;
    readonly publicKey: string;
    readonly privateKey: string;
    readonly copiedPublicKey: boolean;
    readonly copiedPrivateKey: boolean;
    readonly toast: ToastProps
}
    
enum Status { Initial, Generating, Generated, NeedAck, Error }

/*
https://fgcorp.atlassian.net/browse/FGC-17
Wallet and PK Screen
This is the screen that is used to display the wallet address and private key to the user have it is created.
Message for them to contact their national coordinator to receive their coins.
The user is told to copy the information.
They have a button to proceed to their dashboard.
*/
/*
https://fgcorp.atlassian.net/browse/FGC-8
Confirmation for saving wallet information
This pop-up will tell the user to save the information in a USB drive and ask them to answer yes or no.
No - it will return to the wallet info page that displays the information for them to copy
Yes, it will allow the user to proceed to the next stage, which is the dashboard.
*/
class WalletCreate extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        status: Status.Initial,
        publicKey: '',
        privateKey: '',
        copiedPublicKey: false,
        copiedPrivateKey: false,
        toast: DefaultToastProps
    };

    toast = (heading: string, text: string, appearance: string) => {
        this.setState(produce(d => { 
            d.toast.heading = heading;
            d.toast.text = text;
            d.toast.appearance = appearance; 
        }));
    }

    onKeyGenerated = (publicKey: string, privateKey: string) => {
        this.setState(produce(d => {
            d.publicKey = publicKey;
            d.privateKey = privateKey;
            d.status = Status.Generated;
        }));
    }

    handleProceed = () => {
        const { status } = this.state;
        if (status === Status.Generated) {
            this.setState(produce(d => { d.status = Status.NeedAck; }));
        }
    }

    saveAcked = () => {
        let form: IForm = {
            nickname: this.state.publicKey,
            publicKey: this.state.publicKey
        };
        api.WalletCreate(form)
            .then(response => response.data)
            .then((result) => {
                if (result.succeeded) {
                    this.props.fetchWalletList();
                    U.gotoPage(this.props, AppRoutes.DashboardWallet);
                } else {
                    this.setState(produce(d => {
                        d.toast.heading = CommonWordings.walletCreate;
                        d.toast.text = Wordings.errorSaving;
                        d.toast.appearance = 'error'; 
                    }));
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.walletCreate;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    allowSave = () => {
        this.setState(produce(d => {
            d.status = Status.Generated;
        }));
    }
    onPrivateCopy = () => {
        this.setState(produce(d => {
            d.toast.heading = "";
            d.toast.appearance = 'success';
            d.toast.text = Wordings.privateKeyCopied;
        }));
    }
    onPublicCopy = () => {
        this.setState(produce(d => {
            d.toast.heading = "";
            d.toast.appearance = 'success';
            d.toast.text = Wordings.publicKeyCopied;
        }));
    }

    public render() {
        const { status, publicKey, privateKey, toast } = this.state;
        const needack = status === Status.NeedAck;
        return (
            <>
                <Card>
                    <Card.Header as="h3">{Wordings.title}</Card.Header>
                    <Card.Body>
                        <WalletCreatePart publicKey={publicKey} privateKey={privateKey} toast={this.toast} onKeyGenerated={this.onKeyGenerated} />
                        <Button block variant="primary" onClick={this.handleProceed}>{Wordings.proceed}</Button>
                    </Card.Body>
                </Card>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
                {needack &&
                    <Modal show={true} backdrop="static" keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>{Wordings.confirmTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{Wordings.confirmDisclaimer}</p>
                            <p>{Wordings.confirmWarning}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="mr-auto" onClick={this.saveAcked}>{Wordings.confirmYes}</Button>
                            <Button variant="warning" onClick={this.allowSave}>{Wordings.confirmNo}</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </>
        );
    }
};

export default connector(WalletCreate);
