import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import NumberFormat from 'react-number-format';
import ReCAPTCHA from "react-google-recaptcha";

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/UserData';
import { AnonTransferWordings as Wordings, CommonWordings, CurrencyOptions, CurrencyWordings, AnonTransferStatusWordings as StatusWordings, TransactionStateWordings } from '../../Wordings';
import { api, IAnonTransferRequest as IForm, IAnonTransferRequestErrors as IFormErrors, IResponseError, Currency, IAnonTransferInfo } from '../../api';
import * as U from '../../utils/BbUtil';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const errorMap = {
    amount: {
        required: Wordings.amountRequired,
        over_limit: Wordings.amountOverlimit,
        invalid: Wordings.amountInvalid
    },
    senderPrivateKey: {
        required: Wordings.senderPrivateKeyRequired,
        invalid: Wordings.senderPrivateKeyInvalid,
        no_min_eth: Wordings.senderNoMinEth,
        cannot_estimate_gas: Wordings.senderNoMinEth
    },
    receiverPublicKey: {
        required: Wordings.receiverPublicKeyRequired
    },
    captcha: {
        'missing-input-secret':	CommonWordings.missingInputSecret,
        'invalid-input-secret':	CommonWordings.invalidInputSecret,
        'missing-input-response': CommonWordings.missingInputResponse,
        'invalid-input-response': CommonWordings.missingInputResponse,
        'bad-request': CommonWordings.badRequest,
        'timeout-or-duplicate':	CommonWordings.timeoutOrDuplicate,
        'unknown-error': CommonWordings.badRequest
    }
}

const connector = connect((state: ApplicationState) => state, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;
interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly transferInfo: IAnonTransferInfo | undefined;
    readonly toast: ToastProps;
    readonly captchaKey: number;
}

class Transfer extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            currency: 'mhlk',
            amount: "0",
            senderPrivateKey: '',
            receiverPublicKey: '',
            memo: '',
            captcha: ''
        },
        errors: {},
        transferInfo: undefined,
        toast: DefaultToastProps,
        captchaKey: 1
    };

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.senderPrivateKey = (name === 'senderPrivateKey') ? value : d.form.senderPrivateKey;
            d.form.receiverPublicKey = (name === 'receiverPublicKey') ? value : d.form.receiverPublicKey;
            d.form.amount = (name === 'amount') ? value : d.form.amount;
            d.form.memo = (name === 'memo') ? value : d.form.memo;
            d.form.currency = (name === 'currency') ? value as Currency : d.form.currency;

            d.errors.senderPrivateKey = (name === 'senderPrivateKey') ? undefined : d.errors.senderPrivateKey;
            d.errors.receiverPublicKey = (name === 'receiverPublicKey') ? undefined : d.errors.receiverPublicKey;
            d.errors.amount = (name === 'amount') ? undefined : d.errors.amount;
            d.errors.memo = (name === 'memo') ? undefined : d.errors.memo;
            d.errors.currency = (name === 'currency') ? undefined : d.errors.currency;
        }));
    }
    onCaptchaChange = (newValue: string|null) => {
        this.setState(produce(d => { d.form.captcha = newValue ?? '' }));
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { d.errors = errors }));
    }

    validateForm = (): boolean => {
        const { amount, senderPrivateKey, receiverPublicKey } = this.state.form;
        let result: IResponseError = {
            amount: amount ? undefined : 'required',
            senderPrivateKey: senderPrivateKey ? undefined : 'required',
            receiverPublicKey: receiverPublicKey ? undefined : 'required'
        }
        this.setFormErrors(result);
        return U.isValidForm(result);
    }

    handleSubmit = () => {
        const form = { ...this.state.form };
        api.AnonTransfer(form)
            .then((transferInfo) => transferInfo.data)
            .then((result) => {
                if (result.succeeded) {
                    this.setState(produce(d => { d.transferInfo = result.data; }));
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = Wordings.title;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    handleAnother = () => {
        this.setState(produce(d => {
            d.form.senderPrivateKey = '';
            d.form.receiverPublicKey = '';
            d.form.amount = '0';
            d.form.memo = '';
            d.transferInfo = undefined; 
        }));
    }

    public render() {
        const currencies = CurrencyOptions.map(o => ({
            value: o.value, 
            label: CurrencyWordings.getString(o.value),
            group: o.group
        })).sort((a, b) => (a.group - b.group) || a.label.localeCompare(b.label));
        const { form, errors, transferInfo, toast, captchaKey } = this.state;
        const { CaptchaSiteKey } = this.props.appdata.appInfo;

        return (
            <>
                <Card>
                    <Card.Header as="h3">{Wordings.title}</Card.Header>
                    <Card.Body>
                    {transferInfo === undefined &&
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} controlId="senderPrivateKey">
                                    <Form.Label>{Wordings.senderPrivateKey}</Form.Label>
                                    <Form.Control required type="password" 
                                        name="senderPrivateKey"
                                        value={form.senderPrivateKey}
                                        isInvalid={U.isInvalid(errors.senderPrivateKey)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.senderPrivateKey}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} controlId="receiverPublicKey">
                                    <Form.Label>{Wordings.receiverPublicKey}</Form.Label>
                                    <Form.Control required type="text" 
                                        name="receiverPublicKey"
                                        value={form.receiverPublicKey}
                                        isInvalid={U.isInvalid(errors.receiverPublicKey)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.receiverPublicKey}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={4} controlId="currency">
                                    <Form.Label>{Wordings.currency}</Form.Label>
                                    <Form.Control as="select" 
                                        name="currency"
                                        value={form.currency}
                                        isInvalid={U.isInvalid(errors.currency)}
                                        onChange={this.handleChange}>
                                        {currencies.map(o => 
                                            <option key={o.value} value={o.value}>{o.label}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">{errors.currency}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={8} controlId="amount">
                                    <Form.Label>{Wordings.amount}</Form.Label>
                                    <Form.Control required type="number" 
                                            name="amount"
                                            value={form.amount}
                                            isInvalid={U.isInvalid(errors.amount)}
                                            onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} controlId="memo">
                                    <Form.Label>{Wordings.memo}</Form.Label>
                                    <Form.Control type="text" 
                                        name="memo"
                                        value={form.memo}
                                        isInvalid={U.isInvalid(errors.memo)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.memo}</Form.Control.Feedback>
                                </Form.Group>
                                </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={6} md={4} controlId="captcha">
                                    <ReCAPTCHA key={captchaKey} sitekey={CaptchaSiteKey ?? ''}
                                        onChange={this.onCaptchaChange} />
                                    <Form.Control.Feedback type="invalid" style={{display: U.isInvalid(errors.captcha) ? 'block' : 'none' }}>{errors.captcha}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Button variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                            </Form.Row>
                        </Form>
                    }
                    {transferInfo !== undefined &&
                        <>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Form.Label>{StatusWordings.transactionId}</Form.Label>{': '}
                                    {transferInfo.transactionId}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Form.Label>{StatusWordings.senderPublicKey}</Form.Label>{': '}
                                    {transferInfo.senderPublicKey}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Form.Label>{StatusWordings.receiverPublicKey}</Form.Label>{': '}
                                    {transferInfo.receiverPublicKey}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Form.Label>{StatusWordings.amount}</Form.Label>{': '}
                                    {CurrencyWordings.getString(transferInfo.currency) || transferInfo.currency}{' '}
                                    <NumberFormat value={transferInfo.amount} displayType={'text'} thousandSeparator={true}/>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Form.Label>{StatusWordings.memo}</Form.Label>{': '}
                                    {transferInfo.memo}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Form.Label>{StatusWordings.status}</Form.Label>{': '}
                                    {TransactionStateWordings.getString(transferInfo.status) || transferInfo.status}
                                </ListGroup.Item>
                            </ListGroup>
                            <Form.Row>
                                <Button variant="primary" onClick={this.handleAnother}>{Wordings.another}</Button>
                            </Form.Row>
                        </>
                    }
                    </Card.Body>
                </Card>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </>
        );
    }
};

export default connector(Transfer);
