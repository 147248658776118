import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/UserData';
import { WalletTransferWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, IWalletCreateForm as IForm, IWalletInfo, IUserInfo } from '../../api';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';
import WalletCreatePart from '../Wallet/WalletCreatePart';
import Transfer from './Transfer';


const connector = connect((state: ApplicationState) => state, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux 
& {
    receiver: IUserInfo;
    approval: boolean;
    onCompleted: () => void;
};
interface ComponentState {
    readonly step: Step;
    readonly receiverWallets: IWalletInfo[];
    readonly isRWLoading: boolean;
    readonly isRWLoadingError: boolean;
    readonly toast: ToastProps;
    readonly publicKey: string;
    readonly privateKey: string;
}

enum Step { Loading, Error, NeedWallet, ShowKeys, ConfirmSave, Transfer }
class WalletTransfer extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        step: Step.Loading,
        receiverWallets: [],
        isRWLoading: false,
        isRWLoadingError: false,
        toast: DefaultToastProps,
        publicKey: '',
        privateKey: '',
    };

    handleCancel = () => {
        this.props.onCompleted();
    }

    handleNext = () => {
        const { step } = this.state;
        if (step === Step.NeedWallet) {
            this.setState(produce(d => {
                d.step = Step.ShowKeys;
            }));
        } else if (step === Step.ShowKeys) {
            this.setState(produce(d => {
                d.step = Step.ConfirmSave;
            }));
        } else if (step === Step.ConfirmSave) {
            let form: IForm = {
                userId: this.props.receiver.userid,
                nickname: this.state.publicKey,
                publicKey: this.state.publicKey
            };
            api.WalletCreate(form)
                .then(response => response.data)
                .then((result) => {
                    if (result.succeeded) {
                        this.toast(CommonWordings.walletCreate, Wordings.savedWallet, 'success');
                        this.setState(produce(d => {
                            d.receiverWallets.push(result.data as IWalletInfo);
                            d.step = Step.Transfer;
                        }));
                    } else {
                        this.toast(CommonWordings.walletCreate, Wordings.errorSaving, 'error');
                    }
                })
                .catch((error) => {
                    this.toast(CommonWordings.walletCreate, error.message, 'error');
                });
            }
    }

    toast = (heading: string, text: string, appearance: string) => {
        this.setState(produce(d => {
            d.toast.heading = heading;
            d.toast.text = text;
            d.toast.appearance = appearance; 
        }));
    }

    onKeyGenerated = (publicKey: string, privateKey: string) => {
        this.setState(produce(d => {
            d.publicKey = publicKey;
            d.privateKey = privateKey;
            d.step = Step.ShowKeys;
        }));
    }

    loadWallets = () => {
        const { receiver } = this.props;

        this.setState(produce(d => {
            d.step = Step.Loading;
            d.receiverWallets = [];
            d.isRWLoading = true;
            d.isRWLoadingError = false;
        }));
        api.WalletList({ userid: receiver.userid })
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded)  {
                    this.setState(produce(d => {
                        d.receiverWallets = result.data;
                        d.isRWLoading = false;
                        d.isRWLoadingError = false;
                        d.step = result.data.length === 0 ? Step.NeedWallet : Step.Transfer;
                    }));
                } else {
                    this.setState(produce(d => {
                        d.isRWLoading = false;
                        d.isRWLoadingError = true;
                        d.step = Step.Error;
                    }));
                }
            })
            .catch(() => {
                this.setState(produce(d => {
                    d.isRWLoading = false;
                    d.isRWLoadingError = true;
                    d.step = Step.Error;
                }));
            });
    }

    componentDidMount() {
        this.loadWallets();
    }

    componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState) {
        if (this.props.receiver.userid !== prevProps.receiver.userid) {
            this.loadWallets();
        }
    }

    public render() {
        const { publicKey, privateKey } = this.state;
        const { step, toast } = this.state;
        const { receiver } = this.props;
        const receiverName = `${receiver.firstName} ${receiver.lastName}`

        const title = step === Step.Loading ? Wordings.loadingTitle :
            step === Step.Error ? Wordings.errorTitle :
            step === Step.NeedWallet ? Wordings.needsWalletTitle :
            step === Step.ShowKeys ? Wordings.showkeysTitle :
            step === Step.ConfirmSave ? Wordings.confirmSaveTitle :
            undefined;
        const next = step === Step.NeedWallet ? Wordings.needsWalletAction :
            step === Step.ShowKeys ? Wordings.showkeysAction :
            step === Step.ConfirmSave ? Wordings.confirmSaveAction :
            undefined;

        return (
            <>
                {step !== Step.Transfer &&
                    <Modal show={true} backdrop="static" keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        {step === Step.Loading &&
                            <div>{Wordings.formatString(Wordings.loadingText, receiverName)}</div>
                        }
                        {step === Step.Error &&
                            <div>{Wordings.formatString(Wordings.errorText, receiverName)}</div>
                        }
                        {step === Step.NeedWallet && 
                            <div>{Wordings.formatString(Wordings.needsWalletText, receiverName)}</div>
                        }
                        {step === Step.ShowKeys && 
                            <WalletCreatePart publicKey={publicKey} privateKey={privateKey} toast={this.toast} onKeyGenerated={this.onKeyGenerated} />
                        }
                        {step === Step.ConfirmSave && 
                            <div>{Wordings.formatString(Wordings.confirmSaveText, receiverName)}</div>
                        }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleCancel}>{Wordings.cancel}</Button>
                            {next &&
                                <Button variant="primary" onClick={this.handleNext}>{next}</Button>
                            }
                        </Modal.Footer>
                        <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
                    </Modal>
                }
                {step === Step.Transfer &&
                    <Transfer receiver={receiver} onCompleted={this.props.onCompleted} approval={this.props.approval} />
                }
            </>
        );
    }
};

export default connector(WalletTransfer);
