import * as React from 'react';
import produce from 'immer';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import { AuthWalletLoginWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, IPasswordChangeForm as IForm, IPasswordChangeFormErrors as IFormErrors, IResponseError } from '../../api';
import * as U from '../../utils/BbUtil';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const errorMap = {
    newPassword: {
        required: Wordings.newPasswordRequired,
        weak: Wordings.passwordWeak,
    },
    repeatPassword: {
        required: Wordings.repeatPasswordRequired,
        no_match: Wordings.passwordMismatch,
    }
}

type ComponentProps = {
        username: string;
        onSuccess: () => void
    };

interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly toast: ToastProps
}

class WalletLoginPassword extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            oldPassword: '',
            newPassword: '',
            repeatPassword: ''
        },
        errors: {},
        toast: DefaultToastProps
    };

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.newPassword = (name === 'newPassword') ? value : d.form.newPassword;
            d.form.repeatPassword = (name === 'repeatPassword') ? value : d.form.repeatPassword;
            
            d.errors.newPassword = undefined;
            d.errors.repeatPassword = undefined;
        }));
    }

    validateForm = (): boolean => {
        const { newPassword, repeatPassword } = this.state.form;
        let result: IResponseError = {
            newPassword: newPassword ? undefined : 'required',
            repeatPassword: repeatPassword ? undefined : 'required',
        };
        this.setFormErrors(result);
        return U.isValidForm(result);
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { d.errors = errors }));
    }

    handleSubmit = () => {
        if (!this.validateForm()) {
            return;
        }
        api.PasswordChangeKey(this.state.form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded) {
                    this.props.onSuccess();
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.passwordChangeKey;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    public render() {
        const { form, errors, toast } = this.state;
        const { username } = this.props;
        return (
            <Card>
                <Card.Header as="h3">{Wordings.passwordTitle}</Card.Header>
                <Card.Body>
                    <Form.Group controlId="username">
                        <Form.Label>{Wordings.username}</Form.Label>
                        <Form.Control readOnly type="text" 
                            name="username"
                            defaultValue={username}/>
                    </Form.Group>
                    <Form.Group controlId="newPassword">
                        <Form.Label>{Wordings.newPassword}</Form.Label>
                        <Form.Control required type="password" 
                            name="newPassword"
                            value={form.newPassword}
                            isInvalid={U.isInvalid(errors.newPassword)}
                            onChange={this.handleChange}/>
                        <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="repeatPassword">
                        <Form.Label>{Wordings.repeatPassword}</Form.Label>
                        <Form.Control required type="password" 
                            name="repeatPassword"
                            value={form.repeatPassword}
                            isInvalid={U.isInvalid(errors.repeatPassword)}
                            onChange={this.handleChange}/>
                        <Form.Control.Feedback type="invalid">{errors.repeatPassword}</Form.Control.Feedback>
                    </Form.Group>
                    <Button block variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                </Card.Body>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </Card>
        );
    }
};

export default WalletLoginPassword;
