import { Action, Reducer } from 'redux';
import produce from 'immer';
import { api, IUserLoginResponseData } from '../api';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AppSettings {
    ApiServerUrl: string;
    WebServerUrl: string;
    WalletServerUrl: string;
    SupportEmail: string;
    CaptchaSiteKey: string;
    build: string;
    ShowBargainBay: boolean;
    ShowAnonTransfer: boolean;
}
export interface ApplicationDataState {
    userInfo: IUserLoginResponseData;
    appInfo: AppSettings;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface NoOperationAction { type: 'NO_OPERATION' }
export interface SetUserInfoAction { type: 'SET_USER_INFO', userInfo: IUserLoginResponseData }
export interface SetAppInfoAction { type: 'SET_APP_INFO', appInfo: AppSettings }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = NoOperationAction | SetUserInfoAction | SetAppInfoAction

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    nop: () => ({ type: 'NO_OPERATION' } as NoOperationAction),
    setUserInfo: (userInfo?: IUserLoginResponseData) => ({ type: 'SET_USER_INFO', userInfo } as SetUserInfoAction),
    setAppInfo: (appInfo: AppSettings) => ({ type: 'SET_APP_INFO', appInfo } as SetAppInfoAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ApplicationDataState> = (state: ApplicationDataState | undefined, incomingAction: Action): ApplicationDataState => {
    if (state === undefined) {
        const appInfo = window.AppSettings;
        return {
            userInfo: {} as IUserLoginResponseData,
            appInfo: appInfo || {}
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'NO_OPERATION':
            return state;
        case 'SET_USER_INFO':
            {
                api.setToken(action.userInfo?.token);
                return produce(state, d => {
                    d.userInfo = action.userInfo || {};
                });
            }
        case 'SET_APP_INFO':
            {
                return produce(state, d => {
                    d.appInfo.build = action.appInfo.build;
                });
            }
        default:
            return state;
    }
};
