import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable, {TableChangeType, TableChangeState, ColumnDescription} from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Moment from 'react-moment';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/UserData';
import { ApprovalListWordings as Wordings, CommonWordings } from '../../Wordings';
import { IUserListForm as IForm, IUserInfo, IUserListSimpleFilter } from '../../api';
import WalletTransfer from '../Transaction/WalletTransfer';
import RejectUser from './RejectUser';

const connector = connect((state: ApplicationState) => state.userList, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
    form: IForm;
    view: View;
    receiver: IUserInfo | undefined;
}
    
enum View {List, Approve, Reject}
class ApprovalList extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            sort: '',
            page: 0,
            sizePerPage: 0,
            requestId: 0,
            simple: true,
            filter: {
                text: '',
                status: 'pending'
            }
        },
        view: View.List,
        receiver: undefined,
    }

    fetchData = (): void => {
        const page = 0;
        const sizePerPage = 10;
        const pageForm = {
            ...this.state.form,
            page, sizePerPage,
        }
        this.props.fetchUserList(pageForm);
    }

    fetchPageData = (page: number, sizePerPage: number): void => {
        const pageForm = {
            ...this.state.form,
            page, sizePerPage,
        }
        this.props.fetchUserList(pageForm);
    }

    handleFilterEdit = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            const filter = d.form.filter as IUserListSimpleFilter;
            filter.text = (name === 'text') ? value : filter.text;
            filter.status = (name === 'status') ? value : filter.status as any;
        }));
    }

    handleFilterSelect = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
    
        const curr = this.state.form.filter as IUserListSimpleFilter;
        const filter: IUserListSimpleFilter = {
            text: (name === 'text') ? value : curr.text,
            status: (name === 'status') ? value : curr.status as any
        }
        this.setState(produce(d => {
            d.form.filter = filter;
        }));
        const page = 0;
        const sizePerPage = 10;
        const pageForm = {
            ...this.state.form,
            page, sizePerPage,
            filter
        }
        this.props.fetchUserList(pageForm);
    }

    onTableChange = (type: TableChangeType, newState: TableChangeState<any>): void => {
        this.fetchPageData(newState.page, newState.sizePerPage);
    }

    onEdit = () => {
    }

    onActionCompleted = () => {
        this.setState(produce(d => {
            d.view = View.List;
        }));
    }

    onAction = (event:React.MouseEvent<HTMLButtonElement & React.MouseEvent>) => {
        const { userid, action } = event.currentTarget.dataset;
        if (!userid) {
            return;
        }
        const uid = parseInt(userid);
        if (action === 'detail') {
        } else {
            this.setState(produce(d => {
                d.view = action === 'approve' ? View.Approve : View.Reject;
                d.receiver = this.props.data.find(u => u.userid === uid);
            }));
        }
    }

    statusColumn = (col: any, row: IUserInfo) => {
        const id = row.userid;

        const status = (!row.reviewedAt ? 'pending' : (row.approved ? 'approved' : 'rejected'));
        const statusLabel = (!row.reviewedAt ? Wordings.pending : (row.approved ? Wordings.approved : Wordings.rejected));
        const approve = <Button variant="link" size="sm" data-action="approve" data-userid={id} onClick={this.onAction}>{Wordings.approveUser}</Button>;
        const reject = <Button variant="link" size="sm" data-action="reject" data-userid={id} onClick={this.onAction}>{Wordings.rejectUser}</Button>;
        const detail = <></>; // <Button variant="link" size="sm" data-action="detail" data-userid={id} onClick={this.onAction}>{Wordings.details}</Button>;
        return <>
            {statusLabel}
            <br/>
            {row.reviewedAt && <><Moment fromNow>{row.reviewedAt}</Moment>{' '}</>}
            {status === 'approved' && detail}
            {status === 'rejected' && <ButtonGroup>{approve}{detail}</ButtonGroup>}
            {status === 'pending' && <ButtonGroup>{approve}{reject}{detail}</ButtonGroup>}
        </>
    }

    public componentDidMount() {
        this.fetchData();
    }

    public render() {
        const columns: ColumnDescription[] = [{
          dataField: 'firstName',
          text: Wordings.name,
          formatter: (col: any, row: IUserInfo) => <>
            {row.firstName}{' '}{row.lastName}
            <br/>{row.username}
          </>
        }, {
            dataField: 'idType',
            text: Wordings.id,
            formatter: (col: any, row: IUserInfo) => <>
                {row.idType}{': '}{row.idNumber}
            </>
        }, {
            dataField: 'status',
            text: Wordings.status,
            formatter: this.statusColumn
        }];
        const {page, sizePerPage, totalSize, data} = this.props;

        const pf = paginationFactory({
            page, // Specify the current page. It's necessary when remote is enabled
            sizePerPage, // Specify the size per page. It's necessary when remote is enabled
            totalSize, // Total data size. It's necessary when remote is enabled
            pageStartIndex: 0, // first page will be 0, default is 1
            paginationSize: 3,  // the pagination bar size, default is 5
            showTotal: true, // display pagination information
            sizePerPageList: [ {
              text: '5', value: 5
            }, {
              text: '10', value: 10
            }, {
              text: CommonWordings.all, value: totalSize
            } ], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            firstPageText: CommonWordings.firstPageText, // the text of first page button
            prePageText: CommonWordings.prePageText, // the text of previous page button
            nextPageText: CommonWordings.nextPageText, // the text of next page button
            lastPageText: CommonWordings.lastPageText, // the text of last page button
            nextPageTitle: CommonWordings.nextPageTitle, // the title of next page button
            prePageTitle: CommonWordings.prePageTitle, // the title of previous page button
            firstPageTitle: CommonWordings.firstPageTitle, // the title of first page button
            lastPageTitle: CommonWordings.lastPageTitle, // the title of last page button
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
            onPageChange: (page, sizePerPage) => { this.fetchPageData(page, sizePerPage)}, // callback function when page was changing
            onSizePerPageChange: (sizePerPage, page) => {this.fetchPageData(page, sizePerPage)}, // callback function when page size was changing
            //paginationTotalRenderer: (from, to, size) => { ... }  // custom the pagination total
        });
        const { view, receiver } = this.state;
        const { form } = this.state;
        const filter = form.filter as IUserListSimpleFilter;
        const options = [
            {value:'all', label:CommonWordings.allFilter, group: 1},
            {value:'pending', label:CommonWordings.pendingFilter, group: 4},
            {value:'approved', label:CommonWordings.approvedFilter, group: 4},
            {value:'rejected', label:CommonWordings.rejectedFilter, group: 4},
        ].sort((a, b) => (a.group - b.group) || a.label.localeCompare(b.label));
        return (
            <>
                <Card>
                    <Card.Body className="row">
                        <Card.Title as="h3">{Wordings.title}</Card.Title>
                        <Form inline className="ml-auto" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
                            <Form.Control size="sm" as="select" className="mr-sm-2" 
                                name="status"
                                value={filter.status}
                                onChange={this.handleFilterSelect}>
                                {options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                            </Form.Control>
                            <Form.Control size="sm" type="text" className="mr-sm-2" 
                                placeholder={CommonWordings.textFilterPlaceholder}
                                name="text"
                                value={filter.text}
                                onChange={this.handleFilterEdit}/>
                            <Button size="sm" variant="outline-success" onClick={this.fetchData}>{CommonWordings.search}</Button>{' '}
                        </Form>
                    </Card.Body>
                    <BootstrapTable keyField='userid' remote data={ data } columns={ columns } pagination={ pf } onTableChange={ this.onTableChange } />
                </Card>
                {view === View.Approve &&
                    <WalletTransfer receiver={receiver as IUserInfo} onCompleted={this.onActionCompleted} approval={true} />
                }
                {view === View.Reject &&
                    <RejectUser receiver={receiver as IUserInfo} onCompleted={this.onActionCompleted}/>
                }
            </>
        );
    }
};

export default connector(ApprovalList);
