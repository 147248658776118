import { RouteComponentProps } from 'react-router';

import * as qs from 'query-string';
import { PositionProperty } from 'csstype';
import { IResponseError } from '../api';
import { CommonWordings } from '../Wordings';

export interface IFormErrors
{
    [index: string]: string | undefined;
}

export const isValidForm = (errors: any): boolean => {
    const err = errors as IFormErrors;
    let valid = Object.values(err).some((v) => v && v.length > 0) === false;
    return valid;
}

export const isInvalid = (message: string | undefined): boolean | undefined => {
    return (message && message.length > 0) ? true : false;
}

export const isRequired = (value: string | undefined, message: string): string | undefined => {
    return (value && value.length > 0) ? undefined : message;
}

export const gotoPage = (props: RouteComponentProps, route: string) => {
    props.history.push({
        pathname: route,
        search: props.location.search
    });
}

export const getReturnUrl = (search: string): string | undefined => {
    if (!search) {
        return undefined;
    }
    let qp = qs.parse(search, { decode: true });
    let returnUrl = (qp.returnUrl ?? qp.ReturnUrl)?.toString();
    return returnUrl;
}

export const mapError = (errorCode: string | undefined, errorMap: {[ec: string]: string}) => {
    if (errorCode === undefined) {
        return undefined;
    }
    if (errorCode.indexOf('?') >= 0) {
        const parts: string[] = errorCode.split(/[?,]/);
        const errorText = errorMap[parts[0]] || parts[0];
        parts.shift();
        return CommonWordings.formatString(errorText, ...parts) as string;
    }
    return errorMap[errorCode] || errorCode;
}

export const toFormErrors = (errors: IResponseError, errorMap: { [field: string]: {[ec: string]: string}}): IFormErrors => {
    let formErrors: IFormErrors = {};
    Object.keys(errors).forEach(field => {
        formErrors[field] = mapError(errors[field], errorMap[field]);
    });
    return formErrors;
}
export const hasFormErrors = (errors: any): boolean => {
    return Object.values(errors).some(f => f);
}

export interface Position {
    position: PositionProperty;
    top: number;
    right: number;
}
export const toastPosition:Position = {
    position: 'absolute',
    top: 0,
    right: 0,
};
