import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import produce from 'immer';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { ApplicationState } from '../../store';
import * as ApplicationDataStore from '../../store/ApplicationData';
import { AuthLogoutWordings as Wordings } from '../../Wordings';
import { api, IUserLogoutForm as IForm } from '../../api';
import * as U from '../../utils/BbUtil';
import AppRoutes from '../../AppRoutes';

const connector = connect((state: ApplicationState) => state.appdata, ApplicationDataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux 
    & RouteComponentProps<{}>

enum Status { Initial, LoggingOut, LoggededOut }
interface ComponentState {
    readonly form: IForm;
    readonly status: Status;
}

/*
https://fgcorp.atlassian.net/browse/FGC-10
Logout Confirmation Screen
Upon using the logout button, the user will be presented with this screen that gives a confirmation that logout was successful.  
The user’s profile will be updated with the log-on and log-off times.
The screen will give the user an option to log-in again. 
*/
class UserLogout extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {},
        status: Status.Initial
    };

    handleLogout = () => {
        if (this.state.status !== Status.Initial) {
            return;
        }
        this.setState(produce(d => { d.status = Status.LoggingOut; }));
        let form: IForm = {
            username: this.props.userInfo?.username
        };
        api.UserLogout(form)
            .then((response) => {
            })
            .catch((error) => {
            }).finally(() => {
                this.props.setUserInfo(undefined);
                this.setState(produce(d => { d.status = Status.LoggededOut; }));
            });
    }

    public componentDidMount() {
        this.handleLogout();
    }

    public componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState) {
        if (this.state.status !== prevState.status) {
            this.handleLogout();
        }
    }

    public render() {
        const { status } = this.state;
        return (
            <>
                <Card>
                    <Card.Header as="h3">{Wordings.title}</Card.Header>
                    <Card.Body>
                        {status === Status.LoggingOut &&
                            <Card.Text>{Wordings.formatString(Wordings.loggingOut, <br/>)}</Card.Text>
                        }
                        {status === Status.LoggededOut &&
                            <Card.Text>{Wordings.formatString(Wordings.loggedOut, <br/>)}</Card.Text>
                        }
                        <Button variant="primary" onClick={() => U.gotoPage(this.props, AppRoutes.UserLogin)}>{Wordings.login}</Button>
                    </Card.Body>
                </Card>
            </>
        );
    }
};

export default connector(UserLogout);
