import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { WalletLaterWordings as Wordings } from '../../Wordings';
import AppRoutes from '../../AppRoutes';

type ComponentProps = RouteComponentProps<{}>;

interface ComponentState {
}
    
/*
https://fgcorp.atlassian.net/browse/FGC-13
See Your National Coordinator Screen
If the registrant ops to save wallet creation for later, they will see this message and a option to exit from the process.
Their information will be captured, but wallet will be associated with their profile.
Log-out of exit option is on the page
*/
class WalletLater extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        return (
            <>
                <Card>
                    <Card.Header as="h3">{Wordings.title}</Card.Header>
                    <Card.Body>
                        <Card.Text>{Wordings.formatString(Wordings.text, <br/>)}</Card.Text>
                        <LinkContainer to={AppRoutes.UserLogout}>
                            <Button variant="primary">{Wordings.logout}</Button>
                        </LinkContainer>
                    </Card.Body>
                </Card>
            </>
        );
    }
};

export default WalletLater;
