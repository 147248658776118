import * as React from 'react';

import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

type ComponentProps = {
    title: string;
    text: string;
};

interface ComponentState {
}
    
class WalletsLoading extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title as="h3">{this.props.title}</Card.Title>
                </Card.Body>
                <Card.Text>{this.props.text}</Card.Text>
                <Spinner animation="border" />
            </Card>
        );
    }
};

export default WalletsLoading;
