import * as React from 'react';
import produce from 'immer';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';

import { AuthWalletLoginWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, IWalletLoginForm as IForm, IWalletLoginErrors as IFormErrors, IResponseError } from '../../api';
import * as U from '../../utils/BbUtil';
import AppRoutes from '../../AppRoutes';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const errorMap = {
    privateKey: {
        required: Wordings.privateKeyRequired,
        invalid: Wordings.privateKeyInvalid
    }
}

type ComponentProps = {
    onSuccess: (username: string) => void
};

interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly toast: ToastProps
}

class WalletLoginKey extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            privateKey: '',
        },
        errors: {},
        toast: DefaultToastProps
    };

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.privateKey = (name === 'privateKey') ? value : d.form.privateKey;
            
            d.errors.privateKey = undefined;
        }));
    }

    validateForm = (): boolean => {
        const { privateKey } = this.state.form;
        let result: IResponseError = {
            privateKey: privateKey ? undefined : 'required',
        };
        this.setFormErrors(result);
        return U.isValidForm(result);
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { d.errors = errors }));
    }

    handleLogin = () => {
        if (!this.validateForm()) {
            return;
        }
        let form = {
            privateKey: this.state.form.privateKey
        };
        api.WalletLogin(form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded) {
                    api.setToken(result.data?.token as string);
                    this.props.onSuccess(result.data?.username as string);
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.walletLogin;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    public render() {
        const { form, errors, toast } = this.state;
        return (
            <Card>
                <Card.Header as="h3">{Wordings.title}</Card.Header>
                <Card.Body>
                    <Form.Group controlId="privateKey">
                        <Form.Label>{Wordings.privateKey}</Form.Label>
                        <Form.Control required type="password" 
                            name="privateKey"
                            value={form.privateKey}
                            isInvalid={U.isInvalid(errors.privateKey)}
                            onChange={this.handleChange}/>
                        <Form.Control.Feedback type="invalid">{errors.privateKey}</Form.Control.Feedback>
                    </Form.Group>
                    <Button block variant="primary" onClick={this.handleLogin}>{Wordings.submit}</Button>
                </Card.Body>
                <Card.Body>
                    <p>
                        <small style={{verticalAlign: 'middle'}}>{Wordings.passwordLogin}</small>
                        <LinkContainer to={AppRoutes.UserLogin}>
                            <Button variant="link" size="sm"><small>{Wordings.login}</small></Button>
                        </LinkContainer>
                    </p>
                </Card.Body>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </Card>
        );
    }
};

export default WalletLoginKey;
