import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";

export interface IAppWordings extends LocalizedStringsMethods {
    brand: string;
    home: string;
    wallet: string;
    approval: string;
    admin: string;
    transaction: string;
    logout: string;
    help: string;
    password: string;
    profile: string;

    anonWallet: string;
    anonBalance: string;
    anonTransfer: string;
    anonStatus: string;

    copyright: string;
    anonCopyright: string;
    build: string;
    terms: string;
    privacy: string;
}
export const AppWordings: IAppWordings = new LocalizedStrings({
    en: {
        brand: 'BargainBay',
        home: 'Home',
        wallet: 'Wallet',
        approval: 'Approval',
        admin: 'Admin',
        transaction: 'Transaction',
        logout: 'Log out',
        help: 'Help',
        password: 'Password',
        profile: 'Profile',

        anonWallet: 'Create Wallet',
        anonBalance: 'Wallet Balance',
        anonStatus: 'Transfer Status',
        anonTransfer: 'Transfer Crypto',

        copyright: "BargainBay copyright 2020",
        anonCopyright: "MHLK-IRM.com copyright 2020",
        build: "Build",
        terms: "Terms and conditions",
        privacy: "Privacy Statement",
    }
})

export interface IHomeWordings extends LocalizedStringsMethods {
    register: string;
    login: string;
    transfer: string;
}
export const HomeWordings: IHomeWordings = new LocalizedStrings({
    en: {
        register: "Register",
        login: "Log in",
        transfer: 'Transfer',
    }
})

export interface ICommonWordings extends LocalizedStringsMethods {
    all: string;
    firstPageText: string;
    prePageText: string;
    nextPageText: string;
    lastPageText: string;
    nextPageTitle: string;
    prePageTitle: string;
    firstPageTitle: string;
    lastPageTitle: string;
    noResultsFound: string;
    yes: string;
    no: string;

    missingInputSecret:	string;
    invalidInputSecret:	string;
    missingInputResponse: string;
    invalidInputResponse: string;
    badRequest: string;
    timeoutOrDuplicate: string;

    textFilterPlaceholder: string;
    activeFilter: string;
    inactiveFilter: string;
    adminFilter: string;
    reviewerFilter: string;
    allFilter: string;
    pendingFilter: string;
    approvedFilter: string;
    rejectedFilter: string;
    search: string;

    error500: string;
    userRegister: string;
    userLogin: string;
    userLogout: string;
    
    passwordChange: string;
    passwordChangeKey: string;
    passwordReset: string;
    
    getBuild: string;
    setup: string;
    setupEdit: string;
    getUser: string;
    userList: string;
    userSetFlag: string;
    userEdit: string;
    
    reviewCodeList: string;
    reviewCodeCreate: string;
    reviewCodeEdit: string;
    
    walletList: string;
    walletCreate: string;
    walletStatus: string;
    walletCheckBalance: string;
    getWallet: string;
    
    transactionList: string;
    
    walletBalance: string;
    walletKeys: string;
    transfer: string;
    walletLogin: string;
    mint: string;
}
export const CommonWordings: ICommonWordings = new LocalizedStrings({
    en: {
        all: 'All',
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'Go to next',
        prePageTitle: 'Go to previous',
        firstPageTitle: 'Go to first',
        lastPageTitle: 'Go to last',
        noResultsFound: 'No results found',
        yes: 'Yes',
        no: 'No',

        missingInputSecret:	'The secret parameter is missing.',
        invalidInputSecret:	'The secret parameter is invalid or malformed.',
        missingInputResponse: 'The response parameter is missing.',
        invalidInputResponse: 'The response parameter is invalid or malformed.',
        badRequest:	'The request is invalid or malformed.',
        timeoutOrDuplicate: 'The response is no longer valid: either is too old or has been used previously.',

        textFilterPlaceholder: "Search",
        activeFilter: 'Active',
        inactiveFilter: 'Disabled',
        adminFilter: 'Admins',
        reviewerFilter: 'Reviewers',
        allFilter: 'All',
        pendingFilter: 'Pending',
        approvedFilter: 'Approved',
        rejectedFilter: 'Rejected',
        search: 'Refresh',

        error500: 'Internal Error',

        userRegister: 'Register',
        userLogin: 'Login',
        userLogout: 'Logout',
        
        passwordChange: 'Change Password',
        passwordChangeKey: 'Reset Password',
        passwordReset: 'Password Reset',
        
        getBuild: 'Get Build',
        setup: 'Get Setup',
        setupEdit: 'Edit Setup',
        getUser: 'Get User',
        userList: 'Get User List',
        userSetFlag: 'Set User Status',
        userEdit: 'Edit Profile',
        
        reviewCodeList: 'Get Review Codes',
        reviewCodeCreate: 'New Review Code',
        reviewCodeEdit: 'Edit Review Code',
        
        walletList: 'Get Wallets',
        walletCreate: 'Create Wallet',
        walletStatus: 'Set Wallet Status',
        walletCheckBalance: 'Check Wallet Balance',
        getWallet: 'Get Wallet',
        
        transactionList: 'Get Transactions',
        
        walletBalance: 'Get Wallet Balance',
        walletKeys: 'Create Wallet Keys',
        transfer: 'Transfer',
        walletLogin: 'Recover Password',
        mint: 'Mint',
    }
})

export interface IAuthRegisterWordings extends LocalizedStringsMethods {
    title: string;
    firstName: string;
    lastName: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    idType: string;
    idNumber: string;
    reviewCode: string;
    phoneNumber: string;
    email: string;
    username: string;
    password: string;
    repeatPassword: string;
    firstNameRequired: string;
    lastNameRequired: string;
    streetRequired: string;
    cityRequired: string;
    stateRequired: string;
    postalCodeRequired: string;
    countryRequired: string;
    idTypeRequired: string;
    idNumberRequired: string;
    reviewCodeRequired: string;
    phoneNumberRequired: string;
    phoneNumberInvalid: string;
    emailRequired: string;
    emailInvalid: string;
    usernameRequired: string;
    usernameTaken: string;
    passwordRequired: string;
    passwordMismatch: string;

    dataTooLong: string;
    firstNameInvalid: string;
    lastNameInvalid: string;
    streetInvalid: string;
    cityInvalid: string;
    stateInvalid: string;
    postalCodeInvalid: string;
    countryInvalid: string;
    idTypeInvalid: string;
    idNumberInvalid: string;
    idNumberHasAccount: string;
    reviewCodeInvalid: string;
    usernameInvalid: string;
    passwordInvalid: string;
    passwordWeak: string;

    submit: string;
    haveAccount: string;
    login: string;

    profileTitle: string;
    savedHeader: string;
    createdText: string;
    savedText: string;
}
export const AuthRegisterWordings: IAuthRegisterWordings = new LocalizedStrings({
    en: {
        title: "Please fill in this online registration form",
        firstName: "First Name",
        lastName: "Last Name",
        street: "Street",
        city: "City",
        state: "State",
        postalCode: "Postal Code",
        country: "Country",
        idType: "ID Type",
        idNumber: "ID Number",
        reviewCode: "Review Code",
        phoneNumber: "Cell/Mobile phone number",
        email: "Email address",
        username: "Username",
        password: "Password",
        repeatPassword: "Password Reenter",
        firstNameRequired: "First Name is required",
        lastNameRequired: "Last Name is required",
        streetRequired: "Address is required",
        cityRequired: "City is required",
        stateRequired: "State is required",
        postalCodeRequired: "Postal code is required",
        countryRequired: "Country is required",
        idTypeRequired: "ID Type is required",
        idNumberRequired: "ID Number is required",
        idNumberHasAccount: "Account exists for this Id",
        reviewCodeRequired: "Routing code is required",
        phoneNumberRequired: "Phone number is required",
        phoneNumberInvalid: "Phone number is invalid",
        emailRequired: "Email is required",
        emailInvalid: "Email is invalid",
        usernameRequired: "Username is required",
        usernameTaken: "Username is taken, choose another",
        passwordRequired: "Password is required",
        passwordMismatch: "Password does not match",
    
        dataTooLong: "Maximum allowed is {0} characters",
        firstNameInvalid: "First name is invalid",
        lastNameInvalid: "Last name is invalid",
        streetInvalid: "Street is invalid",
        cityInvalid: "City is invalid",
        stateInvalid: "State is invalid",
        postalCodeInvalid: "Postal code is invalid",
        countryInvalid: "Country is invalid",
        idTypeInvalid: "ID type is invalid",
        idNumberInvalid: "ID number is invalid",
        reviewCodeInvalid: "Review code is invalid",
        usernameInvalid: "Username is invalid",
        passwordInvalid: "Password is invalid",
        passwordWeak: "Choose a stronger password",
    
        submit: "Submit",
        haveAccount: "Have an account?",
        login: "Log in",

        createdText: "You have successfully registered at Bargain Bay!",
        profileTitle: "Profile",
        savedHeader: "Profile Saved",
        savedText: "Your profiles is updated successfully",
    }
})

export interface IAuthLoginWordings extends LocalizedStringsMethods {
    title: string;
    username: string;
    password: string;
    submit: string;

    usernameRequired: string;
    usernameInvalid: string;
    passwordRequired: string;
    passwordInvalid: string;

    forgotPassword: string;
    resetPassword: string;
}
export const AuthLoginWordings: IAuthLoginWordings = new LocalizedStrings({
    en: {
        title: "Log in",
        username: "Username",
        password: "Password",
        submit: "Log in",

        usernameRequired: "Username is required",
        usernameInvalid: 'No account found',
        passwordRequired: "Password is required",
        passwordInvalid: 'Check your username/password and try again.',

        forgotPassword: 'Forgot you username or password?',
        resetPassword: 'Reset Password',
    }
})
export interface IAuthWalletLoginWordings extends LocalizedStringsMethods {
    title: string;
    passwordTitle: string

    privateKey: string;
    username: string;
    newPassword: string;
    repeatPassword: string;

    privateKeyRequired: string;
    newPasswordRequired: string;
    repeatPasswordRequired: string;

    privateKeyInvalid: string;
    passwordMismatch: string;
    passwordWeak: string;

    successTitle: string;
    successText: string;
    passwordLogin: string;

    login: string;
    submit: string;
    cancel: string;
}
export const AuthWalletLoginWordings: IAuthWalletLoginWordings = new LocalizedStrings({
    en: {
        title: "Reset Password",
        passwordTitle: "Change Password",

        privateKey: 'Private Key',
        username: 'Username',
        newPassword: 'New Password',
        repeatPassword: 'Password Reenter',
    
        privateKeyRequired: 'Private Key is required',
        newPasswordRequired: 'New password is required',
        repeatPasswordRequired: 'Repeat your new passowrd',
    
        privateKeyInvalid: 'Private Key is invalid',
        passwordMismatch: 'New passwords did not match',
        passwordWeak: 'Choose a stronger password',
    
        successTitle: 'Password Changed',
        successText: 'You password in changed. You can now login using the Login page with you username and password.',
        passwordLogin: 'Need to login with username and password?',

        login: 'Log in',
        submit: 'Submit',
        cancel: 'Cancel'
    }
})
export interface IChangePasswordWordings extends LocalizedStringsMethods {
    title: string;

    oldPassword: string;
    newPassword: string;
    repeatPassword: string;

    oldPasswordRequired: string;
    newPasswordRequired: string;
    repeatPasswordRequired: string;

    oldPasswordInvalid: string;
    passwordMismatch: string;
    passwordWeak: string;

    submit: string;
    cancel: string;
}
export const ChangePasswordWordings: IChangePasswordWordings = new LocalizedStrings({
    en: {
        title: "Change Password",

        oldPassword: 'Current Password',
        newPassword: 'New Password',
        repeatPassword: 'Password Reenter',
    
        oldPasswordRequired: 'Current password is required',
        newPasswordRequired: 'New password is required',
        repeatPasswordRequired: 'Repeat your new passowrd',
    
        oldPasswordInvalid: 'Current password did not match',
        passwordMismatch: 'New passwords did not match',
        passwordWeak: 'Choose a stronger password',
    
        submit: 'Submit',
        cancel: 'Cancel'
    }
})

export interface IAuthLogoutWordings extends LocalizedStringsMethods {
    title: string;
    loggingOut: string;
    loggedOut: string;
    login: string;
}
export const AuthLogoutWordings: IAuthLogoutWordings = new LocalizedStrings({
    en: {
        title: "Log out",
        loggingOut: "You are being logged out",
        loggedOut: "You have successfully logged out of Bargain Bay!",
        login: "LogIn"
    }
})

export interface IUserListWordings extends LocalizedStringsMethods {
    title: string;
    firstName: string;
    lastName: string;
    name: string;
    address: string;
    country: string;
    id: string;
    idType: string;
    idNumber: string;
    reviewCode: string;
    phoneNumber: string;
    email: string;
    username: string;
    status: string;
    role: string;
    admin: string;
    reviewer: string;
    user: string;
    active: string;
    inactive: string;

    actions: string;
    enableUser: string;
    disableUser: string;
    grantAdmin: string;
    revokeAdmin: string;
    grantReviewer: string;
    revokeReviewer: string;
    transfer: string;

    edit: string;
}
export const UserListWordings: IUserListWordings = new LocalizedStrings({
    en: {
        title: "Users",
        firstName: "First Name",
        lastName: "Last Name",
        name: "Name",
        address: "Address",
        country: "Country",
        id: "ID",
        idType: "ID Type",
        idNumber: "ID Number",
        reviewCode: "Review Code",
        phoneNumber: "Phone",
        email: "Email",
        username: "Username",
        status: "Status",
        role: "Role",
        user: "User",
        admin: "Admin",
        reviewer: "Reviewer",
        active: "Active",
        inactive: "Inactive",

        actions: "",
        enableUser: "Enable User",
        disableUser: "Diable User",
        grantAdmin: "Grant Admin",
        revokeAdmin: "Revoke Admin",
        grantReviewer: "Grant Reviewer",
        revokeReviewer: "Revoke Reviewer",
        transfer: "Transfer",
        edit: "Edit",
    }
})

export interface IApprovalListWordings extends LocalizedStringsMethods {
    title: string;
    firstName: string;
    lastName: string;
    name: string;
    address: string;
    country: string;
    id: string;
    idType: string;
    idNumber: string;
    reviewCode: string;
    phoneNumber: string;
    email: string;
    username: string;
    status: string;
    approved: string;
    rejected: string;
    pending: string;

    actions: string;
    details: string;
    approveUser: string;
    rejectUser: string;
}
export const ApprovalListWordings: IApprovalListWordings = new LocalizedStrings({
    en: {
        title: "Approvals",
        firstName: "First Name",
        lastName: "Last Name",
        name: "Name",
        address: "Address",
        country: "Country",
        id: "ID",
        idType: "ID Type",
        idNumber: "ID Number",
        reviewCode: "Review Code",
        phoneNumber: "Phone",
        email: "Email",
        username: "Username",
        status: "",
        approved: "Approved",
        rejected: "Rejected",
        pending: "Pending",

        actions: "",
        details: 'Details',
        approveUser: "Approve",
        rejectUser: "Reject"
    }
})
export interface ITransactionWordings extends LocalizedStringsMethods {
    title: string;
    detailTtitle: string;
    sender: string;
    senderWalletKey: string;
    receiver: string;
    receiverWalletKey: string;
    amount: string;
    transactionId: string;
    transaction: string;
    currency: string;
    startedAt: string;
    completedAt: string;
    statusAt: string;
    status: string;
    details: string;
}
export const TransactionWordings: ITransactionWordings = new LocalizedStrings({
    en: {
        title: "Transactions",
        detailTtitle: "Transaction",
        sender: "Sender",
        senderWalletKey: "Sender Wallet",
        receiver: "Receiver",
        receiverWalletKey: "Receiver Wallet",
        amount: "Amount",
        transactionId: "Transaction Id",
        transaction: "Transaction",
        currency: "Currency",
        startedAt: "Started At",
        completedAt: "Completed At",
        statusAt: "Status At",
        status: "Status",
        details: "Details",
    }
})
export interface IReviewCodeListWordings extends LocalizedStringsMethods {
    title: string;
    reviewCode: string;
    description: string;
    transferDefault: string;
    status: string;
    createdAt: string;
    active: string;
    disabled: string;
    enable: string;
    disable: string;
    edit: string;
    noResultsFound: string;
    add: string;
    refresh: string;
}
export const ReviewCodeListWordings: IReviewCodeListWordings = new LocalizedStrings({
    en: {
        title: "Review Codes",
        reviewCode: "Code",
        description: "Description",
        transferDefault: "Default",
        status: "",
        createdAt: "Created",
        active: "Active",
        disabled: "Disabled",
        enable: "Enable",
        disable: "Disable",
        edit: "Edit",
        noResultsFound: "No review codes found",
        add: "New",
        refresh: "Refresh",
    }
})
export interface IReviewCodeWordings extends LocalizedStringsMethods {
    title: string;
    reviewCode: string;
    description: string;
    transferDefault: string;
    status: string;
    createdAt: string;
    submit: string;
    cancel: string;

    transferDefaultRequired: string;
}
export const ReviewCodeWordings: IReviewCodeWordings = new LocalizedStrings({
    en: {
        title: "Review Code",
        reviewCode: "Code",
        description: "Description",
        transferDefault: "Transfer Default",
        status: "Code",
        createdAt: "Create",
        submit: "Submit",
        cancel: "Cancel",

        transferDefaultRequired: "A default amount is required",
        reviewCodeInvalid: "Review code is invalid. Cancel and try again."
    }
})

export interface IPasswordForgotWordings extends LocalizedStringsMethods {
    title: string;
    usingId: string;
    usingUsername: string;
    idType: string;
    idNumber: string;
    username: string;
    phoneNumber: string;
    submit: string;
    rememberInfo: string;
    login: string;
}
export const PasswordForgotWordings: IPasswordForgotWordings = new LocalizedStrings({
    en: {
        title: "Reset Password",
        usingId: "Using Id",
        usingUsername: "Using Username",
        idType: "ID Type",
        idNumber: "ID Number",
        username: "Username",
        phoneNumber: "Cell/Mobile phone number",
        submit: "Submit",
        rememberInfo: "Remember Password?",
        login: "Log in",
    }
})

export interface IPasswordResetWordings extends LocalizedStringsMethods {
    title: string;
    info: string;
    code: string;
    submit: string;
}
export const PasswordResetWordings: IPasswordResetWordings = new LocalizedStrings({
    en: {
        title: "Enter Code",
        info: "Enter the code that was sent to your phone number",
        code: "Code",
        submit: "Submit"
    }
})

export interface IDashboardWalletWordings extends LocalizedStringsMethods {
    title: string;
    loadingText: string;
    errorLoadingTitle: string;
    errorLoadingText: string;
    addWallet: string;
}
export const DashboardWalletWordings: IDashboardWalletWordings = new LocalizedStrings({
    en: {
        title: "My Wallets",
        loadingText: "Loading Wallets...",
        errorLoadingTitle: 'Error Loading Wallets',
        errorLoadingText: 'There is an error loading your wallets. Please try again.',
        addWallet: "Add New Wallet",
    }
})

export interface IWalletWordings extends LocalizedStringsMethods {
    mhlkCredits: string;
    mhlkBalance: string;
    ethBalance: string;
    lastUpdatedAt: string;
    refreshCredits: string;
    refreshMhlkBalance: string;
    refreshEthBalance: string;
    deleteWallet: string;

    publicKey: string;

    notAvailable: string;
    confirmDeleteTitle: string;
    confirmDeleteHeading: string;
    confirmDeleteText: string;

    keyCopied: string;
}
export const WalletWordings: IWalletWordings = new LocalizedStrings({
    en: {
        mhlkCredits: "MHLK-IRM Credits",
        mhlkBalance: "MHLK-IRM Balance",
        ethBalance: "Ether Balance",
        lastUpdatedAt: "Last updated",
        refreshCredits: "Refresh Credits",
        refreshMhlkBalance: "Refresh Balance",
        refreshEthBalance: "Refresh Ether Balance",
        deleteWallet: "Delete Wallet",

        publicKey: "Public Key",

        notAvailable: "Not Available",
        confirmDeleteTitle: "Confirm Wallet Delete",
        confirmDeleteHeading: "Your wallet will be deleted",
        confirmDeleteText: "This action will delete your wallet {0}. You can create a new wallet and have it linked to your dashboard.{1}Click Yes to delete the wallet.",

        keyCopied: "Your key has been copied and is ready for you to paste."
    }
})
export interface IDashboardNoWalletWordings extends LocalizedStringsMethods {
    title: string;
    nextStep: string;
    requiredAction: string;
    warning: string;
    disclaimer: string;
    createNow: string;
    createLater: string;
}
export const DashboardNoWalletWordings: IDashboardNoWalletWordings = new LocalizedStrings({
    en: {
        title: "You have successfully registered at Bargain Bay!",
        nextStep: "You will be given your Wallet Address and Private Key to receive your cryptocurrency in the next step.",
        requiredAction: "Please be prepared to copy this information to Note Pad or a Word document to save it or print out the document.",
        warning: "Reminder: PLEASE DO NOT SHARE YOUR PRIVATE KEY WITH ANYONE.",
        disclaimer: "This site does not store your Private Key (Wallet Password).",
        createNow: "Create Wallet Now",
        createLater: "Create Wallet Later",
    }
})

export interface IWalletCreateWordings extends LocalizedStringsMethods {
    title: string;
    publicKey: string;
    privateKey: string;
    nextStep: string;
    warning: string;
    proceed: string;

    generatingTitle: string;

    confirmTitle: string;
    confirmYes: string;
    confirmNo: string;
    confirmWarning: string;
    confirmDisclaimer: string;
    errorSaving: string;
    publicKeyCopied: string;
    privateKeyCopied: string;
}
export const WalletCreateWordings: IWalletCreateWordings = new LocalizedStrings({
    en: {
        title: "PLEASE COPY YOUR WALLET ADDRESS AND PRIVATE KEY!",
        publicKey: "Public Key (Wallet Address)",
        privateKey: "Private Key (Password)",
        nextStep: "Please submit your Wallet Address to your National Coordinator to receive your cryptocurrency.",
        warning: "Do not share your Private Key (Password).",
        proceed: "Proceed to Dashboard",

        generatingTitle: "Creating Wallet",

        confirmTitle: "You must save or print your Private Key in a USB drive.",
        confirmYes: "Yes, I did.",
        confirmNo: "No, I did not.",
        confirmWarning: "You cannot access your wallet without your Private Key (Password).",
        confirmDisclaimer: "We do not store your Private Key (Password).",
        errorSaving: 'There was an error creating the wallet. Please try again',

        publicKeyCopied: "Your public key has been copied and is ready for you to paste.",
        privateKeyCopied: "Your private key has been copied and is ready for you to paste.",
    }
})

export interface IWalletTransferWordings extends LocalizedStringsMethods {
    publicKeyCopied: string;
    privateKeyCopied: string;

    cancel: string;
    loadingTitle: string;
    loadingText: string;

    errorTitle: string;
    errorText: string;

    needsWalletTitle: string;
    needsWalletText: string;
    needsWalletAction: string;

    showkeysTitle: string;
    showkeysText: string;
    showkeysAction: string;

    confirmSaveTitle: string;
    confirmSaveText: string;
    confirmSaveAction: string;
    savedWallet: string;
    errorSaving: string;
}
export const WalletTransferWordings: IWalletTransferWordings = new LocalizedStrings({
    en: {

        publicKeyCopied: "Your public key has been copied and is ready for you to paste.",
        privateKeyCopied: "Your private key has been copied and is ready for you to paste.",

        cancel: "Cancel",

        loadingTitle: 'Loading Wallets',
        loadingText: 'Loading Wallets for {0}',
    
        errorTitle: 'Loading Wallets',
        errorText: 'Error loading wallets for {0}',
    
        needsWalletTitle: "Has No Wallet",
        needsWalletText: "User, {0}, does not have a wallet setup. Do you wish to continue to create a wallet for the user?",
        needsWalletAction: "Create Wallet",
    
        showkeysTitle: 'Wallet Created',
        showkeysText: 'User, {0}, cannot access your wallet without the Private Key, the application does not store the Private Key',
        showkeysAction: 'Proceed',
    
        confirmSaveTitle: 'Save Private Key',
        confirmSaveText: "You must save or print the Private Key in a USB drive.",
        confirmSaveAction: "Yes, I did.",
        savedWallet: 'The wallet is created successfully. You may now transfer credits.',
        errorSaving: 'There was an error creating the wallet. Please try again',
    }
})
export interface IWalletListWordings extends LocalizedStringsMethods {
    title: string;
    nickname: string;
    publicKey: string;
    select: string;
    close: string;
    noResultsFound: string;
}
export const WalletListWordings: IWalletListWordings = new LocalizedStrings({
    en: {
        title: "My Wallets",
        nickname: "Nickname",
        publicKey: "Public Key",
        select: "Select",
        close: "Close",
        noResultsFound: "No wallets found",
    }
})

export interface IWalletNewWordings extends LocalizedStringsMethods {
    title: string;
    information: string;
    create: string;
}
export const WalletNewWordings: IWalletNewWordings = new LocalizedStrings({
    en: {
        title: "Help Screen",
        information: "If you lost your Private Key, you can create a new wallet and have it linked to your dashboard by selecting it from the your list when you click the “Wallet Selection” button. {0} However, this process will not recover/replace any coins that were in your old wallet. {0} If you have coins in your wallet and you lost your Private Key, please contact your local National Coordinator for assistance. {0} For assistance, please contact: {1}",
        create: "Create New Wallet",
    }
})

export interface IWalletLaterWordings extends LocalizedStringsMethods {
    title: string;
    text: string;
    logout: string;
}
export const WalletLaterWordings: IWalletLaterWordings = new LocalizedStrings({
    en: {
        title: "See your National Coordinator",
        text: "Please see your National Coordinator for assistance with creating your wallet and printing out your Wallet Address (Open Key) and Password (Private Key).",
        logout: "Log out",
    }
})

export interface IWalletHelpWordings extends LocalizedStringsMethods {
    title: string;
    create: string;
    norecover: string;
    contact: string;
    assistance: string;
    action: string;
}
export const WalletHelpWordings: IWalletHelpWordings = new LocalizedStrings({
    en: {
        title: "Help Screen",
        create: "If you lost your Private Key, you can create a new wallet and have it linked to your dashboard by selecting it from the your list when you click the &quot;Wallet Selection&quot; button.",
        norecover: "However, this process will not recover/replace any coins that were in your old wallet.",
        contact: "If you have coins in your wallet and you lost your Private Key, please contact your local National Coordinator for assistance.",
        assistance: "For assistance, please contact: support@buybargainbay.com",
        action: "Create New Wallet"
    }
})

export interface IDashboardAdminWordings extends LocalizedStringsMethods {
    usersTitle: string;
    transferTitle: string;

    transferAmountMax: string;
    transferNeedEtherMin: string;

    edit: string;
    transferEditTitle: string;
    transferAmountMaxRequired: string;
    transferNeedEtherMinRequired: string;
    transferAmountMaxInvalid: string;
    transferNeedEtherMinInvalid: string;
    submit: string;
    cancel: string;

    logout: string;
    help: string;
}
export const DashboardAdminWordings: IDashboardAdminWordings = new LocalizedStrings({
    en: {
        usersTitle: "Users",
        transferTitle: "Transfer Settings",
        transferAmountMax: "Transfer Limit",
        transferNeedEtherMin: "Min Ether Balance",

        edit: 'edit',
        transferEditTitle: "Edit Transfer Settings",
        transferAmountMaxRequired: "Value is required",
        transferNeedEtherMinRequired: "Value is required",
        transferAmountMaxInvalid: "Value is invalid",
        transferNeedEtherMinInvalid: "Value is invalid",
        submit: 'Submit',
        cancel: 'Cancel',

        logout: "Log out",
        help: "Help",
    }
})
export interface IRejectUserWordings extends LocalizedStringsMethods {
    title: string;

    user: string;
    reason: string;

    submit: string;
    cancel: string;
}
export const RejectUserWordings: IRejectUserWordings = new LocalizedStrings({
    en: {
        title: "Reject User",

        user: "User",
        reason: "reason",

        submit: 'Submit',
        cancel: 'Cancel',
    }
})
export interface ITransferWordings extends LocalizedStringsMethods {
    title: string;

    mhlkBalance: string;
    ethBalance: string;

    senderPublicKey: string;
    senderPrivateKey: string;
    receiver: string;
    receiverPublicKey: string;
    amount: string;

    amountRequired: string;
    amountOverlimit: string;
    amountInvalid: string;
    mhlkBalanceInvalid: string;
    ethBalanceInvalid: string;

    senderPrivateKeyRequired: string;
    senderPrivateKeyInvalid: string;
    senderNotOwnPrivateKey: string;
    senderNoMinEth: string;
    receiverPublicKeyRequired: string;

    loadingError: string;
    loading: string;
    nowallet: string;

    submit: string;
    cancel: string;
}
export const TransferWordings: ITransferWordings = new LocalizedStrings({
    en: {
        title: "Transfer",

        mhlkBalance: "MHLK-IRM Balance",
        ethBalance: "Ether Balance",

        senderPublicKey: "Your Wallet",
        senderPrivateKey: "Private Key",
        receiver: "Receiver",
        receiverPublicKey: "Receiver Wallet",
        amount: "Transfer Credits",

        amountRequired: "Enter a valid amount",
        amountOverlimit: 'The maximum allowed is {0}',
        amountInvalid: 'Invalid amount',
        mhlkBalanceInvalid: "Insufficient balance",
        ethBalanceInvalid: "Insufficient balance",

        senderPrivateKeyRequired: 'Private Key is required',
        senderPrivateKeyInvalid: 'Private Key is invalid',
        senderNotOwnPrivateKey: 'Use your own private key',
        senderNoMinEth: 'More Ether is required for this transaction.',
        receiverPublicKeyRequired: 'Wallet is required',

        loadingError: 'Error Loading Wallets',
        loading: 'Loading...',
        nowallet: 'Has no wallet',
    
        submit: 'Submit',
        cancel: 'Cancel',
    }
})
export interface IMintWordings extends LocalizedStringsMethods {
    title: string;

    publicKey: string;
    amount: string;

    loadingError: string;
    loading: string;
    nowallet: string;

    submit: string;
    cancel: string;
}
export const MintWordings: IMintWordings = new LocalizedStrings({
    en: {
        title: "Mint",

        publicKey: "Public Key",
        amount: "Transfer Credits",

        loadingError: 'Error Loading Wallets',
        loading: 'Loading...',
        nowallet: 'Has no wallet',
    
        submit: 'Submit',
        cancel: 'Cancel',
    }
})
export interface IAnonWalletCreateWordings extends LocalizedStringsMethods {
    title: string;
    publicKey: string;
    privateKey: string;

    acceptWarning: string;
    warningNotAccepted: string;
    publicKeyCopied: string;
    privateKeyCopied: string;
    submit: string;
}
export const AnonWalletCreateWordings: IAnonWalletCreateWordings = new LocalizedStrings({
    en: {
        title: "Create Wallet",
        publicKey: "Public Key",
        privateKey: "Private Key",

        acceptWarning: "I agree that I must save or print the Private Key and store it in a safe location. I agree that I cannot access the wallet without the Private Key.",
        warningNotAccepted: 'You must agree to the above.',
        publicKeyCopied: "Your public key has been copied and is ready for you to paste.",
        privateKeyCopied: "Your private key has been copied and is ready for you to paste.",
        submit: "Create"
    }
})
export interface IAnonWalletBalanceWordings extends LocalizedStringsMethods {
    title: string;
    publicKey: string;

    publicKeyRequired: string;
    publicKeyInvalid: string;
    notAvailable: string;
    submit: string;
}
export const AnonWalletBalanceWordings: IAnonWalletBalanceWordings = new LocalizedStrings({
    en: {
        title: "Wallet Balances",
        publicKey: "Public Key",

        publicKeyRequired: "Public Key is required",
        publicKeyInvalid: "Public Key is invalid",
        notAvailable: "Not Available",
        submit: 'Get Balances'
    }
})
export interface IAnonTransferWordings extends LocalizedStringsMethods {
    title: string;

    senderPrivateKey: string;
    receiverPublicKey: string;
    currency: string;
    amount: string;
    memo: string;

    amountRequired: string;
    amountOverlimit: string;
    amountInvalid: string;
    mhlkBalanceInvalid: string;
    ethBalanceInvalid: string;

    senderPrivateKeyRequired: string;
    senderPrivateKeyInvalid: string;
    senderNoMinEth: string;
    receiverPublicKeyRequired: string;

    submit: string;
    another: string;
}
export const AnonTransferWordings: IAnonTransferWordings = new LocalizedStrings({
    en: {
        title: "Transfer",

        senderPrivateKey: "Sender Private Key",
        receiverPublicKey: "Receiver Public Key",
        currency: "Currency",
        amount: "Amount",
        memo: "Memo",

        amountRequired: "Enter a valid amount",
        amountOverlimit: 'The maximum allowed is {0}',
        amountInvalid: 'Invalid amount',
        mhlkBalanceInvalid: "Insufficient balance",
        ethBalanceInvalid: "Insufficient balance",

        senderPrivateKeyRequired: 'Private Key is required',
        senderPrivateKeyInvalid: 'Private Key is invalid',
        senderNoMinEth: 'More Ether is required for this transaction.',
        receiverPublicKeyRequired: 'Wallet is required',

        submit: 'Submit',
        another: 'New Transaction',
    }
})
export interface IAnonTransferStatusWordings extends LocalizedStringsMethods {
    title: string;

    query: string;

    senderPublicKey: string;
    receiverPublicKey: string;
    currency: string;
    amount: string;
    memo: string;
    startedAt: string;
    completedAt: string;
    status: string;
    transactionId: string;

    queryRequired: string;
    submit: string;
}
export const AnonTransferStatusWordings: IAnonTransferStatusWordings = new LocalizedStrings({
    en: {
        title: "Transaction Status",

        query: "Transaction Id or Sender Public Key",
        senderPublicKey: "Sender Public Key",
        receiverPublicKey: "Receiver Public Key",
        currency: "Currency",
        amount: "Amount",
        memo: "Memo",

        startedAt: "Started At",
        completedAt: 'Completed At',
        status: 'Status',
        transactionId: 'Transaction Id',

        queryRequired: "Transaction Id  or Public Key is required",
        submit: "Get Transactions",
    }
})
export interface OptionType {
    value: string;
    group: number;
}
export const IdTypeOptions:OptionType[] = [
    {value:'pp', group: 1},
    {value:'dl', group: 4},
    {value:'nc', group: 4},
]
export interface IIdTypeWordings extends LocalizedStringsMethods {
    pp: string;
    dl: string;
    nc: string;
}
export const IdTypeWordings:IIdTypeWordings = new LocalizedStrings({
    en: {
        pp: 'Passport',
        dl: 'Driver License',
        nc: 'National Card'
    }
})

export const TransactionStates:OptionType[] = [
    {value:'any', group: 1},
    {value:'S', group: 4},
    {value:'P', group: 4},
    {value:'F', group: 4},
    {value:'A', group: 4},
]
export interface ITransactionStateWordings extends LocalizedStringsMethods {
    any: string;
    S: string;
    P: string;
    F: string;
    A: string;
}
export const TransactionStateWordings:ITransactionStateWordings = new LocalizedStrings({
    en: {
        any: 'Any',
        P: 'Pending',
        S: 'Successful',
        F: 'Failed',
        A: 'Unknown'
    }
})
export const CurrencyOptions:OptionType[] = [
    {value:'mhlk', group: 1},
    {value:'eth', group: 1}
]
export interface ICurrencyWordings extends LocalizedStringsMethods {
    mhlk: string;
    eth: string;
}
export const CurrencyWordings:ICurrencyWordings = new LocalizedStrings({
    en: {
        mhlk: 'MHLK-IRM',
        eth: 'ETH'
    }
})
