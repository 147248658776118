import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable, {TableChangeType, TableChangeState, ColumnDescription} from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Card from 'react-bootstrap/Card';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { Gear } from 'react-bootstrap-icons';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/UserData';
import { UserListWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, IUserListForm as IForm, IUserInfo, IUserFlagForm, IUserListSimpleFilter } from '../../api';
import Transfer from '../Transaction/Transfer';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const connector = connect((state: ApplicationState) => state, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
    readonly form: IForm;
    readonly view: View;
    readonly user: IUserInfo | undefined;
    readonly toast: ToastProps
}

enum View {List, Transfer}
class UserList extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            sort: '',
            page: 0,
            sizePerPage: 0,
            requestId: 0,
            simple: true,
            filter: {
                text: '',
                status: 'active'
            }
        },
        view: View.List,
        user: undefined,
        toast: DefaultToastProps
    }

    fetchData = (): void => {
        const page = 0;
        const sizePerPage = 10;
        const pageForm = {
            ...this.state.form,
            page, sizePerPage,
        }
        this.props.fetchUserList(pageForm);
    }

    fetchPageData = (page: number, sizePerPage: number): void => {
        const pageForm = {
            ...this.state.form,
            page, sizePerPage,
        }
        this.props.fetchUserList(pageForm);
    }

    handleFilterEdit = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            const filter = d.form.filter as IUserListSimpleFilter;
            filter.text = (name === 'text') ? value : filter.text;
            filter.status = (name === 'status') ? value : filter.status as any;
        }));
    }

    handleFilterSelect = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
    
        const curr = this.state.form.filter as IUserListSimpleFilter;
        const filter: IUserListSimpleFilter = {
            text: (name === 'text') ? value : curr.text,
            status: (name === 'status') ? value : curr.status as any
        }
        this.setState(produce(d => {
            d.form.filter = filter;
        }));
        const page = 0;
        const sizePerPage = 10;
        const pageForm = {
            ...this.state.form,
            page, sizePerPage,
            filter
        }
        this.props.fetchUserList(pageForm);
    }

    onTableChange = (type: TableChangeType, newState: TableChangeState<any>): void => {
        this.fetchPageData(newState.page, newState.sizePerPage);
    }

    onTransferDone = () => {
        this.setState(produce(d => {
            d.view = View.List;
        }));
    }
    onActionSelect = (eventKey: string, event: React.SyntheticEvent<unknown>) => {
        const [value, flag, uid] = eventKey.split('-');
        const userid = parseInt(uid);
        const user = this.props.userList.data.find(u => u.userid === userid);

        this.setState(produce(d => {
            d.user = user;
        }));
        if (flag === 'transfer') {
            this.setState(produce(d => {
                d.view = View.Transfer;
            }));
            return;
        }
        const form:IUserFlagForm = {
            userid,
            flag,
            value: value === 'e'
        };
        api.UserSetFlag(form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded)  {
                    this.props.updateUser(result.data as IUserInfo);
                } else {

                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.userSetFlag;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    statusColumn = (col: any, row: IUserInfo, index: number) => {
        let status = "";
        status += (row.active ? Wordings.active : Wordings.inactive);

        let role = "";
        let delim = '';
        if (row.admin) {
            role += delim + Wordings.admin;
            delim = ", ";
        }
        if (row.reviewer) {
            role += delim + Wordings.reviewer;
            delim = ", ";
        }
        if (!row.admin && !row.reviewer) {
            role += delim + Wordings.user;
            delim = ", ";
        }
        return `${status} [${role}]`;
    }

    actionColumn = (col: any, row: IUserInfo, index: number) => {
        const {admin, reviewer, active} = row;
        const self = row.username === this.props.appdata.userInfo.username;
        const id = row.userid;
        if (self) {
            // only useful operation on self is Grant Reviewer
            if (reviewer) {
                return "";
            }
            const actions = (<DropdownButton variant="link" className="caret-off" dir="right" size="sm" as={ButtonGroup} title={<Gear/>} id={`action-dd-${index}`}>
                <Dropdown.Item eventKey={`e-reviewer-${id}`} onSelect={this.onActionSelect}>{Wordings.grantReviewer}</Dropdown.Item>
            </DropdownButton>);
            return actions;
        }

        const actions = (<DropdownButton variant="link" className="caret-off" dir="right" size="sm" as={ButtonGroup} title={<Gear/>} id={`action-dd-${index}`}>
            {active &&
                <Dropdown.Item eventKey={`d-active-${id}`} onSelect={this.onActionSelect}>{Wordings.disableUser}</Dropdown.Item>
            }
            {!active &&
                <Dropdown.Item eventKey={`e-active-${id}`} onSelect={this.onActionSelect}>{Wordings.enableUser}</Dropdown.Item>
            }
            {reviewer &&
                <Dropdown.Item eventKey={`d-reviewer-${id}`} onSelect={this.onActionSelect}>{Wordings.revokeReviewer}</Dropdown.Item>
            }
            {!reviewer &&
                <Dropdown.Item eventKey={`e-reviewer-${id}`} onSelect={this.onActionSelect}>{Wordings.grantReviewer}</Dropdown.Item>
            }
            {admin &&
                <Dropdown.Item eventKey={`d-admin-${id}`} onSelect={this.onActionSelect}>{Wordings.revokeAdmin}</Dropdown.Item>
            }
            {!admin &&
                <Dropdown.Item eventKey={`e-admin-${id}`} onSelect={this.onActionSelect}>{Wordings.grantAdmin}</Dropdown.Item>
            }
            {(reviewer || admin) &&
                <Dropdown.Divider/>
            }
            {(reviewer || admin) &&
                <Dropdown.Item eventKey={`d-transfer-${id}`} onSelect={this.onActionSelect}>{Wordings.transfer}</Dropdown.Item>
            }
        </DropdownButton>);
        return actions;
    }
      
    public componentDidMount() {
        this.fetchData();
    }

    public render() {
        const columns: ColumnDescription[] = [{
            dataField: 'firstName',
            text: Wordings.name,
            formatter: (col: any, row: IUserInfo) => <>
              {row.firstName}{' '}{row.lastName}
              <br/>{row.username}
            </>
        }, {
            dataField: 'idType',
            text: Wordings.id,
            formatter: (col: any, row: IUserInfo) => <>
                {row.idType}{': '}{row.idNumber}
            </>
        }, {
            dataField: 'street',
            formatter: (col: any, row: IUserInfo) => `${row.street}, ${row.city} ${row.state}, ${row.country}`,
            text: Wordings.address
        }, {
            dataField: 'phoneNumber',
            text: Wordings.phoneNumber
        }, {
            dataField: 'status',
            formatter: this.statusColumn,
            text: Wordings.status
        }, {
            dataField: 'actions',
            formatter: this.actionColumn,
            width: 60,
            text: Wordings.actions
        }];
        const {page, sizePerPage, totalSize, data} = this.props.userList;

        const pf = paginationFactory({
            page, // Specify the current page. It's necessary when remote is enabled
            sizePerPage, // Specify the size per page. It's necessary when remote is enabled
            totalSize, // Total data size. It's necessary when remote is enabled
            pageStartIndex: 0, // first page will be 0, default is 1
            paginationSize: 3,  // the pagination bar size, default is 5
            showTotal: true, // display pagination information
            sizePerPageList: [ {
              text: '5', value: 5
            }, {
              text: '10', value: 10
            }, {
              text: CommonWordings.all, value: totalSize
            } ], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            firstPageText: CommonWordings.firstPageText, // the text of first page button
            prePageText: CommonWordings.prePageText, // the text of previous page button
            nextPageText: CommonWordings.nextPageText, // the text of next page button
            lastPageText: CommonWordings.lastPageText, // the text of last page button
            nextPageTitle: CommonWordings.nextPageTitle, // the title of next page button
            prePageTitle: CommonWordings.prePageTitle, // the title of previous page button
            firstPageTitle: CommonWordings.firstPageTitle, // the title of first page button
            lastPageTitle: CommonWordings.lastPageTitle, // the title of last page button
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
            onPageChange: (page, sizePerPage) => { this.fetchPageData(page, sizePerPage)}, // callback function when page was changing
            onSizePerPageChange: (sizePerPage, page) => {this.fetchPageData(page, sizePerPage)}, // callback function when page size was changing
            //paginationTotalRenderer: (from, to, size) => { ... }  // custom the pagination total
        });
        const { form } = this.state;
        const filter = form.filter as IUserListSimpleFilter;
        const options = [
            {value:'active', label:CommonWordings.activeFilter, group: 2},
            {value:'reviewer', label:CommonWordings.reviewerFilter, group: 3},
            {value:'admin', label:CommonWordings.adminFilter, group: 3},
            {value:'all', label:CommonWordings.allFilter, group: 1},
            {value:'inactive', label:CommonWordings.inactiveFilter, group: 2},
            {value:'pending', label:CommonWordings.pendingFilter, group: 4},
            {value:'approved', label:CommonWordings.approvedFilter, group: 4},
            {value:'rejected', label:CommonWordings.rejectedFilter, group: 4},
        ].sort((a, b) => (a.group - b.group) || a.label.localeCompare(b.label));
        const { view, user, toast } = this.state;
        return (
            <>
                <Card>
                    <Card.Body className="row">
                        <Card.Title as="h3">{Wordings.title}</Card.Title>
                        <Form inline className="ml-auto" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
                            <Form.Control size="sm" as="select" className="mr-sm-2" 
                                name="status"
                                value={filter.status}
                                onChange={this.handleFilterSelect}>
                                {options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                            </Form.Control>
                            <Form.Control size="sm" type="text" className="mr-sm-2" 
                                placeholder={CommonWordings.textFilterPlaceholder}
                                name="text"
                                value={filter.text}
                                onChange={this.handleFilterEdit}/>
                            <Button size="sm" variant="outline-success" onClick={this.fetchData}>{CommonWordings.search}</Button>{' '}
                        </Form>
                    </Card.Body>
                    <BootstrapTable keyField='userid' remote data={ data } columns={ columns } pagination={ pf } 
                        onTableChange={ this.onTableChange } />
                </Card>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
                {view === View.Transfer &&
                    <Transfer receiver={user as IUserInfo} onCompleted={this.onTransferDone} approval={false} />
                }
            </>
        );
    }
};

export default connector(UserList);
