import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import QRCode from 'qrcode.react';
import produce from 'immer';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Clipboard } from 'react-bootstrap-icons';

import Moment from 'react-moment';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/WalletData';
import { WalletWordings as Wordings } from '../../Wordings';
import { IWalletInfo, Currency } from '../../api';
import ConfirmAction from '../Util/ConfirmAction';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';
import CurrencyFormat from '../Util/CurrencyFormat';
import moment from 'moment';

const connector = connect((state: ApplicationState) => state.appdata, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux
    & { wallet: IWalletInfo };

interface ComponentState {
    readonly confirmDelete: boolean;
    readonly timer: NodeJS.Timeout | undefined;
    readonly toast: ToastProps;
}
    
class Wallet extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        confirmDelete: false,
        timer: undefined,
        toast: DefaultToastProps
    };

    fetchBalance = (event: React.MouseEvent<HTMLAnchorElement & MouseEvent>): void => {
        event.preventDefault();
        const { publicKey } = this.props.wallet;
        const { isAdmin, isReviewer } = this.props.userInfo;
        const currency:Currency[] = isAdmin || isReviewer ? ['mhlk', 'eth'] : ['mhlk'];
        this.props.fetchWalletBalance({ publicKey, currency, refresh: true });
    };

    fetchInitialBalance = (): void => {
        const { publicKey, balanceAt } = this.props.wallet;
        const staleAfter = moment(balanceAt).add(10, 'm').utc();
        const now = moment().utc();
        if (now.isAfter(staleAfter)) {
            const { isAdmin, isReviewer } = this.props.userInfo;
            const currency:Currency[] = isAdmin || isReviewer ? ['mhlk', 'eth'] : ['mhlk'];
            this.props.fetchWalletBalance({ publicKey, currency, refresh: false });
        }
    };

    deleteCard = (event: React.MouseEvent<HTMLAnchorElement & MouseEvent>): void => {
        event.preventDefault();
        this.setState(produce(d => {
            d.confirmDelete = true;
        }));
    };

    deleteYes = (): void => {
        this.setState(produce(d => {
            d.confirmDelete = false;
        }));
        const {walletid} = this.props.wallet;
        this.props.deleteWallet(walletid);
    };

    deleteNo = (): void => {
        this.setState(produce(d => {
            d.confirmDelete = false;
        }));
    };

    onCopy = () => {
        this.setState(produce(d => {
            d.toast.heading = "";
            d.toast.appearance = 'success';
            d.toast.text = Wordings.keyCopied;
        }));
    }

    componentDidMount() {
        this.fetchInitialBalance();
    }

    public render() {
        const { wallet } = this.props;
        const { isAdmin, isReviewer } = this.props.userInfo;
        const { toast } = this.state;
        const isSimpleUser = !isAdmin && !isReviewer;
        const qrcode = (
            <Popover id="wallet-qrcode">
              <Popover.Content>
                  <QRCode value={wallet.publicKey} size={256}/>
              </Popover.Content>
            </Popover>
        );
        const spinnerColor = {backgroundColor: '#6c757d'};
        const { mhlkBalance, isMhlkZero, ethBalance, isEthZero } = wallet;
        const len = Math.max(mhlkBalance.length, ethBalance.length);
        const font = (len <= 6) ? 'h1' : (len <= 8) ? 'h3' : (len < 11) ? 'h5' : 'h6';
        return (
            <>
            <Card style={{marginBottom: '2em'}}>
                <Card.Body>
                    <Row>
                        <Col xs={6}>
                            <Card.Title as="h4">{isSimpleUser ? Wordings.mhlkCredits : Wordings.mhlkBalance}</Card.Title>
                            {mhlkBalance !== '-1' &&  <Card.Text as={font}>
                                <CurrencyFormat value={mhlkBalance} displayType={'text'} thousandSeparator={true} decimalPlaces={2}/>
                            </Card.Text>}
                            {mhlkBalance === '-1' &&  <Card.Text className='text-danger'>
                                {Wordings.notAvailable}
                            </Card.Text>}
                            <Card.Text as="small">
                                {Wordings.lastUpdatedAt}{' '} <Moment fromNow>{wallet.balanceAt}</Moment>
                            </Card.Text>
                        </Col>
                        <Col xs={6}>
                            {!isSimpleUser &&
                                <>
                                    <Card.Title as="h4">{Wordings.ethBalance}</Card.Title>
                                    {ethBalance !== '-1' &&  <Card.Text as={font}>
                                        <CurrencyFormat value={ethBalance} displayType={'text'} thousandSeparator={true} decimalPlaces={4}/>
                                    </Card.Text>}
                                    {ethBalance === '-1' &&  <Card.Text className='text-danger'>
                                        {Wordings.notAvailable}
                                    </Card.Text>}
                                </>
                            }
                            {isSimpleUser &&
                                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={qrcode} delay={{show:500, hide:100}}>
                                    <QRCode value={wallet.publicKey} size={128}/>
                                </OverlayTrigger>
                            }
                        </Col>
                        <Col xs={12}>
                            <br/>
                        </Col>
                        <Col xs={6}>
                            <Card.Text as="h5">{Wordings.publicKey}</Card.Text>
                        </Col>
                        <Col xs={6}>
                            {!isSimpleUser &&
                                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={qrcode} delay={{show:500, hide:100}}>
                                    <Button variant="link">QR Code</Button>
                                </OverlayTrigger>
                            }
                        </Col>
                    </Row>
                    <InputGroup className="mb-3">
                        <Form.Control readOnly type="text" value={wallet.publicKey} />
                        <InputGroup.Append>
                            <CopyToClipboard text={wallet.publicKey} onCopy={this.onCopy}>
                                <Button variant="outline-secondary"><Clipboard/></Button>
                            </CopyToClipboard>
                        </InputGroup.Append>
                    </InputGroup>
                    <Card.Link href="#" data-currency='mhlk' onClick={this.fetchBalance}>
                        {wallet.isUpdating && <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" style={spinnerColor} />}
                        {Wordings.refreshCredits}
                    </Card.Link>
                    {isMhlkZero && isEthZero &&
                        <Card.Link href="#" onClick={this.deleteCard}>{Wordings.deleteWallet}</Card.Link>
                    }
                </Card.Body>
            </Card>
            <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            <ConfirmAction show={this.state.confirmDelete} title={Wordings.confirmDeleteTitle}
                heading={Wordings.confirmDeleteHeading}
                text={Wordings.formatString(Wordings.confirmDeleteText, <>{wallet.publicKey}</>, <br/>)}
                alert="warning"
                onNo={this.deleteNo}
                onYes={this.deleteYes}
                />
            </>
        );
    }
}

export default connector(Wallet);