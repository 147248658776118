import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable, {TableChangeType, TableChangeState, ColumnDescription} from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/TransactionData';
import { TransactionWordings as Wordings, CommonWordings, TransactionStateWordings, TransactionStates } from '../../Wordings';
import { ITransactionListForm as IForm, ITransactionInfo, ITransactionListSimpleFilter } from '../../api';
import NumberFormat from 'react-number-format';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';

const connector = connect((state: ApplicationState) => state.transactionList, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux & {
    onSelectRow?: (transaction?: ITransactionInfo) => void
};

interface ComponentState {
    form: IForm;
}
    
class TransactionList extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            sort: '',
            page: 0,
            sizePerPage: 0,
            requestId: 0,
            simple: true,
            filter: {
                text: '',
                status: 'any'
            }
        }
    }

    fetchData = (): void => {
        const page = 0;
        const sizePerPage = 10;
        const pageForm = {
            ...this.state.form,
            page, sizePerPage,
        }
        this.props.fetchTransactionList(pageForm);
    }

    fetchPageData = (page: number, sizePerPage: number): void => {
        const pageForm = {
            ...this.state.form,
            page, sizePerPage,
        }
        this.props.fetchTransactionList(pageForm);
    }

    handleFilterEdit = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            const filter = d.form.filter as ITransactionListSimpleFilter;
            filter.text = (name === 'text') ? value : filter.text;
            filter.status = (name === 'status') ? value : filter.status as any;
        }));
    }

    handleFilterSelect = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
    
        const curr = this.state.form.filter as ITransactionListSimpleFilter;
        const filter: ITransactionListSimpleFilter = {
            text: (name === 'text') ? value : curr.text,
            status: (name === 'status') ? value : curr.status as any
        }
        this.setState(produce(d => {
            d.form.filter = filter;
        }));
        const page = 0;
        const sizePerPage = 10;
        const pageForm = {
            ...this.state.form,
            page, sizePerPage,
            filter
        }
        this.props.fetchTransactionList(pageForm);
    }

    onTableChange = (type: TableChangeType, newState: TableChangeState<any>): void => {
        this.fetchPageData(newState.page, newState.sizePerPage);
    }

    onActionSelect = (event:React.MouseEvent<HTMLButtonElement>) => {
        const { txnid } = event.currentTarget.dataset;
        if (!txnid) {
            return;
        }
        const id = parseInt(txnid);
        const txn = this.props.data.find(t => t.txnid === id);
        if (this.props.onSelectRow) {
            this.props.onSelectRow(txn)
        }
    }

    statusColumn = (col: any, row: ITransactionInfo) => {
        const status = TransactionStateWordings.getString(row.status) || row.status;
        return <>
            {status}{' '}
            <Button variant="link" data-txnid={row.txnid} onClick={this.onActionSelect}>{Wordings.details}</Button>
        </>;
    }

    public componentDidMount() {
        this.fetchData();
    }

    public render() {
        const columns: ColumnDescription[] = [{
            dataField: 'sender',
            text: Wordings.sender
        }, {
            dataField: 'receiver',
            text: Wordings.receiver
        }, {
            dataField: 'amount',
            text: Wordings.amount,
            formatter: (col: any, row: ITransactionInfo) => 
                <NumberFormat value={row.amount} displayType={'text'} thousandSeparator={true} />
        }, {
            dataField: 'status',
            formatter: this.statusColumn,
            text: Wordings.status
        }];
        const {page, sizePerPage, totalSize, data} = this.props;

        const pf = paginationFactory({
            page, // Specify the current page. It's necessary when remote is enabled
            sizePerPage, // Specify the size per page. It's necessary when remote is enabled
            totalSize, // Total data size. It's necessary when remote is enabled
            pageStartIndex: 0, // first page will be 0, default is 1
            paginationSize: 3,  // the pagination bar size, default is 5
            showTotal: true, // display pagination information
            sizePerPageList: [ {
              text: '5', value: 5
            }, {
              text: '10', value: 10
            }, {
              text: CommonWordings.all, value: totalSize
            } ], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
            withFirstAndLast: false, // hide the going to first and last page button
            alwaysShowAllBtns: true, // always show the next and previous page button
            firstPageText: CommonWordings.firstPageText, // the text of first page button
            prePageText: CommonWordings.prePageText, // the text of previous page button
            nextPageText: CommonWordings.nextPageText, // the text of next page button
            lastPageText: CommonWordings.lastPageText, // the text of last page button
            nextPageTitle: CommonWordings.nextPageTitle, // the title of next page button
            prePageTitle: CommonWordings.prePageTitle, // the title of previous page button
            firstPageTitle: CommonWordings.firstPageTitle, // the title of first page button
            lastPageTitle: CommonWordings.lastPageTitle, // the title of last page button
            hideSizePerPage: true, // hide the size per page dropdown
            hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
            onPageChange: (page, sizePerPage) => { this.fetchPageData(page, sizePerPage)}, // callback function when page was changing
            onSizePerPageChange: (sizePerPage, page) => {this.fetchPageData(page, sizePerPage)}, // callback function when page size was changing
            //paginationTotalRenderer: (from, to, size) => { ... }  // custom the pagination total
        });
        const { form } = this.state;
        const filter = form.filter as ITransactionListSimpleFilter;
        const options = TransactionStates.map(o => ({
            value: o.value, 
            label: TransactionStateWordings.getString(o.value), 
            group: o.group
        })).sort((a, b) => (a.group - b.group) || a.label.localeCompare(b.label));
        return (
            <>
                <Card>
                    <Card.Body className="row">
                        <Card.Title as="h3">{Wordings.title}</Card.Title>
                        <Form inline className="ml-auto" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
                            <Form.Control size="sm" as="select" className="mr-sm-2" 
                                name="status"
                                value={filter.status}
                                onChange={this.handleFilterSelect}>
                                {options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                            </Form.Control>
                            <Form.Control size="sm" type="text" className="mr-sm-2" 
                                placeholder={CommonWordings.textFilterPlaceholder}
                                name="text"
                                value={filter.text}
                                onChange={this.handleFilterEdit}/>
                            <Button size="sm" variant="outline-success" onClick={this.fetchData}>{CommonWordings.search}</Button>{' '}
                        </Form>
                    </Card.Body>
                    <BootstrapTable keyField='txnid' remote data={ data } columns={ columns } pagination={ pf } 
                        onTableChange={ this.onTableChange } />
                </Card>
            </>
        );
    }
};

export default connector(TransactionList);
