import * as React from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import { Clipboard } from 'react-bootstrap-icons';
import CopyToClipboard from 'react-copy-to-clipboard';

import { WalletCreateWordings as Wordings, CommonWordings } from '../../Wordings';
import { api } from '../../api';

type ComponentProps = {
    publicKey: string;
    privateKey: string;
    onKeyGenerated: (publicKey: string, privateKey: string) => void;
    toast: (heading: string, text: string, appearance: string) => void;
}

interface ComponentState {
    readonly copiedPublicKey: boolean;
    readonly copiedPrivateKey: boolean;
}
    
export default class WalletCreatePart extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        copiedPublicKey: false,
        copiedPrivateKey: false,
    };

    generateKeys = () => {
        api.WalletKeys({})
            .then(response => response.data)
            .then(result => {
                if (result.publicKey) {
                    this.props.onKeyGenerated(result.publicKey, result.privateKey);
                } else {
                    this.props.toast(CommonWordings.walletKeys, '', 'error');
                }
            })
            .catch((error) => {
                this.props.toast(CommonWordings.walletKeys, '', 'error');
            });
    }

    componentDidMount() {
        this.generateKeys();
    }

    public render() {
        const { publicKey, privateKey, toast } = this.props;
        return (
            <>
                <Form.Group controlId="publicKey">
                    <Form.Label>{Wordings.publicKey}</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control readOnly type="text" defaultValue={publicKey}/>
                        <InputGroup.Append>
                            <CopyToClipboard text={publicKey} onCopy={() => toast('', Wordings.publicKeyCopied, 'success')}>
                                <Button variant="outline-secondary"><Clipboard/></Button>
                            </CopyToClipboard>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="privateKey">
                    <Form.Label>{Wordings.privateKey}</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control readOnly type="text" defaultValue={privateKey}/>
                        <InputGroup.Append>
                            <CopyToClipboard text={privateKey} onCopy={() => toast('', Wordings.privateKeyCopied, 'success')}>
                                <Button variant="outline-secondary"><Clipboard/></Button>
                            </CopyToClipboard>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
            </>
        );
    }
};
