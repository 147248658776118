import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { Clipboard } from 'react-bootstrap-icons';

import CopyToClipboard from 'react-copy-to-clipboard';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/UserData';
import { AnonWalletCreateWordings as Wordings, CommonWordings } from '../../Wordings';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';
import { api, IAnonKeysRequest as IForm, IAnonKeysErrors as IFormErrors, IResponseError, IAnonKeysResponse } from '../../api';
import * as U from '../../utils/BbUtil';

const errorMap = {
    captcha: {
        'missing-input-secret':	CommonWordings.missingInputSecret,
        'invalid-input-secret':	CommonWordings.invalidInputSecret,
        'missing-input-response': CommonWordings.missingInputResponse,
        'invalid-input-response': CommonWordings.missingInputResponse,
        'bad-request': CommonWordings.badRequest,
        'timeout-or-duplicate':	CommonWordings.timeoutOrDuplicate,
        'unknown-error': CommonWordings.badRequest
    }
}

const connector = connect((state: ApplicationState) => state, DataStore.actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly view: View;
    readonly warningAccepted: boolean;
    readonly warningNotAccepted: string;
    readonly publicKey: string;
    readonly privateKey: string;
    readonly copiedPublicKey: boolean;
    readonly copiedPrivateKey: boolean;
    readonly toast: ToastProps;
    readonly captchaKey: number;
}
    
enum View { Create, Created }
class WalletCreate extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            captcha: ''
        },
        errors: {},
        view: View.Create,
        warningAccepted: false,
        warningNotAccepted: '',
        publicKey: '',
        privateKey: '',
        copiedPublicKey: false,
        copiedPrivateKey: false,
        toast: DefaultToastProps,
        captchaKey: 1
    };

    validateForm = (): boolean => {
        const { warningAccepted } = this.state;
        this.setState(produce(d => { 
            d.warningNotAccepted = warningAccepted ? '' : Wordings.warningNotAccepted;
        }));
        return warningAccepted;
    }

    handleCbChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const checked = event.currentTarget.checked;
        this.setState(produce(d => {
            d.warningAccepted = (name === 'acceptWarning') ? checked : d.warningAccepted;
            d.warningNotAccepted = d.warningAccepted ? '' : Wordings.warningNotAccepted;
        }));
    }
    onCaptchaChange = (newValue: string|null) => {
        this.setState(produce(d => { d.form.captcha = newValue ?? '' }));
    }
    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { d.errors = errors }));
    }

    handleSubmit = () => {
        if (!this.validateForm()) {
            return;
        }
        api.AnonKeys(this.state.form)
            .then(response => response.data)
            .then(result => {
                if (result.succeeded) {
                    this.setState(produce(d => {
                        const data = result.data as IAnonKeysResponse;
                        d.publicKey = data.publicKey;
                        d.privateKey = data.privateKey;
                        d.copiedPublicKey = false;
                        d.copiedPrivateKey = false;
                        d.view = View.Created;
                    }));
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = Wordings.title;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }
    onPrivateCopy = () => {
        this.setState(produce(d => {
            d.toast.heading = "";
            d.toast.appearance = 'success';
            d.toast.text = Wordings.privateKeyCopied;
        }));
    }
    onPublicCopy = () => {
        this.setState(produce(d => {
            d.toast.heading = "";
            d.toast.appearance = 'success';
            d.toast.text = Wordings.publicKeyCopied;
        }));
    }

    public render() {
        const { view, warningAccepted, warningNotAccepted, publicKey, privateKey, toast } = this.state;
        return (
            <>
                <Card>
                    <Card.Header as="h3">{Wordings.title}</Card.Header>
                    <Card.Body>
                    {view === View.Create &&
                        <>
                            <Form.Group as={Col} xs={12} controlId="acceptWarning">
                                <Form.Check type="checkbox" name="acceptWarning" label={Wordings.acceptWarning} onChange={this.handleCbChange}
                                            checked={warningAccepted ? warningAccepted : undefined} isInvalid={U.isInvalid(warningNotAccepted)}/>
                                <Form.Control.Feedback type="invalid">{warningNotAccepted}</Form.Control.Feedback>
                            </Form.Group>
                            <Button block variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                        </>
                    }
                    {view === View.Created &&
                        <>
                            <Form.Group as={Col} xs={12} controlId="publicKey">
                                <Form.Label>{Wordings.publicKey}</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control readOnly type="text" defaultValue={publicKey}/>
                                    <InputGroup.Append>
                                        <CopyToClipboard text={publicKey} onCopy={this.onPublicCopy}>
                                            <Button variant="outline-secondary"><Clipboard/></Button>
                                        </CopyToClipboard>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} controlId="privateKey">
                                <Form.Label>{Wordings.privateKey}</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control readOnly type="text" defaultValue={privateKey}/>
                                    <InputGroup.Append>
                                        <CopyToClipboard text={privateKey} onCopy={this.onPrivateCopy}>
                                            <Button variant="outline-secondary"><Clipboard/></Button>
                                        </CopyToClipboard>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </>
                    }
                    </Card.Body>
                </Card>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </>
        );
    }
};

export default connector(WalletCreate);
