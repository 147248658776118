import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

import Button from 'react-bootstrap/Button';

import { HomeWordings as Wordings } from '../Wordings';
import AppRoutes from '../AppRoutes';

type ComponentProps = {};

interface ComponentState {
}
    
class Home extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
    };

    public render() {
        const showAnonTransfer = window.AppSettings.ShowAnonTransfer;
        return (
            <>
                <LinkContainer to={AppRoutes.UserRegister}>
                    <Button variant="primary" block>{Wordings.register}</Button>
                </LinkContainer>
                <LinkContainer to={AppRoutes.UserLogin}>
                    <Button variant="primary" block>{Wordings.login}</Button>
                </LinkContainer>
                {showAnonTransfer && <LinkContainer to={AppRoutes.AnonTransfer}>
                    <Button variant="primary" block>{Wordings.transfer}</Button>
                </LinkContainer>}
            </>
        );
    }
};

export default Home;
