import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import produce from 'immer';

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import ReCAPTCHA from "react-google-recaptcha";

import { ApplicationState } from '../../store';
import * as ApplicationDataStore from '../../store/ApplicationData';
import { AuthRegisterWordings as Wordings, IdTypeOptions, IdTypeWordings, CommonWordings } from '../../Wordings';
import { api, IUserRegisterForm as IForm, IUserRegisterFormErrors as IFormErrors, IResponseError, IUserLoginResponseData } from '../../api';
import * as U from '../../utils/BbUtil';
import AppRoutes from '../../AppRoutes';
import { CountryOptions, CountryWordings } from '../../Country';
import Toaster, { DefaultToastProps, ToastProps } from '../Util/Toaster';

const errorMap = {
    firstName: {
        required: Wordings.firstNameRequired,
        invalid: Wordings.firstNameInvalid,
        toolong: Wordings.dataTooLong
    },
    lastName: {
        required: Wordings.lastNameRequired,
        invalid: Wordings.lastNameInvalid, 
        toolong: Wordings.dataTooLong
    },
    street: {
        required: Wordings.streetRequired,
        invalid: Wordings.streetInvalid, 
        toolong: Wordings.dataTooLong
    },
    city: {
        required: Wordings.cityRequired,
        invalid: Wordings.cityInvalid, 
        toolong: Wordings.dataTooLong
    },
    state: {
        required: Wordings.stateRequired,
        invalid: Wordings.stateInvalid, 
        toolong: Wordings.dataTooLong
    },
    postalCode: {
        required: Wordings.postalCodeRequired,
        invalid: Wordings.postalCodeInvalid, 
        toolong: Wordings.dataTooLong
    },
    country: {
        required: Wordings.countryRequired,
        invalid: Wordings.countryInvalid, 
        toolong: Wordings.dataTooLong
    },
    idType: {
        required: Wordings.idTypeRequired,
        invalid: Wordings.idTypeInvalid, 
        toolong: Wordings.dataTooLong
    },
    idNumber: {
        required: Wordings.idNumberRequired,
        invalid: Wordings.idNumberInvalid, 
        has_account: Wordings.idNumberHasAccount,
        toolong: Wordings.dataTooLong
    },
    reviewCode: {
        required: Wordings.reviewCodeRequired,
        invalid: Wordings.reviewCodeInvalid, 
    },
    phoneNumber: {
        required: Wordings.phoneNumberRequired,
        invalid: Wordings.phoneNumberInvalid, 
        toolong: Wordings.dataTooLong
    },
    email: {
        required: Wordings.emailRequired,
        invalid: Wordings.emailInvalid, 
        toolong: Wordings.dataTooLong
    },
    username: {
        required: Wordings.usernameRequired,
        invalid: Wordings.usernameInvalid, 
        taken: Wordings.usernameTaken, 
        toolong: Wordings.dataTooLong
    },
    password: {
        required: Wordings.passwordRequired,
        invalid: Wordings.passwordInvalid, 
        weak: Wordings.passwordWeak, 
        toolong: Wordings.dataTooLong
    },
    repeatPassword: {
        required: Wordings.passwordRequired,
        invalid: Wordings.passwordInvalid, 
        no_match: Wordings.passwordMismatch, 
    },
    captcha: {
        'missing-input-secret':	CommonWordings.missingInputSecret,
        'invalid-input-secret':	CommonWordings.invalidInputSecret,
        'missing-input-response': CommonWordings.missingInputResponse,
        'invalid-input-response': CommonWordings.missingInputResponse,
        'bad-request': CommonWordings.badRequest,
        'timeout-or-duplicate':	CommonWordings.timeoutOrDuplicate,
        'unknown-error': CommonWordings.badRequest
    }
}

const connector = connect((state: ApplicationState) => state.appdata, ApplicationDataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux 
    & RouteComponentProps<{}>;

interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly toast: ToastProps;
    readonly captchaKey: number;
}

/*
https://fgcorp.atlassian.net/browse/FGC-12
Registrants Data Fields Screen
*/
class UserRegister extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            firstName: '',
            lastName: '',
            street: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            idType: '',
            idNumber: '',
            reviewCode: '',
            phoneNumber: '',
            email: '',
            username: '',
            password: '',
            repeatPassword: '',
            captcha: ''
        },
        errors: {},
        toast: DefaultToastProps,
        captchaKey: 1
    };

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.firstName = (name === 'firstName') ? value : d.form.firstName;
            d.form.lastName = (name === 'lastName') ? value : d.form.lastName;
            d.form.street = (name === 'street') ? value : d.form.street;
            d.form.city = (name === 'city') ? value : d.form.city;
            d.form.state = (name === 'state') ? value : d.form.state;
            d.form.postalCode = (name === 'postalCode') ? value : d.form.postalCode;
            d.form.country = (name === 'country') ? value : d.form.country;
            d.form.idType = (name === 'idType') ? value : d.form.idType;
            d.form.idNumber = (name === 'idNumber') ? value : d.form.idNumber;
            d.form.reviewCode = (name === 'reviewCode') ? value : d.form.reviewCode;
            d.form.phoneNumber = (name === 'phoneNumber') ? value : d.form.phoneNumber;
            d.form.email = (name === 'email') ? value : d.form.email;
            d.form.username = (name === 'username') ? value : d.form.username;
            d.form.password = (name === 'password') ? value : d.form.password;
            d.form.repeatPassword = (name === 'repeatPassword') ? value : d.form.repeatPassword;

            d.errors.firstName = (name === 'firstName') ? undefined : d.errors.firstName;
            d.errors.lastName = (name === 'lastName') ? undefined : d.errors.lastName;
            d.errors.street = (name === 'street') ? undefined : d.errors.street;
            d.errors.city = (name === 'city') ? undefined : d.errors.city;
            d.errors.state = (name === 'state') ? undefined : d.errors.state;
            d.errors.postalCode = (name === 'postalCode') ? undefined : d.errors.postalCode;
            d.errors.country = (name === 'country') ? undefined : d.errors.country;
            d.errors.idType = (name === 'idType') ? undefined : d.errors.idType;
            d.errors.idNumber = (name === 'idNumber') ? undefined : d.errors.idNumber;
            d.errors.reviewCode = (name === 'reviewCode') ? undefined : d.errors.reviewCode;
            d.errors.phoneNumber = (name === 'phoneNumber') ? undefined : d.errors.phoneNumber;
            d.errors.email = (name === 'email') ? undefined : d.errors.email;
            d.errors.username = (name === 'username') ? undefined : d.errors.username;
            d.errors.password = (name === 'password') ? undefined : d.errors.password;
            d.errors.repeatPassword = (name === 'repeatPassword') ? undefined : d.errors.repeatPassword;
        }));
    }

    validateForm = (): boolean => {
        const { firstName, lastName, street, city, state, postalCode, country } = this.state.form;
        const { idType, idNumber, reviewCode, username, password, repeatPassword } = this.state.form;
        let result: IResponseError = {
            firstName: firstName ? undefined : 'required',
            lastName: lastName ? undefined : 'required',
            street: street ? undefined : 'required',
            city: city ? undefined : 'required',
            state: state ? undefined : 'required',
            postalCode: postalCode ? undefined : 'required',
            country: country ? undefined : 'required',
            idType: idType ? undefined : 'required',
            idNumber: idNumber ? undefined : 'required',
            reviewCode: reviewCode ? undefined : 'required',
            username: username ? undefined : 'required',
            password: password ? undefined : 'required',
            repeatPassword: password === repeatPassword ? undefined : 'no_match',
        };
        this.setFormErrors(result);
        return U.isValidForm(result);
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { 
            d.errors = errors;
            if (U.hasFormErrors(errors)) {
                d.captchaKey = d.captchaKey + 1;
            }
        }));
    }

    onCaptchaChange = (newValue: string|null) => {
        this.setState(produce(d => { d.form.captcha = newValue ?? '' }));
    }

    handleSubmit = () => {
        if (!this.validateForm()) {
            return;
        }
        let form: IForm = {
            ...this.state.form,
            returnUrl: U.getReturnUrl(this.props.location.search)
        };
        api.UserRegister(form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded) {
                    this.props.setUserInfo(result.data);
                    const {isAdmin, isReviewer} = result.data as IUserLoginResponseData;
                    U.gotoPage(this.props, isAdmin ? AppRoutes.DashboardAdmin : 
                        isReviewer ? AppRoutes.DashboardApproval : AppRoutes.DashboardWallet);
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.userRegister;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    public render() {
        const idTypes = IdTypeOptions.map(o => ({
            value: o.value, 
            label: IdTypeWordings.getString(o.value), 
            group: o.group
        })).sort((a, b) => (a.group - b.group) || a.label.localeCompare(b.label));
        const countries = CountryOptions.map(o => ({
            value: o.value, 
            label: CountryWordings.getString(o.value), 
            group: o.group
        })).sort((a, b) => (a.group - b.group) || a.label.localeCompare(b.label));
        const { form, errors, toast, captchaKey } = this.state;
        const { CaptchaSiteKey } = this.props.appInfo;
        return (
            <>
                <Card>
                    <Card.Header as="h3">
                        <Image src="/images/logo_t.png" height="30" className="d-inline-block align-top" alt="" />
                        {' '}{Wordings.title}
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} sm={9} md={6} controlId="firstName">
                                    <Form.Label>{Wordings.firstName}</Form.Label>
                                    <Form.Control required type="text" 
                                        name="firstName"
                                        value={form.firstName}
                                        isInvalid={U.isInvalid(errors.firstName)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={12} sm={9} md={6} controlId="lastName">
                                    <Form.Label>{Wordings.lastName}</Form.Label>
                                    <Form.Control required type="text" 
                                        name="lastName"
                                        value={form.lastName}
                                        isInvalid={U.isInvalid(errors.lastName)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} controlId="street">
                                    <Form.Label>{Wordings.street}</Form.Label>
                                    <Form.Control required type="text" 
                                        name="street"
                                        value={form.street}
                                        isInvalid={U.isInvalid(errors.street)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={6} md={3} controlId="city">
                                    <Form.Label>{Wordings.city}</Form.Label>
                                    <Form.Control required type="text" 
                                        name="city"
                                        value={form.city}
                                        isInvalid={U.isInvalid(errors.city)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} md={3} controlId="state">
                                    <Form.Label>{Wordings.state}</Form.Label>
                                    <Form.Control required type="text" 
                                        name="state"
                                        value={form.state}
                                        isInvalid={U.isInvalid(errors.state)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} md={3} controlId="postalCode">
                                    <Form.Label>{Wordings.postalCode}</Form.Label>
                                    <Form.Control required type="text" 
                                        name="postalCode"
                                        value={form.postalCode}
                                        isInvalid={U.isInvalid(errors.postalCode)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.postalCode}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} md={3} controlId="country">
                                    <Form.Label>{Wordings.country}</Form.Label>
                                    <Form.Control as="select" 
                                        name="country"
                                        value={form.country}
                                        isInvalid={U.isInvalid(errors.country)}
                                        onChange={this.handleChange}>
                                        <option key={'-'} value={''}></option>)
                                        {countries.map(o => 
                                            <option key={o.value} value={o.value}>{o.label}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={6} md={3} controlId="phoneNumber">
                                    <Form.Label>{Wordings.phoneNumber}</Form.Label>
                                    <Form.Control type="text" 
                                        name="phoneNumber"
                                        value={form.phoneNumber}
                                        isInvalid={U.isInvalid(errors.phoneNumber)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} md={6} controlId="email">
                                    <Form.Label>{Wordings.email}</Form.Label>
                                    <Form.Control type="text" 
                                        name="email"
                                        value={form.email}
                                        isInvalid={U.isInvalid(errors.email)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={6} md={3} controlId="idType">
                                    <Form.Label>{Wordings.idType}</Form.Label>
                                    <Form.Control as="select" 
                                        name="idType"
                                        value={form.idType}
                                        isInvalid={U.isInvalid(errors.idType)}
                                        onChange={this.handleChange}>
                                        <option key={'-'} value={''}></option>)
                                        {idTypes.map(o => 
                                            <option key={o.value} value={o.value}>{o.label}</option>)
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">{errors.idType}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} md={4} controlId="idNumber">
                                    <Form.Label>{Wordings.idNumber}</Form.Label>
                                    <Form.Control required type="text" 
                                        name="idNumber"
                                        value={form.idNumber}
                                        isInvalid={U.isInvalid(errors.idNumber)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.idNumber}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} md={4} controlId="reviewCode">
                                    <Form.Label>{Wordings.reviewCode}</Form.Label>
                                    <Form.Control required type="text" 
                                        name="reviewCode"
                                        value={form.reviewCode}
                                        isInvalid={U.isInvalid(errors.reviewCode)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.reviewCode}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={6} md={4} controlId="username">
                                    <Form.Label>{Wordings.username}</Form.Label>
                                    <Form.Control required type="text" 
                                        name="username"
                                        value={form.username}
                                        isInvalid={U.isInvalid(errors.username)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} md={4} controlId="password">
                                    <Form.Label>{Wordings.password}</Form.Label>
                                    <Form.Control required type="password" 
                                        name="password"
                                        value={form.password}
                                        isInvalid={U.isInvalid(errors.password)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} md={4} controlId="repeatPassword">
                                    <Form.Label>{Wordings.repeatPassword}</Form.Label>
                                    <Form.Control required type="password" 
                                        name="repeatPassword"
                                        value={form.repeatPassword}
                                        isInvalid={U.isInvalid(errors.repeatPassword)}
                                        onChange={this.handleChange}/>
                                    <Form.Control.Feedback type="invalid">{errors.repeatPassword}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} xs={6} md={4} controlId="captcha">
                                    <ReCAPTCHA key={captchaKey} sitekey={CaptchaSiteKey ?? ''}
                                        onChange={this.onCaptchaChange} />
                                    <Form.Control.Feedback type="invalid" style={{display: U.isInvalid(errors.captcha) ? 'block' : 'none' }}>{errors.captcha}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Button variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                            </Form.Row>
                        </Form>
                    </Card.Body>
                </Card>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </>
        );
    }
};

export default connector(UserRegister);
