import axios, { AxiosRequestConfig } from 'axios';
import { Api } from '../interfaces/Api';
import { WalletServerApi } from "../interfaces/WalletServerApi";
import { AnonServerApi } from '../interfaces/AnonServerApi';
import * as AI from '../interfaces/AuthInterface';
import * as UI from '../interfaces/UserInterface';
import * as PI from '../interfaces/PasswordInterface';
import * as RI from '../interfaces/ReviewCodeInterface';
import * as WI from '../interfaces/WalletInterface';
import * as SI from '../interfaces/SetupInterface';
import * as TI from '../interfaces/TransactionInterface';
import * as WSI from '../interfaces/WalletServerInterface';
import * as ATI from '../interfaces/AnonTransferInterface';
import { IResponse } from '.';
import { AppSettings } from '../store/ApplicationData';
import AppRoutes from '../AppRoutes';

const {ApiServerUrl, WalletServerUrl}  = window.AppSettings;

axios.interceptors.response.use(response => {
    return response;
}, error => {
   if (error.response.status === 401) {
       const redirect = window.location.href;
       window.location.href = `${window.AppSettings.WebServerUrl}${AppRoutes.UserLogin}?returnUrl=${redirect}&r=timeout`;
   }
   return error;
});

export class ApiImpl implements Api, WalletServerApi, AnonServerApi {
    private _token: string = '';

    setToken = (token: string): void => {
        this._token = token;
    }

    private getRequestConfig = ():AxiosRequestConfig => {
        const c: AxiosRequestConfig = {
            headers: {
                'authorization': `Bearer ${this._token}`
            }
        };
        return c;        
    }

    UserRegister = (form: AI.IUserRegisterForm): Promise<{ data: AI.IUserRegisterResponse }> => {
        return axios.post(`${ApiServerUrl}/api/auth/register`, form);
    }
    UserLogin = (form: AI.IUserLoginForm): Promise<{ data: AI.IUserLoginResponse }> => {
        return axios.post(`${ApiServerUrl}/api/auth/login`, form);
    }
    UserLogout = (form: AI.IUserLogoutForm): Promise<{ data: AI.IUserLogoutResponse }> => {
        return axios.post(`${ApiServerUrl}/api/auth/logout`, form, this.getRequestConfig());
    }

    PasswordChange = (form: PI.IPasswordChangeForm): Promise<{ data: PI.IPasswordChangeResponse }> => {
        return axios.post(`${ApiServerUrl}/api/password/change`, form, this.getRequestConfig());
    }
    PasswordChangeKey = (form: PI.IPasswordChangeForm): Promise<{ data: PI.IPasswordChangeResponse }> => {
        return axios.post(`${ApiServerUrl}/api/password/keyset`, form, this.getRequestConfig());
    }
    PasswordReset = (form: PI.IPasswordResetForm): Promise<{ data: PI.IPasswordResetResponse }> => {
        return axios.post(`${ApiServerUrl}/api/password/reset`, form, this.getRequestConfig());
    }

    // GET: /build
    GetBuild = (): Promise<{data: AppSettings}> => {
        return axios.get(`${ApiServerUrl}/build`);
    }
    Setup = (): Promise<{ data: SI.ISetupResponse; }> => {
        return axios.get(`${ApiServerUrl}/api/setup/fetch`, this.getRequestConfig());
    }
    SetupEdit = (form: SI.ISetupEditForm): Promise<{ data: SI.ISetupResponse; }> => {
        return axios.post(`${ApiServerUrl}/api/setup/edit`, form, this.getRequestConfig());
    }
    GetUser = (id: number | string): Promise<{ data: UI.IUserResponse }> => {
        return axios.get(`${ApiServerUrl}/api/user/${id}`, this.getRequestConfig());
    }
    UserList = (form: UI.IUserListForm): Promise<{ data: UI.IUserListResponse }> => {
        return axios.post(`${ApiServerUrl}/api/user/list`, form, this.getRequestConfig());
    }
    UserSetFlag = (form: UI.IUserFlagForm): Promise<{ data: UI.IUserResponse }> => {
        return axios.post(`${ApiServerUrl}/api/user/setflag`, form, this.getRequestConfig());
    }
    UserEdit = (form: UI.IUserProfileForm): Promise<{ data: UI.IUserResponse }> => {
        return axios.post(`${ApiServerUrl}/api/user/edit`, form, this.getRequestConfig());
    }
    
    ReviewCodeList = (): Promise<{ data: RI.IReviewCodeListResponse; }> => {
        return axios.get(`${ApiServerUrl}/api/reviewcode/list`, this.getRequestConfig());
    }
    ReviewCodeCreate = (): Promise<{ data: RI.IReviewCodeResponse; }> => {
        return axios.post(`${ApiServerUrl}/api/reviewcode/create`, {}, this.getRequestConfig());
    }
    ReviewCodeEdit = (form: RI.IReviewCodeForm): Promise<{ data: RI.IReviewCodeResponse; }> => {
        return axios.post(`${ApiServerUrl}/api/reviewcode/edit`, form, this.getRequestConfig());
    }

    WalletList = (form: {userid?: number}): Promise<{ data: WI.IWalletListResponse }> => {
        return axios.post(`${ApiServerUrl}/api/wallet/list`, form, this.getRequestConfig());
    }
    WalletCreate = (form: WI.IWalletCreateForm): Promise<{ data: WI.IWalletResponse }> => {
        return axios.post(`${ApiServerUrl}/api/wallet/create`, form, this.getRequestConfig());
    }
    WalletStatus = (form: WI.IWalletStatusForm): Promise<{ data: WI.IWalletResponse }> => {
        return axios.post(`${ApiServerUrl}/api/wallet/status`, form, this.getRequestConfig());
    }
    GetWallet = (id: number): Promise<{ data: WI.IWalletResponse; }> => {
        return axios.get(`${ApiServerUrl}/api/wallet/${id}`, this.getRequestConfig());
    }

    TransactionList = (form: TI.ITransactionListForm): Promise<{ data: TI.ITransactionListResponse; }> => {
        return axios.post(`${ApiServerUrl}/api/transaction/list`, form, this.getRequestConfig());
    }

    WalletBalance = (form: WSI.IWalletBalanceRequest): Promise<{data: IResponse<WSI.IWalletBalanceResponse>}> => {
        return axios.post(`${WalletServerUrl}/api/wallet/balance`, form, this.getRequestConfig());
    }
    WalletKeys = (form: WSI.IWalletKeysRequest): Promise<{data: WSI.IKeyPair}> => {
        return axios.post(`${WalletServerUrl}/api/wallet/genkeys`, form, this.getRequestConfig());
    }
    Transfer = (form: WSI.ITransferRequest): Promise<{data: WSI.ITransferResponse}> => {
        return axios.post(`${WalletServerUrl}/api/wallet/transfer`, form, this.getRequestConfig());
    }
    WalletLogin = (form: { privateKey: string; }): Promise<{ data: IResponse<{ token: string; username: string }> }> => {
        return axios.post(`${WalletServerUrl}/api/auth/login`, form);
    }
    Mint = (form: any): Promise<{data: {}}> => {
        return axios.post(`${WalletServerUrl}/api/mock/mint`, form, this.getRequestConfig());
    }
    AnonKeys = (form: ATI.IAnonKeysRequest): Promise<{ data: IResponse<ATI.IAnonKeysResponse>; }> => {
        return axios.post(`${WalletServerUrl}/api/anon/genkeys`, form, this.getRequestConfig());
    }
    AnonBalance = (form: ATI.IAnonBalanceRequest): Promise<{ data: IResponse<ATI.IAnonBalanceResponse>; }> => {
        return axios.post(`${WalletServerUrl}/api/anon/balance`, form, this.getRequestConfig());
    }
    AnonTransfer = (form: ATI.IAnonTransferRequest): Promise<{ data: ATI.IAnonTransferResponse; }> => {
        return axios.post(`${WalletServerUrl}/api/anon/transfer`, form, this.getRequestConfig());
    }
    AnonTransferStatus = (form: ATI.IAnonTransferStatusRequest): Promise<{ data: ATI.IAnonTransferStatusResponse; }> => {
        return axios.post(`${WalletServerUrl}/api/anon/status`, form, this.getRequestConfig());
    }
}
