import * as React from 'react';

import NumberFormat from 'react-number-format';

type ComponentProps = {
    value: string;
    displayType: 'input' | 'text';
    thousandSeparator: boolean;
    decimalPlaces: number;
};

const getMain = (value: string) => {
    return value.indexOf('.') < 0 ? value : value.replace(/\..*/, '.');
}
const getFractional = (value: string, decimalPlaces: number) => {
    return value.indexOf('.') < 0 ? '' : value.replace(/.*\./, '').substring(0, decimalPlaces);
}
const CurrencyFormat = (props: ComponentProps) => (
    <>
        <NumberFormat value={getMain(props.value)} displayType={props.displayType} thousandSeparator={props.thousandSeparator} />
        <span style={{fontSize: 'smaller', verticalAlign: 'top'}}>{getFractional(props.value, props.decimalPlaces)}</span>
    </>
)

export default CurrencyFormat;