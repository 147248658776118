import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/SetupData';
import { ITransactionInfo } from '../../api';
import TransactionList from '../Transaction/TransactionList';
import TransactionDetail from '../Transaction/TransactionDetail';

const connector = connect((state: ApplicationState) => state.setup, DataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
    transaction?: ITransactionInfo
}
    
/*
*/
class DashboardTransaction extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        transaction: undefined
    };

    onTransactionSelect = (transaction?: ITransactionInfo) => {
        this.setState(produce(d => { d.transaction = transaction }));
    }

    public render() {
        const { transaction } = this.state;
        return (
            <>
                <Row>
                    <Col xs={12} md={6}>
                        <TransactionList onSelectRow={this.onTransactionSelect}/>
                    </Col>
                    <Col xs={12} md={6}>
                        {transaction && <TransactionDetail transaction={transaction}/>}
                    </Col>
                </Row>
            </>
        );
    }
};

export default connector(DashboardTransaction);