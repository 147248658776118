import { ApiImpl } from './ApiImpl';

const api = new ApiImpl();

export { api };
export * from '../interfaces/CommonInterface';
export * from '../interfaces/AuthInterface';
export * from '../interfaces/PasswordInterface';
export * from '../interfaces/ReviewCodeInterface';
export * from '../interfaces/UserInterface';
export * from '../interfaces/WalletInterface';
export * from '../interfaces/TransactionInterface';
export * from '../interfaces/SetupInterface';
export * from '../interfaces/EmploymentInterface';
export * from '../interfaces/WalletServerInterface';
export * from '../interfaces/AnonTransferInterface';
