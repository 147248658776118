import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import produce from 'immer';

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';

import { ApplicationState } from '../../store';
import * as DataStore from '../../store/UserData';
import { AnonWalletBalanceWordings as Wordings, CommonWordings, CurrencyWordings } from '../../Wordings';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';
import { api, IAnonBalanceRequest as IForm, IAnonBalanceErrors as IFormErrors, IAnonBalanceResponse, IResponseError } from '../../api';
import * as U from '../../utils/BbUtil';
import NumberFormat from 'react-number-format';

const errorMap = {
    publicKey: {
        required: Wordings.publicKeyRequired,
        invalid: Wordings.publicKeyInvalid
    },
    captcha: {
        'missing-input-secret':	CommonWordings.missingInputSecret,
        'invalid-input-secret':	CommonWordings.invalidInputSecret,
        'missing-input-response': CommonWordings.missingInputResponse,
        'invalid-input-response': CommonWordings.missingInputResponse,
        'bad-request': CommonWordings.badRequest,
        'timeout-or-duplicate':	CommonWordings.timeoutOrDuplicate,
        'unknown-error': CommonWordings.badRequest
    }
}

const connector = connect((state: ApplicationState) => state, DataStore.actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux;

interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly response: IAnonBalanceResponse | undefined;
    readonly toast: ToastProps;
    readonly captchaKey: number;
}
    
class WalletBalance extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            publicKey: '',
            captcha: '',
            currency: ['mhlk', 'eth']
        },
        errors: {
        },
        response: undefined,
        toast: DefaultToastProps,
        captchaKey: 1
    };

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.publicKey = (name === 'publicKey') ? value : d.form.publicKey;

            d.errors.publicKey = (name === 'publicKey') ? undefined : d.errors.publicKey;
        }));
    }

    validateForm = (): boolean => {
        const { publicKey } = this.state.form;
        let result: IResponseError = {
            publicKey: publicKey ? undefined : 'required',
        };
        this.setFormErrors(result);
        return U.isValidForm(result);
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { 
            d.errors = errors;
            if (U.hasFormErrors(errors)) {
                d.captchaKey = d.captchaKey + 1;
            }
        }));
    }

    onCaptchaChange = (newValue: string|null) => {
        this.setState(produce(d => { d.form.captcha = newValue ?? '' }));
    }

    handleSubmit = () => {
        if (!this.validateForm()) {
            return;
        }
        this.setState(produce(d => {
            d.response = undefined;
        }));
        api.AnonBalance(this.state.form)
            .then(response => response.data)
            .then(result => {
                if (result.succeeded) {
                    this.setState(produce(d => {
                        const data = result.data as IAnonBalanceResponse;
                        d.response = data;
                    }));
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = Wordings.title;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    public render() {
        const { form, errors, response, toast } = this.state;
        return (
            <>
                <Card>
                    <Card.Header as="h3">{Wordings.title}</Card.Header>
                    <Card.Body>
                        <Form.Group as={Col} xs={12} controlId="publicKey">
                            <Form.Label>{Wordings.publicKey}</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control type="text" 
                                name="publicKey"
                                value={form.publicKey}
                                isInvalid={U.isInvalid(errors.publicKey)}
                                onChange={this.handleChange}/>
                                <InputGroup.Append>
                                    <Button variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                                </InputGroup.Append>
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">{errors.publicKey}</Form.Control.Feedback>
                        </Form.Group>
                    {response !== undefined &&  
                        <ListGroup as={Col} xs={12}>
                            {response.balances.map(balance =>
                                <ListGroup.Item key={balance.currency}>
                                    <Form.Label>{CurrencyWordings.getString(balance.currency) || balance.currency}</Form.Label>{': '}
                                    {balance.amount !== '-1' && <NumberFormat value={balance.amount} displayType={'text'} thousandSeparator={true}/> }
                                    {balance.amount === '-1' && <Card.Text className='text-danger'>{Wordings.notAvailable}</Card.Text> }
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    }
                    </Card.Body>
                </Card>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </>
        );
    }
};

export default connector(WalletBalance);
