import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import produce from 'immer';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import ReCAPTCHA from "react-google-recaptcha";

import { ApplicationState } from '../../store';
import * as ApplicationDataStore from '../../store/ApplicationData';
import { AuthLoginWordings as Wordings, CommonWordings } from '../../Wordings';
import { api, IUserLoginForm as IForm, IUserLoginFormErrors as IFormErrors, IResponseError, IUserLoginResponseData } from '../../api';
import * as U from '../../utils/BbUtil';
import AppRoutes from '../../AppRoutes';
import { LinkContainer } from 'react-router-bootstrap';
import Toaster, { ToastProps, DefaultToastProps } from '../Util/Toaster';

const errorMap = {
    username: {
        required: Wordings.usernameRequired,
        invalid: Wordings.usernameInvalid
    },
    password: {
        required: Wordings.passwordRequired,
        invalid: Wordings.passwordInvalid
    },
    captcha: {
        'missing-input-secret':	CommonWordings.missingInputSecret,
        'invalid-input-secret':	CommonWordings.invalidInputSecret,
        'missing-input-response': CommonWordings.missingInputResponse,
        'invalid-input-response': CommonWordings.missingInputResponse,
        'bad-request': CommonWordings.badRequest,
        'timeout-or-duplicate':	CommonWordings.timeoutOrDuplicate,
        'unknown-error': CommonWordings.badRequest
    }
}

const connector = connect((state: ApplicationState) => state.appdata, ApplicationDataStore.actionCreators);
type PropsFromRedux = ConnectedProps<typeof connector>
type ComponentProps = PropsFromRedux 
    & RouteComponentProps<{}>;

interface ComponentState {
    readonly form: IForm;
    readonly errors: IFormErrors;
    readonly toast: ToastProps;
    readonly captchaKey: number;
}

class UserLogin extends React.Component<ComponentProps, ComponentState> {
    state: Readonly<ComponentState> = {
        form: {
            username: '',
            password: '',
            captcha: ''
        },
        errors: {},
        toast: DefaultToastProps,
        captchaKey: 1
    };

    handleChange = (event:React.ChangeEvent<FormControl & HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        this.setState(produce(d => {
            d.form.username = (name === 'username') ? value : d.form.username;
            d.form.password = (name === 'password') ? value : d.form.password;
            d.errors.username = undefined;
            d.errors.password = undefined;
        }));
    }

    validateForm = (): boolean => {
        const { username, password } = this.state.form;
        let result: IResponseError = {
            username: username ? undefined : 'required',
            password: password ? undefined : 'required',
        };
        this.setFormErrors(result);
        return U.isValidForm(result);
    }

    onCaptchaChange = (newValue: string|null) => {
        this.setState(produce(d => { d.form.captcha = newValue ?? '' }));
    }

    setFormErrors = (result: IResponseError) => {
        let errors: IFormErrors = U.toFormErrors(result, errorMap);
        this.setState(produce(d => { 
            d.errors = errors;
            if (U.hasFormErrors(errors)) {
                d.captchaKey = d.captchaKey + 1;
            }
        }));
    }

    handleSubmit = () => {
        if (!this.validateForm()) {
            return;
        }
        let form: IForm = {
            ...this.state.form,
            returnUrl: U.getReturnUrl(this.props.location.search)
        };
        api.UserLogin(form)
            .then((response) => response.data)
            .then((result) => {
                if (result.succeeded) {
                    this.props.setUserInfo(result.data);
                    const {isAdmin, isReviewer} = result.data as IUserLoginResponseData;
                    U.gotoPage(this.props, isAdmin ? AppRoutes.DashboardAdmin : 
                        isReviewer ? AppRoutes.DashboardApproval : AppRoutes.DashboardWallet);
                } else {
                    this.setFormErrors(result.errors as IResponseError);
                }
            })
            .catch((error) => {
                this.setState(produce(d => { 
                    d.toast.heading = CommonWordings.userLogin;
                    d.toast.text = error.message;
                    d.toast.appearance = 'error'; 
                }));
            });
    }

    public render() {
        const { form, errors, toast, captchaKey } = this.state;
        const { CaptchaSiteKey } = this.props.appInfo;
        return (
            <>
                <Card>
                    <Card.Header as="h3">{Wordings.title}</Card.Header>
                    <Card.Body>
                        <Form.Group controlId="username">
                            <Form.Label>{Wordings.username}</Form.Label>
                            <Form.Control required type="text" 
                                name="username"
                                value={form.username}
                                isInvalid={U.isInvalid(errors.username)}
                                onChange={this.handleChange}/>
                            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>{Wordings.password}</Form.Label>
                            <Form.Control required type="password" 
                                name="password"
                                value={form.password}
                                isInvalid={U.isInvalid(errors.password)}
                                onChange={this.handleChange}/>
                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="captcha">
                            <ReCAPTCHA key={captchaKey} sitekey={CaptchaSiteKey ?? ''}
                                onChange={this.onCaptchaChange} />
                            <Form.Control.Feedback type="invalid" style={{display: U.isInvalid(errors.captcha) ? 'block' : 'none' }}>{errors.captcha}</Form.Control.Feedback>
                        </Form.Group>
                        <Button block variant="primary" onClick={this.handleSubmit}>{Wordings.submit}</Button>
                    </Card.Body>
                    <Card.Body>
                        <p>
                            <small style={{verticalAlign: 'middle'}}>{Wordings.forgotPassword}</small>
                            <LinkContainer to={AppRoutes.WalletLogin}>
                                <Button variant="link" size="sm"><small>{Wordings.resetPassword}</small></Button>
                            </LinkContainer>
                        </p>
                    </Card.Body>
                </Card>
                <Toaster heading={toast.heading} appearance={toast.appearance} text={toast.text}/>
            </>
        );
    }
};

export default connector(UserLogin);
