const AppRoutes = {
    Home: '/',
    UserRegister: '/register',
    UserLogin: '/login',
    UserLogout: '/logout',
    WalletLogin: '/loginw',

    DashboardWallet: '/dashboard/wallet',
    DashboardApproval: '/dashboard/approval',
    DashboardAdmin: '/dashboard/admin',
    DashboardTransaction: '/dashboard/transaction',

    UserList: '/user/list',
    UserProfile: '/user/profile',
    WalletLater: '/wallet/later',
    WalletCreate: '/wallet/create',
    WalletHelp: '/wallet/help',

    AnonHome: '/a',
    AnonWalletCreate: '/a/wallet',
    AnonWalletBalance: '/a/balance',
    AnonTransfer: '/a/transfer',
    AnonTransferStatus: '/a/status',
}

export default AppRoutes;